import React from 'react'

import penetrationTestingAndBugBountyIcon from '../../../../assets/images/penetration_testing_and_bug_bounty__icon.png'
import penetrationTestingAndBugBountyText from '../../../../assets/images/penetration_testing_and_bug_bounty__heading_text.png'

import SubHeading from '../../../ui/SubHeading';

const PenetrationTestingAndBugBounty = () => {
  return ( 
    <>
      <div className="capability__card flex-col md:ml-12 md:m-2 md:w-5/12 2xl:m-6 2xl:w-1/5" data-testid="capability__card">
        <a href="/kyc-verification-penetration-testing-bug-bounty#bug_bounty">
          <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
            <div className="h-48 rounded-t-lg flex items-center justify-center">
              <img
                src={penetrationTestingAndBugBountyIcon}
                alt="Capability Card Top Image"
                id="penetration_testing_and_bug_bounty__icon"
              />
            </div>
          </div>
          <div className="bottom_our_capabilities__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
            <div className="p-3">
              <div className="our_capabilities__seperator border-b-2 mb-12" />
              {/* <img
                src={penetrationTestingAndBugBountyText}
                alt="Penetration Testing and Bug Bounty Heading"
                id="penetrationTestingAndBugBountyText"
                className="mb-12"
              /> */}
              <SubHeading
                text="PENETRATION TESTING AND BUG BOUNTY"
                id="penetration_testing_and_bug_bounty__heading"
                alt="Capability Card Top Image - Smart Contract Development and Audits"
                className="text-lg sm:text-sm md:text-md text-white mb-3"
              />
              <p className="text-gray-400 text-lg sm:text-sm md:text-md lg:text-xl"
              data-testid="penetration_testing_and_bug_bounty__description">
                Our team is highly experienced at Identifying and rectifying issues
                in the earliest stages of a smart contract development. Formal Verification's
                proactive approach pinpoints potential problems during the design phase,
                saving you time, resources, and potential losses down the road.
                <br/><br/><br/>
              </p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}
 
export default PenetrationTestingAndBugBounty;