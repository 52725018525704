import React from 'react';

import CyperTech_Labs_Logo from '../../assets/images/logo.png';
import facebook_footer_logo from '../../assets/images/facebook_footer_logo.png';
import x_footer_logo from '../../assets/images/x_footer_logo.png';
import instagram_footer_logo from '../../assets/images/instagram_footer_logo.png';
import youtube_footer_logo from '../../assets/images/youtube_footer_logo.png';
import github_footer_logo from '../../assets/images/github_footer_logo.png';
import GLB_ACT_Compliant_Logo from '../../assets/images/GLB-ACT_Compliant_Logo.png';
import GDPR_Logo from '../../assets/images/GDPR_Logo.png';
import ISO_Logo from '../../assets/images/ISO_Logo.png';
import NIST_Logo from '../../assets/images/NIST_Logo.png';

const Footer = () => {
  return (
    <div id="footer" className="bg-[#0e131d] text-white py-16" data-testid="footer_section">
      <div className="container mx-auto px-4 lg:px-0 grid grid-cols-1 lg:grid-cols-4 gap-8">
        <div className="flex justify-center lg:justify-start sm:mt-6 lg:mt-12">
            <img src={CyperTech_Labs_Logo} alt="CyperTech Labs Logo" className="hidden lg:flex w-64 h-64" />
            <img src={CyperTech_Labs_Logo} alt="CyperTech Labs Logo" className="flex md:hidden w-32 h-32 lg:ml-12" />
        </div>
        
        <div className="flex flex-col items-start">
          <h2 className="text-xl font-bold mb-4">JUMP TO</h2>
          <ul className="space-y-2">
            <li><a href="/">Home</a></li>
            <li><a href="https://blog.cyphertech-labs.io">Blog</a></li>
            <li><a href="/smart-contracts-and-audits">Smart Contracts and Audits</a></li>
            <li><a href="/kyc-verification-penetration-testing-bug-bounty">KYC Verification Penetration Testing</a></li>
            <li><a href="/kyc-verification-penetration-testing-bug-bounty#bug_bounty">Bug Bounty</a></li>
            <li><a href="/crypto-environments">Crypto Environments and Blockchain</a></li>
            <li><a href="/custom-development">Custom Development</a></li>
            <li><a href="/payment-options">Payment Options</a></li>
            <li><a href="/contact-us">Contact Us</a></li>
            <li><a href="/about-us">About Us</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/faqs">FAQs</a></li>
          </ul>
        </div>

        <div className="flex flex-col items-start">
          <h2 className="text-xl font-bold mb-4">OTHER SERVICES</h2>
          <ul className="space-y-2">
            <li><a href="/web-2-design-and-development">WEB-2 Design and Development</a></li>
            <li><a href="/web-3-design-and-development">WEB-3 Design and Development</a></li>
            <li><a href="/iOS-app-development">iOS APP Development</a></li>
            <li><a href="/iOS-app-development">iPadOS App Development</a></li>
            <li><a href="/iOS-app-development">MacOS App Development</a></li>
            <li><a href="/android-app-development">Android App Development</a></li>
            <li><a href="/microsoft-azure">Microsoft Azure</a></li>
            <li><a href="/amazon-web-services">Amazon Web Services</a></li>
            <li><a href="/ai-services">AI Services</a></li>
          </ul>
        </div>

        <div className="flex flex-col items-start">
          <h2 className="text-xl font-bold mb-4">CONNECT WITH US</h2>
          <ul className="space-y-2">
            <li className="flex items-center">
              <img src={facebook_footer_logo} alt="Facebook" className="w-6 h-6 mr-2" />
              <a href="https://www.facebook.com/profile.php?id=61564366229453" target="_blank" rel="noopener noreferrer">Facebook</a>
            </li>
            <li className="flex items-center">
              <img src={x_footer_logo} alt="X/Twitter" className="w-6 h-6 mr-2" />
              <a href="https://x.com/cyphertechlabs" target="_blank" rel="noopener noreferrer">X</a>
            </li>
            <li className="flex items-center">
              <img src={instagram_footer_logo} alt="Instagram" className="w-6 h-6 mr-2" />
              <a href="https://www.instagram.com/cyphertechlabs/" target="_blank" rel="noopener noreferrer">Instagram</a>
            </li>
            <li className="flex items-center">
              <img src={youtube_footer_logo} alt="YouTube" className="w-6 h-6 mr-2" />
              <a href="https://youtube.com/@CypherTechLabs" target="_blank" rel="noopener noreferrer">YouTube</a>
            </li>
            <li className="flex items-center">
              <img src={github_footer_logo} alt="GitHub" className="w-6 h-6 mr-2" />
              <a href="https://github.com/CypherTechLabs" target="_blank" rel="noopener noreferrer">GitHub</a>
            </li>
          </ul>
          
          <div className="flex flex-wrap justify-start space-x-4 space-y-4 mt-8">
            <table className="w-full">
              <tbody>
                <tr>
                  <td width="25%">
                    <img src={GLB_ACT_Compliant_Logo} alt="GLB ACT Compliant" />
                  </td>
                  <td>
                    &nbsp;&nbsp;
                  </td>
                  <td width="20%">
                    <img src={GDPR_Logo} alt="GDPR" className="w-20 h-20" />
                  </td>
                </tr>
                <tr>
                  <td>
                    &nbsp;&nbsp;
                  </td>
                </tr>
                <tr>
                  <td width="25%">
                    <img src={ISO_Logo} alt="ISO" />
                  </td>
                  <td>
                    &nbsp;&nbsp;
                  </td>
                  <td width="70%">
                    <img src={NIST_Logo} alt="NIST" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
