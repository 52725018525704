import React from 'react';
import Header from '../global/Header';
import Footer from '../global/Footer';
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png';

import ccPaymentOptions from '../../assets/images/cc_payment_options.png';
import payPalMePaymentOptions from '../../assets/images/paypal_me.png';
import bitcoinQrCode from '../../assets/images/bitcoin__qr_code.png';
import ethereumQrCode from '../../assets/images/ethereum__qr_code.png';
import dogeQrCode from '../../assets/images/doge__qr_code.png';
import usdtQrCode from '../../assets/images/usdt__qr_code.png';

import SubHeading from '../ui/SubHeading';
import SmallSubHeading from '../ui/SmallSubHeading';
import P from '../ui/P';

import CryptoAddressDetails from './payment_options/CryptoAddressDetails';

function PaymentOptions() {

  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="payment_options_body" data-testid="payment_options__page">
          <div className="payment_options_header__section" style={headerBackgroundImageStyle}>
            <Header/>
            
            <div className="m-1 payment_options__card lg:m-6" data-testid="payment_options__card">
              <div className="p-2 bg-[#17243D] text-white rounded-t-lg shadow-xl lg:p-6 w-full">
                <div className="rounded-t-lg flex items-center justify-center">
                  <SubHeading
                    text="Payment Options"
                    alt="Payment Options - CypherTech Labs - Technology Company"
                    id="payment_options__heading"
                  />
                </div>
              </div>
              <div className="p-1 bottom_payment_options__card bg-[#0C1320] text-white rounded-b-lg shadow-xl lg:p-6 w-full">
                <div className="p-1 lg:p-3">
                  <P className="payment_information__description text-2xl">
                    At CypherTech-Labs, currently we accept the following payment options
                  </P>

                  <SmallSubHeading
                    text="USD - Credit/Debit Card"
                    className="text-2xl"
                  />

                  <P className="payment_information__description_2 text-2xl mt-6">
                    First to note, we understand that your business may only be setup or able to pay us in USD.
                    However, we would prefer to accept Cryptocurrency as payment as we do believe it is the future of financial transactions and we are a technology company that looks to be on the bleeding edge of technology. If you cannot facilitate this request, we do understand and will accept USD as a payment at this time.<br/>

                    If you wish to pay by Credit or Debit Card, we will send you an invoice via the email account designated by you
                    for completion of payment. We utilize Stripe as our chosen payment processor for Credit and Debit Card transactions.<br/>

                    All payment transactions with CypherTech Labs are secure, your credit card or debit card information will never be saved, and you will be emailed a receipt. We accept Visa, MasterCard, Discover, American Express, and most Debit Cards.<br/>
                  </P>

                  <center>
                    <img
                      src={ccPaymentOptions}
                      alt="Credit Card Payment Options"
                      data-testid="credit_card_payment_options__image"
                      className="pt-6"
                    />
                    <div className="payment_options__seperator border-b-2 my-24" />
                  </center>

                  <SmallSubHeading
                    text="USD / EUR - PayPal"
                    className="text-2xl"
                  />

                  <P className="payment_information__description_3 text-2xl mt-6">
                    If you wish to pay via PayPal, you can send us payment directly at PayPal.ME/cyphertech-labs, or you can request
                    to pay by PayPal, and we will be sending you an invoice through PayPal to the email associated with your PayPal account.
                  </P>

                  <center>
                    <img
                      src={payPalMePaymentOptions}
                      alt="PayPal Payment Options for USD / EUR"
                      data-testid="paypal_me_payment_options__image"
                      className="pt-6"
                    />
                    <div className="payment_options__seperator border-b-2 my-24" />
                  </center>

                  <SubHeading
                    text="Cryptocurrency Payment Options"
                    className="text-3xl mb-0 pb-0"
                  />
                  <P className="payment_information__description_4 text-2xl">
                    For your convenience, we accept the following crypto currency forms of payment
                  </P>

                  <CryptoAddressDetails
                    tokenCallSign="BTC"
                    tokenLongName="bitcoin"
                    tokenAddress="3FLufzYavoLmFELquRccqfvVSz4FyV6zi8"
                    disclaimer="Do not send any other crypto to this address other than Bitcoin, or it will be lost, and we are not responsible for this loss"
                  />

                  <CryptoAddressDetails
                    tokenCallSign="ETH"
                    tokenLongName="ethereum"
                    tokenAddress="0x659D477e361b6dCA5412BbE2f4aE4ecD93288E96"
                    disclaimer="Do not send any other crypto to this address other than Ethereum, or it will be lost, and we are not responsible for this loss"
                  />

                  <CryptoAddressDetails
                    tokenCallSign="DOGE"
                    tokenLongName="dogecoin"
                    tokenAddress="DDxmdouK6ZWQmJqyr1DFiDo2ZQ9g8tbrUs"
                    disclaimer="Do not send any other crypto to this address other than from the Dogecoin network, or it will be lost, and we are not responsible for this loss"
                  />

                  <CryptoAddressDetails
                    tokenCallSign="USDT"
                    tokenLongName="usdt"
                    tokenAddress="0x1837a71E85C0635fE5f79C0757DB7C3d25f458C7"
                    disclaimer="Do not send any other crypto to this address other than USDT (ERC-20), or it will be lost, and we are not responsible for this loss"
                  />

                  <center>
                    <div className="payment_options__seperator border-b-2 my-24" />
                  </center>

                  <div className="flex flex-wrap mb-3">
                    <div className="flex flex-col w-1/2 lg:w-1/4 p-2">
                      <center>
                        <img
                          src={bitcoinQrCode}
                          alt="Bitcoin QR Code"
                          className="m-3"
                        /><br/>
                          Bitcoin QR Code
                          <br/><br/>
                      </center>
                    </div>
                    <div className="flex flex-col w-1/2 lg:w-1/4 p-2">
                      <center>
                        <img
                          src={ethereumQrCode}
                          alt="Ethereum QR Code"
                          className="m-3"
                        /><br/>
                          Ethereum QR Code
                          <br/><br/>
                      </center>
                    </div>
                    <div className="flex flex-col w-1/2 lg:w-1/4 p-2">
                      <center>
                        <img
                          src={dogeQrCode}
                          alt="Dogecoin QR Code"
                          className="m-3"
                        /><br/>
                          Dogecoin QR Code
                          <br/><br/>
                      </center>
                    </div>
                    <div className="flex flex-col w-1/2 lg:w-1/4 p-2">
                      <center>
                        <img
                          src={usdtQrCode}
                          alt="USDT QR Code"
                          className="m-3"
                        /><br/>
                          USDST QR Code
                          <br/><br/>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentOptions