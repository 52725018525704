import React from 'react'
import Button from '../../../ui/Button';
import SubHeading from '../../../ui/SubHeading';
import SmallSubHeading from '../../../ui/SmallSubHeading';
import penetrationTestingText from '../../../../assets/images/kyc_penetration_testing/penetration_testing__text.png';

const PenetrationTesting = () => {
  return (
    <div className="penetration_testing__card m-6" data-testid="penetration_testing__card">
      <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
        <div className="rounded-t-lg flex items-center justify-center">
          <SubHeading
            text="PENETRATION TESTING"
          />
        </div>
      </div>
      <div className="bottom_penetration_testing__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
        <div className="p-3">
          <div className="penetration_testing__seperator border-b-2 mb-12" />

          <SubHeading text="WHAT IS A PENETRATION TEST?" />
          <p className="text-gray-400 text-2xl mb-12">
            Penetration testing, often referred to as ethical hacking, it involves simulating attacks on a project's systems to uncover potential vulnerabilities and weaknesses.
            Our experts use their skills to replicate the tactics of malicious hackers, providing actionable insights to safeguard your project's security.
            This is a crucial step in ensuring the utmost security for blockchain and cryptocurrency projects.
          </p>

          <SubHeading text="HOW DOES OUR PENETRATION TESTING SERVICE WORK?" />
          <p className="text-gray-400 text-2xl mb-12">
            <span className="text-gray-300 font-bold">1. In-Depth Analysis</span><br/>
            Our skilled penetration testers thoroughly analyze your project's infrastructure, applications, and network configurations.
            <br/>
            <span className="text-gray-300 font-bold">2. Simulation of Attacks</span><br/>
            We employ a variety of techniques to simulate real-world attacks, identifying weak points that might be exploited by malicious people.
            <br/>
            <span className="text-gray-300 font-bold">3. Comprehensive Reporting</span><br/>
            Our detailed reports provide you with a clear understanding of vulnerabilities, along with recommended strategies to mitigate risks.
          </p>

          <SubHeading text="WHAT ARE THE BENEFITS OF PENETRATION TESTING?" />
          <p className="text-gray-400 text-2xl mb-4">
            By opting for our penetration testing service, project owners can:<br/>
            <span className="text-gray-300 font-bold">1. Identify Hidden Weaknesses</span><br/>
            Discover vulnerabilities that may not be apparent through traditional security assessments and audits.
            <br/>
            <span className="text-gray-300 font-bold">2. Enhance Defenses</span><br/>
            Strengthen your project's security measures based on actionable insights from simulated attacks.
            <br/>
            <span className="text-gray-300 font-bold">3. Proactive Approach</span><br/>
            Address vulnerabilities before they can be exploited by malicious entities, safeguarding your project's reputation.
          </p>

          <p className="text-gray-400 text-2xl mb-12">  
            At Cypher-Tech Labs, we believe that robust security is paramount in the crypto space. Our penetration testing service ensures that your project is resilient against a wide range
            of potential threats. Choose Cypher-Tech Labs for comprehensive security solutions that go beyond code analysis.
          </p>

          <SubHeading text="HOW DO I GET STARTED WITH PENETRATION TESTING?" />
          <p className="text-gray-400 text-2xl mb-12">
            To get started with penetration testing, you need to contact us and request Penetration Testing Service. We will than get in touch with you informing you of the process and
            sending you the of the necessary information we require to complete the Penetration Testing process.<br/><br/>

            By completing a Penetratin Test Series, you can find out if your project is, or will be susceptible to any previous and current hacking methods. This way you can either make
            the necessary changes we will suggest, and finally you will rest assured that your project will be safe and less likely to be hacked.
          </p>
          
          <div className="w-full">
            <center>
              <SmallSubHeading text="REQUEST PENETRATION TESTING TODAY" />
            </center>
          </div>
          <div className="flex flex-col lg:flex-row w-full mb-6">
            <div className="w-full">
              <Button
                url="/contact-us"
                text="Penetration Testing Request Form"
                dataTestId="penetration_testing_request__form"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PenetrationTesting