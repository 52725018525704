import React from 'react';

import solanaIconOne from '../../../assets/images/crypto_environments/solana_icon__one.png';
import solanaIconTwo from '../../../assets/images/crypto_environments/solana_icon__two.png';
import solanaIconThree from '../../../assets/images/crypto_environments/solana_icon__three.png';

import SubHeading from '../../ui/SubHeading';
import P from '../../ui/P';

const SolanaEnvironments = () => {
  return (
  <>
    <div className="solana_environments__card m-6" data-testid="solana_environments__card">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="rounded-t-lg flex items-center justify-center">
            <SubHeading
              text="SOLANA ENVIRONMENTS"
            />
          </div>
        </div>
        <div className="bottom_solana_environments__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="p-3">
            <div className="solana_environments__seperator border-b-2 mb-12" />

            <SubHeading text="SOLANA SMART CONTRACT AUDIT" className="text-xl" />
            <P className="text-xl">
              We know how crucial it is to secure your Solana smart contracts. Our audit services ensure your projects are reliable and secure, giving you the confidence to deploy worry-free. Projects on Solana are launching every day, and while most of them are rubbish, rug-pulls and intended to make the creator a quick buck, we believe in the power of Solana and having us evaluate and audit your Solana project, will give you, your investors and your buyers the much needed piece of mind.
            </P>

            <SubHeading text="WHAT IS A SOLANA SMART CONTRACT?" className="text-xl" />
            <P className="text-xl">
              A Solana smart contract is a self-executing contract deployed and running on the Solana blockchain. Solana is a high-performance blockchain platform known for its fast transaction processing speed and low transaction costs.It utilizes a unique consensus mechanism called Proof of History (PoH) in combination with Proof of Stake (PoS) to achieve its high throughput.
            </P>
            <P className="text-xl">
              Smart contracts on Solana, like those on other blockchain platforms such as Ethereum, are programmable scripts that automatically execute predefined actions when certain conditions are met. These contracts are typically written in programming languages like Rust or C, enabling decentralized applications (DApps) to perform various functions without a centralized authority.
            </P>
            <P className="text-xl">
              Solana smart contracts can be used for a wide range of applications, including decentralized finance (DeFi), non-fungible tokens (NFTs), decentralized exchanges (DEXs), and more. Developers can deploy and interact with these smart contracts through the Solana blockchain, leveraging its speed and scalability for efficient and cost-effective operations.
            </P>

            <SubHeading text="ADVANTAGES TO A SOLANA CONTRACT AUDIT" className="text-xl" />
            <P className="text-xl">
              We know how crucial it is to secure your Solana smart contracts. Our audit services ensure your projects are reliable and secure,
              giving you the confidence to deploy worry-free. Projects on Solana are launching every day, and while most of them are rubbish,
              rug-pulls and intended to make the creator a quick buck, we believe in the power of Solana and having us evaluate and audit
              your Solana project, will give you, your investors and your buyers the much needed piece of mind.
            </P>

            <div className="flex items-start my-6">
              <img
                src={solanaIconOne}
                alt="Solana Environment Icon One"
                id="solana_environment_icon__one"
                className="pt-[.255rem] pr-[.255rem] hidden lg:flex"
              />
              <div>
                <SubHeading text="Contract Quality" className="text-xl mb-0 pb-0 lg:ml-4" />
                <P className="text-xl mt-0 pt-0 lg:ml-4">
                  Audits help improve the overall quality of the smart contract. This includes ensuring that the follows best practices,
                  is well-structured, and is easy to maintain. Improved quality reduces the likelihood of bugs and enhances the reliability
                  of the smart contract.
                </P>
              </div>
            </div>

            <div className="flex items-start my-6">
              <img
                src={solanaIconTwo}
                alt="Solana Environment Icon Two"
                id="solana_environment_icon__two"
                className="pt-[.255rem] pr-[.255rem] hidden lg:flex"
              />
              <div>
                <SubHeading text="Risk Mitigation" className="text-xl mb-0 pb-0 lg:ml-4" />
                <P className="text-xl mt-0 pt-0 lg:ml-4">
                  By identifying and addressing potential vulnerabilities, audits help mitigate risks associated with smart
                  contract execution. This is especially important in financial applications where security is paramount.
                </P>
              </div>
            </div>

            <div className="flex items-start my-6">
              <img
                src={solanaIconThree}
                alt="Solana Environment Icon Three"
                id="solana_environment_icon__three"
                className="pt-[.255rem] pr-[.255rem] hidden lg:flex"
              />
              <div>
                <SubHeading text="Risk Mitigation" className="text-xl mb-0 pb-0 lg:ml-4" />
                <P className="text-xl mt-0 pt-0 lg:ml-4">
                  Audits identify and address potential security vulnerabilities, such as vulnerabilities, reentrancy issues, and other
                  common smart contract vulnerabilities. This helps in preventing potential exploits and attacks on the Solana smart contracts.
                </P>
              </div>
            </div>

            <SubHeading text="WHY AUDIT SOLANA SMART CONTRACTS?" className="text-xl" />
            <P className="text-gray-400 text-2xl mb-3">
              A Solana smart contract audit is a comprehensive examination and evaluation of the functionalities within a smart contract deployed on the Solana blockchain. The goal is to identify and address potential vulnerabilities, and security risks, and ensure that the smart contract behaves as intended. This process involves a thorough review of the contract, manual and automated testing, and the documentation associated with the smart contract.
            </P>
            
            <P className="text-gray-400 text-xl mb-12">
              The audit aims to enhance the security and reliability of the smart contract, reducing the risk of vulnerabilities that could
              be exploited by malicious actors. It provides developers, project owners, and users with confidence in the integrity of the smart contract, particularly in a decentralized and trust-less environment like the blockchain. The audit process helps identify and  mitigate potential issues before the smart contract goes live, safeguarding the assets and associated transactions.
            </P>

            <SubHeading text="SOLANA SMART CONTRACT AUDIT PROCESS" className="text-xl" />
            <div className="text-gray-400 text-xl my-3">
              <div className="mb-6">
                <span className="text-gray-300 font-bold">1. Contract Submission</span><br/>
                The audit process begins with the submission of the contract to be audited. Upon receiving the contract, auditors conduct an initial review to understand its structure, complexity, and purpose.
              </div>
              <div className="mb-6">
                <span className="text-gray-300 font-bold">2. Audit Tests</span><br/>
                Audit testing involves manual reviews to identify issues and ensure alignment with business logic. Unit tests validate functionality, particularly in scenarios like future actions, while automated tools detect qualitative insights through pattern analysis, enhancing overall system robustness.
              </div>
              <div className="mb-6">
                <span className="text-gray-300 font-bold">3. Review from Auditors</span><br/>
                The entire audit report is checked by engineers who have not been involved in the previous steps. Since they are not biased, they may apply some corrections.
              </div>
              <div className="mb-6">
                <span className="text-gray-300 font-bold">4. Report, Delivery and Suggestions</span><br/>
                In alignment with Agile methodology, our audit process is continuous. We proactively advise the customer on suggested updates and seek their feedback for ongoing improvements to the report and contract.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default SolanaEnvironments;