import React from 'react'

import HeroLeft from './hero_block/HeroLeft'
import HeroRight from './hero_block/HeroRight'

const HeroBlock = () => {
  return ( 
    <>
      <div className="flex flex-col lg:flex-row lg:mb-24">
        <div className="w-full lg:w-1/2">
          <HeroLeft />
        </div>
        <div className="w-full lg:w-1/2">
          <HeroRight />
        </div>
      </div>
    </>
   );
}
 
export default HeroBlock;