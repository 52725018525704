import React from 'react'
import Header from '../global/Header'
import Footer from '../global/Footer';
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png'
import auditBadgeBg from '../../assets/images/audit_badge/audit_badge_bg.png'
import HeroBlock from './home_components/HeroBlock';
import OurCapabilities from './home_components/OurCapabilities';
import HowAuditsWork from './home_components/HowAuditsWork';
import OurSpecialties from './home_components/OurSpecialties';
import WorkWithUs from './home_components/WorkWithUs';
import AuditBadge from './home_components/AuditBadge';

function Home() {

  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  const auditBadgeBackgroundImageStyle = {
    backgroundImage: `url(${auditBadgeBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
  }

  return (
    <>
      <div style={backgroundStyle}>
        <div className="homepage_body">
          <div className="homepage_header__section" style={headerBackgroundImageStyle}>
            <Header/>
            <HeroBlock />
            <div className="w-100 homepage_body__our_capabilities">
              <OurCapabilities />
            </div>
            <div className="w-100 homepage_body__how_audits_work bg-black text-white py-6">
              <HowAuditsWork />
            </div>
            <div className="w-100 homepage_body__our_specialties py-24">
              <OurSpecialties />
            </div>
            <div className="w-100 homepage_body__why_work_with_us bg-black pb-6 pt-6 lg:pt-24 lg:pb-24">
              <WorkWithUs />
              <br/><br/><br/>
            </div>
            <div className="w-100 homepage_body__audit_badge bg-black pb-6 pt-6 lg:pt-24 lg:pb-24" style={auditBadgeBackgroundImageStyle}>
               <AuditBadge />
            </div>
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home
