import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses';

const ses = new SESClient({
  region: 'us-east-1',
  credentials: {
    accessKeyId: process.env.CTL_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.CTL_AWS_SECRET_ACCESS_KEY,
  },
});

export async function sendMail ( subject, body ) {
  const params = {
    Source: "contact@cyphertech-labs.io",
    Destination: {
      ToAddresses: ['devon@3dmandt.com', 'contact@cyphertech-labs.io'],
    },
    Message: {
      Subject: { Data: subject },
      Body: {
        Html: { Data: body },
      },
    },
  };

  try {
    const data = await ses.send(new SendEmailCommand(params));
    return true;
  } catch (error) {
    // Log any errors encountered during email sending
    if (error instanceof Error) {
      console.error("Error sending email:", error.message);
    } else {
      console.error("Unknown error:", error);
    }
    throw new Error(`Email sending failed: ${error}`);

    return false;
  }
}