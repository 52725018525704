import React from 'react';
import Header from '../global/Header';
import Footer from '../global/Footer';
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png';
import SubHeading from '../ui/SubHeading';
import SmallSubHeading from '../ui/SmallSubHeading';
import P from '../ui/P';

function FAQs() {

  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="faqs_body" data-testid="faqs__page">
          <div className="faqs_header__section" style={headerBackgroundImageStyle}>
            <Header/>
            
            <div className="faqs__card m-6" data-testid="faqs__card">
              <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
                <div className="rounded-t-lg flex items-center justify-center">
                  <SubHeading
                    text="Frequently Asked Questions"
                    alt="FAQ's (Frequently Asked Questions) - CypherTech Labs - Technology Company"
                    id="faqs__heading"
                    data-testid="faqs__heading_text"
                  />
                </div>
              </div>
              <div className="bottom_faqs__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
                <div className="p-3">
                  <SmallSubHeading text="WHO NEEDS SMART CONTRACT AUDITS" />
                  <P>
                    All projects on the networks can benefit from a thorough audit of their smart contracts., including developers building decentralized applications (DApps), projects launching DeFi or NFT platforms, investors, users, and regulatory bodies, can benefit from a smart contract audit. It ensures the security, reliability, and compliance of smart contracts, offering assurance to all stakeholders in the ecosystem.
                  </P>

                  <SmallSubHeading text="My Token is Created by a Token Program, Do I Still Need an Audit?" />
                  <P>
                    Whether or not you need an audit for your token created by a Token Program depends on various factors, including regulatory requirements, investor expectations, and the complexity of your tokenomics.
                  </P>

                  <SmallSubHeading text="Can I get the Audit Badge for my Presale?" />
                  <P>
                    Yes, you can get the related badge if you hold your presale/fairlaunch in any of our recommended launchpads.
                  </P>

                  <SmallSubHeading text="Can The Audit Fail?" />
                  <P>
                    Sometimes, if there are severe issues found within a contract's code, the audit can 'fail'. You will need to read the audit report and address the issues.
                  </P>

                  <SmallSubHeading text="Can The Audit Fail?" />
                  <P>
                    Our audit reports contain detailed descriptions and recommendations on each finding. Consult your developer and if you fix and redeploy a new contract let us know to check and revise it accordingly.
                  </P>

                  <SmallSubHeading text="Will renouncing the Ownership fix our contract's issues?" />
                  <P>
                    Yes, it can fix most of the most common issues as far as the audit is concerned. If you need ownership for finalizing your presale or listing on a CEX, it can prove counter-productive. So you need to make sure this won't cause any problems for you in the future.
                  </P>

                  <SmallSubHeading text="I have to deploy a new contract. The new contract is exactly the same as the old contract. Can I get the audit certificate again? Do I have to pay an extra fee?" />
                  <P>
                    Yes, you can. If it's the exact same contract there is usually a small admin fee involved.
                  </P>

                  <SmallSubHeading text="Do you guys audit CEX, DEX wallets, and new blockchains as well?" />
                  <P>
                    Yes, we do custom audits but we have different quotes depending on the contract. Please send us your smart contract in order to review it.
                  </P>

                  <SmallSubHeading text="Why are your audit services more expensive than other companies who audit cheaper?" />
                  <P>
                    A strong Audit report is the best marketing you can do for your project. If you are not willing to buy a strong brand audit how do you expect investors to buy your token? Let aside the fact that cheaper companies just run automated diagnostics instead of line-to-line code review. Meaning they will not find real dangers and let you know with recommended fixes. If there is an issue in your contracts, our highly experienced auditors will find it.
                  </P>

                  <SmallSubHeading text="Should we add an Anti-Whale bot?" />
                  <P>
                    If you have one, we might flag it in the Audit but it is up to you.
                  </P>

                  <SmallSubHeading text="Which launchpad would you suggest me?" />
                  <P>
                    All launchpads that we work with are very good, usually our clients prefer Pinksale because it has a bit more traffic but we will support you whatever you choose.
                  </P>

                  <SmallSubHeading text="What is the turnaround time on an audit?" />
                  <P>
                    Well, this depends on the contract complexity and length. Typically it is between 7-30 days.
                  </P>

                  <SmallSubHeading text="What are the payment options available to me?" />
                  <P>
                    We take PayPal, CreditCard and Crypto Payments in the form of BTC and ETH. Please visit our <a href="/payment-options">Payment Options</a> page for additional information.
                  </P>

                </div>
              </div>
            </div>
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FAQs
