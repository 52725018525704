import React from 'react'
import { Link } from 'react-router-dom'
import logoFile from '../../../assets/images/logo.png'

const TabletNav = () => {
  return ( 
    <>
      <nav className="w-full" data-testid="tablet_navigation__item">
        <ul className="hidden items-center text-center md:flex xl:hidden" id="tablet_navbar_ul">
          <li className="inline-block px-4">
            <Link to="/">
              <img
                src={logoFile}
                alt="Cypher-Tech Labs - Smart Contract Audits, Token and NFT Developer, Custom Software Applications, and much more!"
                id="tablet_logo_file"
              />
            </Link>
          </li>
          <TabletNavLinks/>
        </ul>
      </nav>
    </>
  );
}

const TabletNavLinks = () => {
  const navLinks = [
    { name: 'Home', path: '/', id: 'home__route'},
    { name: 'Blog', path: 'https://blog.cyphertech-labs.io/', id: 'blog__route', external: true},
    { name: 'SmartContracts & Audits', path: '/smart-contracts-and-audits', id: 'smart_contracts_and_audits__route'},
    { name: 'KYC/Penetration Testing', path: '/kyc-verification-penetration-testing-bug-bounty', id: 'kyc_verification_penetration_testing_and_bug_bounty__route'},
    { name: 'Bug Bounty', path: '/kyc-verification-penetration-testing-bug-bounty#bug_bounty', id: 'kyc_verification_penetration_testing_and_bug_bounty__route#bug_bounty'},
    { name: 'Crypto Environments', path: '/crypto-environments', id: 'crypto_environments__route'},
    { name: 'Custom Development', path: '/custom-development', id: 'custom_development__route'},
    { name: 'Payment Options', path: '/payment-options', id: 'payment_options__route'},
  ]

  return (
    <>
      {navLinks.map((link, index) => (
        <li key={index} className="inline-block px-4 md:text-xs">
          <Link to={link.path}>{link.name}</Link>
        </li>
      ))}
    </>
  );
}

 
export default TabletNav;