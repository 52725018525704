import React from 'react';

import WhyWorkWithUsTitle from './why_work_with_us/WhyWorkWithUsTitle';
import FirstCol from './why_work_with_us/FirstCol';
import SecondCol from './why_work_with_us/SecondCol';
import ThirdCol from './why_work_with_us/ThirdCol';

const WorkWithUs = () => {
  return ( 
    <>
      <WhyWorkWithUsTitle />
      <div className="bg-black flex flex-wrap w-full">
        <FirstCol />
        <SecondCol />
        <ThirdCol />
      </div>
    </>
   );
}
 
export default WorkWithUs;