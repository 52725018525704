import React from 'react'
import blueLine510 from '../../../../assets/images/blue_line__510.png';
import SubHeading from '../../../ui/SubHeading';

const InitialCapabilities = () => {
  return ( 
    <>
      <div className="capability__card w-full lg:w-1/5 flex-col m-6 mb-24" data-testid="capability__card">
        <div className="w-full">
          <center>
            <SubHeading
              text="OUR CAPABILITIES"
              alt="Our Capabilities at Cypher-Tech Labs"
              id="our_capabilities__text"
              className="text-3xl sm:text-2xl md:text-2xl text-white mt-4"
            />
          </center>
        </div>
        <img
          src={blueLine510}
          alt="seperating aesthic line"
          className="mt-12 mb-4"
          id="blue_line__510"
        />
        <p className="mb-3 text-lg text-gray-300" data-testid="our_capabilities__description">
          Our team of specialists and developers are experts
          in multiple disciplines. Our team can handle the
          following services.<br/><br/>
        </p>
        <ul className="ml-3 text-gray-300 text-md" data-testid="our_capabilities__list_items">
          <li className="my-1 text-md">Web 2, Web 3, Mobile App Design + Development</li>
          <li className="my-1 text-md">Smart Contracts</li>
          <li className="my-1 text-md">Custom Computer Software</li>
          <li className="my-1 text-md">Firmware for specific hardware, such as:<br/>
            <ol className="ml-3">
              <li>NVIDIA</li>
              <li>AMD</li>
            </ol>
          </li>
          <li className="my-1 text-md">Specializing in Server Setup:<br/>
            <ol className="ml-3">
              <li>AWS</li>
              <li>AZURE</li>
              <li>Heroku</li>
              <li>DigitalOcean</li>
              <li>Vercel</li>
              <li>Netlify</li>
            </ol>
          </li>
          <li className="my-1 text-md">Custom AI Projects</li>
          <li className="my-1 text-md">Custom Cryptography for Web3 projects</li>
          <li className="my-1 text-md">Cryptography</li>
        </ul>
      </div>
    </>
   );
}
 
export default InitialCapabilities;