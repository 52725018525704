import React from 'react'
import laptopBackgroundHome from '../../../../assets/images/laptop_background__home.png'

const HeroRight = () => {
  return ( 
    <>
      <div className="hidden lg:flex lg:w-full">
        <center>
          <img
            src={laptopBackgroundHome}
            alt="Use your mobile devices with peace of mind with proper security policies."
            id=""
            className="w-full h-full pr-12 rounded"
          />
        </center>
      </div>
      <div className="flex w-full lg:hidden">
        <center>
          <img
            src={laptopBackgroundHome}
            alt="Use your mobile devices with peace of mind with proper security policies."
            id=""
            className="w-full"
          />
        </center>
      </div>
    </>
   );
}
 
export default HeroRight;