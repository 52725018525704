import React from 'react'
import Header from '../global/Header'
import Footer from '../global/Footer'
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png';
import ContractsHeroBlock from './smart_contracts_development/ContractsHeroBlock';

import CustomSmartContractDevelopment from './smart_contracts_development/CustomSmartContractDevelopment';

function SmartContractsAndAudits() {

  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="smart_contract_development_body" data-testid="smart_contract_development__page">
          <div className="smart_contract_development_header__section" style={headerBackgroundImageStyle}>
            <Header/>
            <ContractsHeroBlock/>
            <CustomSmartContractDevelopment />
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SmartContractsAndAudits