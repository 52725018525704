import React from 'react';

const FirstCol = () => {
  return ( 
    <>
      <div className="flex-row w-full lg:flex-col lg:w-1/3">
        <div className="flex-row w-full">
          <div className="w-full">
            <p className="text-md text-gray-300 leading-relaxed m-8">
              Cypher-Tech Labs has audited numerous contracts, showcasing our extensive experience and proficiency in scrutinizing projects
              on the Solana blockchain. With a track record of successful audits, clients can trust our expertise to ensure the security
              and reliability of their Solana smart contracts.
            </p>
            <p className="text-md text-gray-300 leading-relaxed m-8">
              Critical Audit Considerations: During an audit of a tokencreated by <a href="https://spl.solana.com/token" target="_blank" style={{textDecoration: "underline"}}>The Token Program</a>, it's essential to assess the following aspects meticulously:
            </p>
            <ul className="text-md text-gray-300 s m-8 pt-4">
              <li className="ml-4 mb-2"><strong>Freeze Authority:</strong> Unless a compelling reason necessitates its retention, it should be revoked.</li>
              <li className="ml-4 mb-2"><strong>Mint Authority:</strong> Similar considerations apply to Freeze Authority.</li>
              <li className="ml-4 mb-2"><strong>TransferConfig Authority:</strong> Similar considerations apply as with Freeze Authority.</li>
              <li className="ml-4 mb-2"><strong>Update Authority:</strong> Similar considerations apply as with Freeze Authority.</li>
              <li className="ml-4 mb-2"><strong>Short address attacks:</strong> less data than could be expected have been received by a contract. The default data are used to fill the missing expected transactional data with unexpected outcomes.</li>
            </ul>
            <p className="text-md text-gray-300 leading-relaxed m-8">
              It is also considered best practice (especially if you are planning a presale)
              to permit the following extensions:
            </p>
            <ul className="text-md text-gray-300 leading-relaxed m-8 pt-4">
              <li className="mb-2 text-xl"><strong>Whitelisted Extensions:</strong></li>
              <li className="ml-4">ExtensionType.MintCloseAuthority</li>
              <li className="ml-4">ExtensionType.TransferFeeConfig</li>
              <li className="ml-4">ExtensionType.DefaultAccountState</li>
              <li className="ml-4">ExtensionType.ImmutableOwner</li>
              <li className="ml-4">ExtensionType.MetadataPointer</li>
              <li className="ml-4">ExtensionType.TokenMetadata</li>
            </ul>
            <p className="text-md text-gray-300 leading-relaxed m-8">
              <strong>* Important Note on TransferFeeConfig</strong><br/>
              If TransferFeeConfig is utilized, it's crucial to establish the fee upfront.
              Any updates require a two-epoch waiting period (approximately 4-6 days) to become effective.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default FirstCol;