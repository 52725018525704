import React from 'react'
import P from '../../ui/P';
import SmallSubHeading from '../../ui/SmallSubHeading';

const CryptoAddressDetails = ({ tokenCallSign, tokenLongName, tokenAddress, disclaimer }) => {
  return (
    <>
      <SmallSubHeading
        text={`${tokenCallSign} Address`}
        className="text-2xl mb-0 pb-1"
      />
      <P className="text-md mb-0 text-gray-200 font-bold">{tokenAddress}</P>
      <P className={`${tokenLongName}_payment_address text-xl mt-0 pt-4`}><small>
        {disclaimer}
      </small></P>
    </>
  );
}

export default CryptoAddressDetails
