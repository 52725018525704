import React from 'react'

import InitialCapabilities from './our_capabilities/InitialCapabilities'
import SmartContractDevelopmentAndAudits from './our_capabilities/SmartContractDevelopmentAndAudits'
import KYCAndVerifications from './our_capabilities/KYCAndVerifications'
import PenetrationTestingAndBugBounty from './our_capabilities/PenetrationTestingAndBugBounty'
import CustomDevelopment from './our_capabilities/CustomDevelopment'

const OurCapabilities = () => {
  return ( 
    <>
      <div className="w-full homepage_body__our_capabilities">
        <div
          className="flex flex-wrap mb-6 lg:flex-nowrap lg:px-24"
          id="our_capabilities__section"
          data-testid="our_capabilities__section"
        >
          <InitialCapabilities />
          <SmartContractDevelopmentAndAudits />
          <KYCAndVerifications />
          <PenetrationTestingAndBugBounty />
          <CustomDevelopment />
        </div>
      </div>
    </>
  );
}
 
export default OurCapabilities;