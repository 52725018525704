import React from 'react';
import { cn } from '../../lib/utilities';

const P = ({ children, className, testId }) => {
  return (
    <p className={cn("text-gray-400 text-lg mb-12", className, {})} data-testid={testId}>
      {children}
    </p>
  );
};

export default P;