import React from 'react';
import Button from '../../ui/Button';

const RequestAnAudit = () => {
  return (
    <>
      <div className="w-full my-6">
        <center>
          <Button
            url="https://docs.google.com/forms/d/15cJiPQIiGMDeblD6koKPQLHZa_xNF35SlPuWB-eQctA/viewform"
            text="Request An Audit"
            dataTestId="request_an_audit__form_button"
            className="text-2xl"
            target="_blank"
          />
        </center>
      </div>
    </>
  )
}

export default RequestAnAudit