import React from 'react'

import stepFiveMitigation from '../../../../assets/images/HDAW_STEP_5.png';

const Mitigation = () => {
  return ( 
    <>
      <div className="flex flex-col items-center px-auto py-8" data-testid="how_do_audits_work__card">
        <div className="mb-12">
          <center>
            <p className="text-3xl">Step 5</p>
          </center>
        </div>
        <div className="icon-container transform transition-transform duration-300 hover:scale-110">
          <img src={stepFiveMitigation} alt="Step 5 - Mitigation - Icon" className="w-24 h-24" />
        </div>
        <p className="text-4xl mt-6">Mitigation</p>
        <hr/>
      </div>    
    </>
  );
}
 
export default Mitigation;