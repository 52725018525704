import React from 'react';
import SubHeading from '../../../ui/SubHeading';
import IntroParagraph from '../../../ui/IntroParagraph';

const HeroLeft = () => {
  return ( 
    <>

      <div className="mt-3">
        <SubHeading

          text={`CYPHERTECH LABS`}
          className="text-4xl font-bold text-left mt-6 mx-12 mb-0 sm:mt-6 md:ml-6 lg:ml-32 lg:justify-center"
          id="cyphertech_labs__text"
          alt="Cypher-Tech Labs"
          
        />
      </div>

      <IntroParagraph

        id="introductory_paragraph"
        testId="home__introductory_paragraph"

      >

        Cypher-Tech Labs provides comprehensive 
        security assessments for blockchain-based 
        projects. We are highly experienced security
        researchers and auditors who understand
        smart contract best practices and vulnerabilities.

      </IntroParagraph>

    </>
  );
}
 
export default HeroLeft;