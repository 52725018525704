import React, { useEffect } from 'react';
import Header from '../global/Header'
import Footer from '../global/Footer'
import CustomDevelopmentHeroBlock from './custom_development/CustomDevelopmentHeroBlock';
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png';
import Web2Development from './custom_development/Web2Development';
import Web3Development from './custom_development/Web3Development';
import OtherServices from './custom_development/OtherServices';
import IOSDevelopment from './custom_development/IOSDevelopment';

function CustomDevelopment() {
  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div
          className="custom_development_body"
          data-testid="custom_development__page"
        >
          <div
            className="custom_development_header__section"
            style={headerBackgroundImageStyle}
          >
            <Header />
            <CustomDevelopmentHeroBlock />
            <Web2Development />
            <Web3Development />
            <IOSDevelopment />
            <OtherServices />
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomDevelopment

