import React from 'react';
import CryptoEnvironmentsHeroLeft from './hero_block/CryptoEnvironmentsHeroLeft'
import CryptoEnvironmentsHeroRight from './hero_block/CryptoEnvironmentsHeroRight'


const CryptoEnvironmentsHeroBlock = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row lg:mb-24" data-testid="hero_block__component">
        <div className="w-full lg:w-1/2">
          <CryptoEnvironmentsHeroLeft />
        </div>
        <div className="w-full lg:w-1/2">
          <CryptoEnvironmentsHeroRight />
        </div>
      </div>
    </>
  )
}

export default CryptoEnvironmentsHeroBlock