import React from 'react'
import solanaEnvironmentIcon from '../../../../assets/images/solana_environment__icon.png';
import SubHeading from '../../../ui/SubHeading';

const SolanaEnvironment = () => {
  return ( 
    <>
      <div className="specialty__card flex-col m-6 mb-12 md:ml-12 md:m-2 md:w-5/12 2xl:m-6 2xl:w-1/5" data-testid="specialty__card">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="h-48 rounded-t-lg flex items-center justify-center">
            
            <img
              src={solanaEnvironmentIcon}
              alt="Solana Environment Top Image"
              id="solana_environment__icon"
            />
            
          </div>
        </div>
        <div className="bottom_our_specialties__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="p-3">

            <div className="our_specialties__seperator border-b-2 mb-12" />
            
            <div className="w-full text-center">
              <center>
                <SubHeading
                  text="SOLANA ENVIRONMENT"
                  className="text-lg sm:text-sm md:text-md text-white mb-12"
                  alt="Solana Environment Heading"
                  id="solanaEnvironmentText"
                />
              </center>
            </div>

            <p className="text-gray-400 text-lg" data-testid="solana_environment__description">
              Solana is a blockchain platform and cryptocurrency that allows for decentralized applications and smart contracts. It was founded in 2017 by Anatoly Yakovenko and Solana Labs, and was       originally called Loom before being renamed after the beach town near San Diego where the co-founders lived.
            </p>

          </div>
        </div>
      </div>
    </>
  );
}
 
export default SolanaEnvironment;