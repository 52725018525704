import React from 'react';

import regulatoryCompliance from '../../../../assets/images/why_work_with_us/regulatory_compliance.png';
import investorConfidence from '../../../../assets/images/why_work_with_us/investor_confidence.png';
import complexityOfTokenomics from '../../../../assets/images/why_work_with_us/complexity_of_tokenomics.png';
import communityTrust from '../../../../assets/images/why_work_with_us/community_trust.png';

const ThirdCol = () => {
  return ( 
    <>
      <div className="flex-row w-full lg:flex-col lg:w-1/3">
        <div className="flex-row w-full pr-6">
          <table className="w-full table-auto">
            <tbody>
              <tr>
                <td width="25%">
                  <center>
                    <img
                      src={regulatoryCompliance}
                      alt="Regulatory Compliance Can Be Achieved with Cypher-Tech Labs"
                      className="w-full p-8"
                    />
                  </center>
                </td>
                <td width="75%">
                  <p className="text-lg text-gray-300 leading-relaxed pl-6">
                    <strong>1. Regulatory Compliance</strong><br/>
                    Depending on your jurisdiction, there may be regulatory requirements mandating audits for certain types of tokens,
                    especially if they involve financial transactions, securities, or significant value transfer. It's essential to consult
                    legal experts familiar with cryptocurrency regulations in your area.
                  </p>
                </td>
              </tr>
              <tr>
                <td width="100%" colSpan={2}>
                  <br/><br/><br/>
                </td>
              </tr>
              <tr>
                <td width="25%">
                  <center>
                    <img
                      src={investorConfidence}
                      alt="Investor Confidence Can Be Achieved with Cypher-Tech Labs"
                      className="w-full p-8"
                    />
                  </center>
                </td>
                <td width="75%">
                  <p className="text-lg text-gray-300 leading-relaxed pl-6">
                    <strong>2. Investor Confidence</strong><br/>
                    Audits can increase investor confidence by providing assurance that your token and its underlying smart contracts
                    have been thoroughly reviewed for security, functionality, and compliance. This can be particularly important if
                    you're conducting a public sale or seeking investment from institutional investors.
                  </p>
                </td>
              </tr>
              <tr>
                <td width="100%" colSpan={2}>
                  <br/><br/><br/>
                </td>
              </tr>
              <tr>
                <td width="25%">
                  <center>
                    <img 
                      src={complexityOfTokenomics} 
                      alt="Complexity of Tokenomics Can Be Achieved with Cypher-Tech Labs" 
                      className="w-full p-8" 
                    />
                  </center>
                </td>
                <td width="75%">
                  <p className="text-lg text-gray-300 leading-relaxed pl-6">
                    <strong>3. Complexity of Tokenomics</strong><br/>
                    If your token involves complex tokenomics, such as staking mechanisms, yield farming, or governance features,
                    an audit becomes even more crucial. These features can introduce additional complexities and potential vulnerabilities
                    that require thorough review.
                  </p>
                </td>
              </tr>
              <tr>
                <td width="100%" colSpan={2}>
                  <br/><br/><br/>
                </td>
              </tr>
              <tr>
                <td width="25%">
                  <center>
                    <img  
                      src={communityTrust}
                      alt="Regulatory Compliance Can Be Achieved with Cypher-Tech Labs"
                      className="w-full p-8"
                    />
                  </center>
                </td>
                <td width="75%">
                  <p className="text-lg text-gray-300 leading-relaxed pl-6">
                    <strong>4. Community Trust</strong><br/>
                    Demonstrating a commitment to transparency and security through an audit can help build trust within your community
                    of users and investors. This can be particularly important for the long-term success and adoption of your token.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
 
export default ThirdCol;