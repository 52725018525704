import React from 'react';
import Header from '../global/Header';
import Footer from '../global/Footer';
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png';

import fourOhFourSadBotImage from '../../assets/images/404_page_not_found.png';

import SubHeading from '../ui/SubHeading';
import P from '../ui/P';


function PaymentOptions() {

  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="no_match_body" data-testid="no_match__page">
          <div className="no_match_header__section" style={headerBackgroundImageStyle}>
            <Header/>
            
            <div className="m-1 no_match__card lg:m-6" data-testid="no_match__card">
              <div className="p-2 bg-[#17243D] text-white rounded-t-lg shadow-xl lg:p-6 w-full">
                <div className="rounded-t-lg flex items-center justify-center">
                  <SubHeading
                    text="Page Not Found"
                    alt="404 Error - Page Not Found - CypherTech Labs - Technology Company"
                    id="no_match__heading"
                  />
                </div>
              </div>
              <div className="p-1 bottom_no_match__card bg-[#0C1320] text-white rounded-b-lg shadow-xl lg:p-6 w-full">
                <div className="p-1 lg:p-3">
                  
                  <div className="w-full items-center justify-center flex flex-col">
                    <center>
                      <a href="/">
                        <img
                          src={fourOhFourSadBotImage}
                          alt="404 Error - Page Not Found - CypherTech Labs - Technology Company"
                          className="w-full xs:w-full sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-2/3 rounded-lg"
                        />
                      </a>
                      <P className="no_match__description text-xl pt-3 w-full text-center mb-0">
                        The page you are looking for does not exist return to the menu above to find your way back to more important information.
                      </P>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentOptions