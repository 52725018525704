import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '../global/Header'
import Footer from '../global/Footer'

import KYCPentestingHeroBlock from './kyc_penetration_testing/KYCPentestingHeroBlock';
import KYCVerifications from './kyc_penetration_testing/kyc_verifications/KYCVerifications';
import PenetrationTesting from './kyc_penetration_testing/penetration_testing/PenetrationTesting';
import BugBountyPrograms from './kyc_penetration_testing/bug_bounty_programs/BugBountyPrograms';

import headerBackgroundImage from '../../assets/images/background_image_mask__small.png';

function KYCVerificationPenetrationTestingBugBounty() {

  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  const location = useLocation();

  useEffect(() => {
    const scrollToHashElement = () => {
      const hash = window.location.hash;

      if (hash) {
        const elementId = hash.substring(1);
        const element = document.getElementById(elementId);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    scrollToHashElement();
    window.addEventListener('hashchange', scrollToHashElement);
    return () => {
      window.removeEventListener('hashchange', scrollToHashElement);
    };
  }, [location]);

  return (
    <>
      <div style={backgroundStyle}>
        <div className="kyc_pentesting_body" data-testid="kyc_verification_and_pentesting__page">
          <div className="kyc_pentesting_header__section" style={headerBackgroundImageStyle}>
            <Header/>
            
            <KYCPentestingHeroBlock/>

            {/* KYCVerifications - new styling applied here */}
            <KYCVerifications/>
            
            {/* TODO: PenetrationTesting - NO NEW STYLING APPLIED */}
            <PenetrationTesting/>

            {/* TODO: BugBountyPrograms - NO NEW STYLING APPLIED */}
            <BugBountyPrograms/>
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default KYCVerificationPenetrationTestingBugBounty