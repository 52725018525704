import React from 'react'

import howDoAuditsWorkTitle from '../../../../assets/images/how_do_audits_work__text.png';

const InitialHowDoAuditsWork = () => {
  return ( 
    <>
      <div className="flex flex-col items-center justify-center px-auto" data-testid="how_do_audits_work__card">
        <img src={howDoAuditsWorkTitle} className="py-8" />
      </div>
    </>
   );
}
 
export default InitialHowDoAuditsWork;