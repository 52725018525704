import React from 'react'

import KYCPentestingHeroLeft from './hero_block/KYCPentestingHeroLeft'
import KYCPentestingHeroRight from './hero_block/KYCPentestingHeroRight'

const KYCPentestingHeroBlock = () => {
  return ( 
    <>
      <div className="flex flex-col lg:flex-row lg:mb-24">
        <div className="w-full lg:w-1/2">
          <KYCPentestingHeroLeft />
        </div>
        <div className="w-full lg:w-1/2">
          <KYCPentestingHeroRight />
        </div>
      </div>
    </>
   );
}
 
export default KYCPentestingHeroBlock;