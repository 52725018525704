import React from 'react';
import Heading from '../../../ui/Heading';
import IntroParagraph from '../../../ui/IntroParagraph';

const HeroLeft = () => {
  return (
    <>

      <div className="mt-3">
        <Heading
          className="text-4xl font-bold text-left mt-6 mx-12 mb-0 sm:mt-6 md:ml-6 lg:ml-32 lg:justify-center"
          id="crypto_environments_and_blockchains__text"
          alt="Cypher-Tech Labs - KYC Verification, Penetration Testing, and Bug Bounty Services"
          testId="crypto_environments_and_blockchains__text"
        >
          
          CRYPTO ENVIRONMENTS<br/>
          AND BLOCKCHAINS

        </Heading>
      </div>

      <IntroParagraph

        id="introductory_paragraph"
        testId="crypto_environments__introductory_paragraph"

      >

        Cypher-Tech Labs is a company that specializes in providing comprehensive security assessments for blockchain-based projects. We have a team of highly experienced security researchers and auditors who are well-versed in smart contract vulnerabilities and best practices.

      </IntroParagraph>

    </>
  );
}
 
export default HeroLeft;