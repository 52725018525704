import React from 'react'
import { cn } from '../../lib/utilities';

const SmallSubHeading = ({ text, className }) => { 
  const textToDataTestId = (inputText) => {
    return inputText.toLowerCase().replace(/\s+/g, '_');
  };

  const testId = textToDataTestId(text);

  return (
    <>
      <p
        className={cn("small_heading__font text-lg mb-1", className, {})}
        data-testid={`${testId}__small_subheading`}
      >
        {text}
      </p>
    </>
  );
}

export default SmallSubHeading;