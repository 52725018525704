import React from 'react';
import Heading from '../../../ui/Heading';
import IntroParagraph from '../../../ui/IntroParagraph';

const HeroLeft = () => {
  return ( 
    <>

      <div className="mt-3">
        <Heading
          className="text-4xl font-bold text-left mt-6 mx-12 mb-0 sm:mt-6 md:ml-6 lg:ml-32 lg:justify-center"
          id="kyc_penetration_testing__text"
          alt="Cypher-Tech Labs - KYC Verification, Penetration Testing, and Bug Bounty Services"
          testId="kyc_penetration_testing__text"
        >

          KYC VERIFICATIONS,<br/>
          PENETRATION TESTING,<br/>
          BUG-BOUNTY PROGRAMS

        </Heading>
      </div>

      <IntroParagraph

       id="introductory_paragraph"
       testId="kyc_penetration_testing__introductory_paragraph"
      
      >

        Cypher-Tech Labs specializes in providing comprehensive security assessments of blockchain based projects. Our highly experienced security team is well-versed in smart contract vulnerabilities.

      </IntroParagraph>

    </>
  );
}
 
export default HeroLeft;