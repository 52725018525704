import React from 'react';
import Heading from '../../../ui/Heading';
import IntroParagraph from '../../../ui/IntroParagraph';

const HeroLeft = () => {
  return (
    <>

      <div className="mt-3" data-testid="hero_left__component">
        <Heading
          className="text-4xl font-bold text-left mt-6 mx-12 mb-0 sm:mt-6 md:ml-6 lg:ml-32 lg:justify-center"
          id="custom_development__text"
          alt="Cypher-Tech Labs - Custom Development Services"
          testId="custom_development__text"
        >
          
          CUSTOM WEB DEVELOPMENT<br/>
          AND OTHER SERVICES

        </Heading>
      </div>
    
      <IntroParagraph

        id="introductory_paragraph"
        testId="custom_development__introductory_paragraph"

      >

        Cypher-Tech Labs specializes in providing comprehensive security assessments, however, we also provide stellar web design and development services for both Web2 and Web3 projects. Our team of highly experienced security researchers all got their start in the business by developing websites and web applications. We would be happy to provide this as a service as well.

      </IntroParagraph>

    </>
  );
}
 
export default HeroLeft;