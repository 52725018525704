import React, { useState, useEffect } from 'react'
import customDevelopmentHeroImage from '../../../../assets/images/custom_development/custom_development__hero_image.png'

const HeroRight = () => {

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);
  
  return ( 
    <>
      <div className="hidden w-full lg:flex lg:w-full" data-testid="hero_right__component">
        <center>
          <img
            src={customDevelopmentHeroImage}
            alt="Custom Development Services Image"
            className={`transition-opacity w-full h-full pr-12 rounded duration-300 ease-in ${
              isLoaded ? 'opacity-100' : 'opacity-0'
            }`}
          />
        </center>
      </div>
      <div className="flex w-full p-12 lg:hidden">
        <center>
          <img
            src={customDevelopmentHeroImage}
            alt="Custom Development Services Image"
            id="custom_development_image"
            className={`transition-opacity w-full rounded duration-600 ease-in ${
              isLoaded ? 'opacity-100' : 'opacity-0'
            }`}
          />
        </center>
      </div>
    </>
   );
}
 
export default HeroRight;