import React from 'react';
import Button from '../ui/Button';

const RequestAQuote = () => {
  return (
    <>
      <div className="w-full my-6">
        <center>
          <Button
            url="https://docs.google.com/forms/d/1qqzA0Spj8qdL11DwAo5G6IrnMF5A73_IpwMO8jEDClk/viewform"
            text="Request A Quote"
            dataTestId="custom_development_request__form_button"
            className="text-2xl"
          />
        </center>
      </div>
    </>
  )
}

export default RequestAQuote