import React from 'react';

import Header from '../global/Header';
import Footer from '../global/Footer';

import headerBackgroundImage from '../../assets/images/background_image_mask__small.png';
import privacyPolicyHeadingText from '../../assets/images/privacy_policy__heading_text.png';

import SubHeading from '../ui/SubHeading';
import SmallSubHeading from '../ui/SmallSubHeading';
import P from '../ui/P';

function PrivacyPolicy() {

  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="privacy_policy_body" data-testid="privacy_policy__page">
          <div className="privacy_policy_header__section" style={headerBackgroundImageStyle}>
            <Header/>
            
            <div className="privacy_policy__card m-6" data-testid="privacy_policy__card">
              <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
                <div className="rounded-t-lg flex items-center justify-center">
                  <SubHeading
                    text="Privacy Policy"
                    alt="Privacy Policy - CypherTech Labs - Technology Company"
                    id="privacy_policy__heading"
                  />
                </div>
              </div>
              <div className="bottom_privacy_policy__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
                <div className="p-3">
                  <P className="privacy_policy__general_description text-2xl">
                    At CypherTech-Labs, accessible from <a href="/">cyphertech-labs.io</a>, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by CypherTech-Labs and how we use it.
                  </P>

                  <P className="privacy_policy__general_description_2 text-2xl">
                    If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                  </P>

                  <SmallSubHeading
                    text="CONSENT"
                    className="privacy_policy__consent_heading text-xl"
                  />

                  <P className="privacy_policy__consent_description text-2xl">
                    By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                  </P>

                  <SmallSubHeading
                    text="INFORMATION WE COLLECT"
                    className="privacy_policy__information_we_collect_heading text-xl"
                  />

                  <P className="privacy_policy__information_we_collect_description text-2xl">
                    The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                  </P>

                  <P className="privacy_policy__information_we_collect_description_2 text-2xl">
                    If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                  </P>

                  <P className="privacy_policy__information_we_collect_description_3 text-2xl">
                    When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                  </P>

                  <SmallSubHeading
                    text="HOW WE USE YOUR INFORMATION"
                    className="privacy_policy__how_we_use_your_information__heading text-xl"
                  />

                  <P className="privacy_policy__how_we_use_your_information__description text-2xl">
                    We use the information we collect in various ways, including:
                  </P>

                  <ul className="text-gray-400 text-2xl" data-testid="privacy_policy__how_we_use_your_information__list">
                    <li>Provide, operate, and maintain our website</li>
                    <li>Improve, personalize, and expand our website</li>
                    <li>Understand and analyze how you use our website</li>
                    <li>Develop new products, services, features, and functionality</li>
                    <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                    <li>Send you emails for marketing purposes</li>
                    <li>Find, Prevent, Mitigate, and Stop Fraud from Occurring</li>
                  </ul>

                  <br/><br/>

                  <SmallSubHeading
                    text="LOG FILES"
                    className="privacy_policy__log_files__heading text-xl"
                  />

                  <P className="privacy_policy__log_files__description text-2xl">
                    CypherTech-Labs follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
                  </P>

                  <SmallSubHeading
                    text="ADVERTISING PARTNERS PRIVACY POLICIES"
                    className="privacy_policy__advertising_partners__heading text-xl"
                  />

                  <P className="privacy_policy__advertising_partners__description text-2xl">
                    You may consult this list to find the Privacy Policy for each of the advertising partners of CypherTech-Labs.
                  </P>

                  <P className="privacy_policy__advertising_partners__description_2 text-2xl">
                    Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on CypherTech-Labs, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                  </P>

                  <P className="privacy_policy__advertising_partners__description_3 text-2xl">
                    Note that CypherTech-Labs has no access to or control over these cookies that are used by third-party advertisers.
                  </P>

                  <SmallSubHeading
                    text="THIRD PARTY PRIVACY POLICIES"
                    className="privacy_policy__third_party_privacy_policies__heading text-xl"
                  />

                  <P className="privacy_policy__third_party_privacy_policies__description text-2xl">
                    CypherTech-Labs's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                  </P>

                  <P className="privacy_policy__third_party_privacy_policies__description_2 text-2xl">
                    You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
                  </P>

                  <SmallSubHeading
                    text="CCPA PRIVACY RIGHTS (DO NOT SELL MY PERSONAL INFORMATION)"
                    className="privacy_policy__do_not_sell_my_info__heading text-xl"
                  />

                  <P className="privacy_policy__do_not_sell_my_info__description text-2xl">
                    Under the CCPA, among other rights, California consumers have the right to:
                  </P>
                  
                  <P className="privacy_policy__do_not_sell_my_info__description_2 text-2xl">
                    Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
                  </P>

                  <P className="privacy_policy__do_not_sell_my_info__description_3 text-2xl">
                    Request that a business delete any personal data about the consumer that a business has collected.
                  </P>

                  <P className="privacy_policy__do_not_sell_my_info__description_4 text-2xl">
                    Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
                  </P>

                  <P className="privacy_policy__do_not_sell_my_info__description_5 text-2xl">
                    If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                  </P>

                  <SmallSubHeading
                    text="GDPR Data Protection Rights"
                    className="privacy_policy__gdpr_data_protection_rights__heading text-xl"
                  />

                  <P className="privacy_policy__gdpr_data_protection_rights__description text-2xl">
                    We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                  </P>

                  <P className="privacy_policy__gdpr_data_protection_rights__description_2 text-2xl">
                    The right to access - You have the right to request copies of your personal data. We may charge you a small fee for this service.
                  </P>

                  <P className="privacy_policy__gdpr_data_protection_rights__description_3 text-2xl">
                    The right to rectification - You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                  </P>

                  <P className="privacy_policy__gdpr_data_protection_rights__description_4 text-2xl">
                    The right to erasure - You have the right to request that we erase your personal data, under certain conditions.
                  </P>

                  <P className="privacy_policy__gdpr_data_protection_rights__description_5 text-2xl">
                    The right to restrict processing - You have the right to request that we restrict the processing of your personal data, under certain conditions.
                  </P>

                  <P className="privacy_policy__gdpr_data_protection_rights__description_6 text-2xl">
                    The right to object to processing - You have the right to object to our processing of your personal data, under certain conditions.
                  </P>

                  <P className="privacy_policy__gdpr_data_protection_rights__description_7 text-2xl">
                    The right to data portability - You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                  </P>

                  <P className="privacy_policy__gdpr_data_protection_rights__description_8 text-2xl">
                    If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                  </P>

                  <SmallSubHeading
                    text="Children's Information"
                    className="privacy_policy__childrens_information__heading text-xl"
                  />

                  <P className="privacy_policy__childrens_information__description text-2xl">
                    Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                  </P>

                  <P className="privacy_policy__childrens_information__description_2 text-2xl">
                    CypherTech-Labs does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                  </P>
                </div>
              </div>
            </div>
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
