import React from 'react';

import appleDesignResources from '../../../assets/images/custom_development/apple_design_resources__image.png';
import advancedAppleDevelopmentEnvironments from '../../../assets/images/custom_development/advanced_apple_development_environments__image.png';
import SubHeading from '../../ui/SubHeading';
import RequestAQuote from '../RequestAQuote';

const   IOSDevelopment = () => {
  return ( 
    <>
      <div className="iOS_development__card m-6" id="iOS_development" data-testid="ios_development__component">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="rounded-t-lg flex items-center justify-center">
            <SubHeading
              text="IOS, IPADOS, MACOS, & ANDROID DEVELOPMENT"
            />
          </div>
        </div>
        <div className="bottom_other_services__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="flex flex-col lg:flex-row lg:mb-24">
            <div className="w-full lg:w-1/2 pr-12">
              <SubHeading text="MOBILE ECOSYSTEM APPLICATION DEVELOPMENT" />
              <p className="text-gray-400 text-xl mb-3">
                Mobile application development is the process of creating software applications that run on a mobile device, and a typical mobile application utilizes
                a network connection to work with remote computing resources. Hence, the mobile development process involves creating installable software bundles
                (code, binaries, assets, etc.) , implementing backend services such as data access with an API, and testing the application on target devices.
              </p>
              <br/>
              <p className="text-gray-400 text-xl mb-3">
              There are two dominant platforms in the modern smartphone market. One is the iOS platform from Apple Inc. The iOS platform is the operating system
              that powers Apple's popular line of iPhone smartphones. The second is Android from Google. The Android operating system is used not only by Google devices
              but also by many other OEMs to built their own smartphones and other smart devices.
              </p>
              <br/>
              <p className="text-gray-400 text-xl mb-3">
                Although there are some similarities between these two platforms when building applications, developing for iOS vs. developing for Android involves using
                different software development kits (SDKs) and different development toolchain. While Apple uses iOS exclusively for its own devices, Google makes Android
                available to other companies provided they meet specific requirements such as including certain Google applications on the devices they ship. Our developers
                can build apps for hundreds of millions of devices by targeting both of these platforms.
              </p>
              <br/>
              <p className="text-gray-400 text-xl mb-3">
                Developing applications for Mac is all about speed and power, and macOS
                helps you maximize the performance of your apps. Mac is ideal for apps that
                require raw processing power to execute tasks as quickly as possible. However,
                you also use it for day-to-day tasks such as communication, news and
                information, social media, games, and much more. macOS offers different
                paths for delivering your content, and the path you choose affects the initial
                decisions you must make:
              </p>
              <br/>
              <ul className="text-gray-400 text-xl mb-8">
                <li>Assembly From Wireframes</li>
                <li>Creating Linear Screens for Feature-Rich Implementation</li>
                <li>Intuitive & Guided Experiences which onboard users with ease</li>
                <li>Software Development without the use of AI</li>
              </ul>

              <SubHeading text="Mobile App Development via" />
              <ol className="link_list text-lg">
                <li>
                  <a href="https://developer.apple.com/xcode/" target="_blank">XCode</a>, <a href="https://developer.apple.com/xcode/swiftui/" target="_blank">SwiftUI</a>, <a href="https://developer.apple.com/documentation/appkit" target="_blank">AppKit</a>
                </li>
                <li>
                  <a href="https://www.electronjs.org/" target="_blank">Electron</a>, <a href="https://reactnative.dev/" target="_blank">React Native</a>
                </li>
                <li>
                    <a href="https://flutter.dev/" target="_blank">Flutter</a>, <a href="https://kotlinlang.org/" target="_blank">Kotlin</a>, <a href="https://cordova.apache.org/" target="_blank">Apache Cordova(formerly PhoneGap)</a></li>
                <li>
                  <a href="https://dotnet.microsoft.com/en-us/apps/xamarin" target="_blank">Xamarin</a>, <a href="https://nativescript.org/" target="_blank">NativeScript</a>, <a href="https://unity.com/solutions/mobile" target="_blank">Unity</a>
                </li>
              </ol>
            </div>
            <div className="w-full lg:w-1/2 flex-1">
              <div className="mt-6">
                <center>
                  <img
                    src={appleDesignResources}
                    alt="Apple Design Resources Image"
                    id="apple_design_resources_image"
                    className="lg:mt-24"
                  />
                </center>

                <div className="mb-20 lg:mb-32" />
                <center>
                  <img
                    src={advancedAppleDevelopmentEnvironments}
                    alt="Apple Design Environments Image"
                    id="apple_design_resources_image"
                  />
                </center>
              </div>
            </div>
          </div>
          <RequestAQuote />
        </div>
      </div>
    </>
   );
}
 
export default IOSDevelopment;