import React, { useState, useEffect } from 'react'
import {APIProvider, Map} from '@vis.gl/react-google-maps';

import Header from '../global/Header'
import Footer from '../global/Footer'
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png';

import SubHeading from '../ui/SubHeading';
import Button from '../ui/Button';
import P from '../ui/P';

import { FcOk } from "react-icons/fc";

import { sendMail } from '../../lib/email';

const darkStyling = [
  {
      "featureType": "all",
      "elementType": "all",
      "stylers": [
          {
              "invert_lightness": true
          },
          {
              "saturation": 10
          },
          {
              "lightness": 30
          },
          {
              "gamma": 0.5
          },
          {
              "hue": "#435158"
          }
      ]
  }
];

function ContactUs() {

  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  // Form Settings
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const [formIsSubmitted, setFormIsSubmitted] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Form Data Validation
  const validateForm = () => {
    const newErrors = {};
    if ( !formData.firstName ) newErrors.firstName = 'First name is required';
    if ( !formData.lastName ) newErrors.lastName = 'Last name is required';
    if ( !formData.email) newErrors.email = 'Email is required';
    if ( !formData.phone ) newErrors.phone = 'Phone number is required';
    if ( !formData.message ) newErrors.message = 'Message is required';
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Set the Subject
      const subject = "New Lead From " + formData.firstName + " " + formData.lastName + " - CypherTech-Labs.io";
      // Set the Body Incrementally with HTML tags
      let body = "";
      body += "<h1>New CypherTech-Labs.io Lead</h1>";
      body += "<p><strong>Name:</strong> " + formData.firstName + " " + formData.lastName + "</p>";
      body += "<p><strong>Email:</strong> <a href='mailto:" + formData.email + "' target='_blank'>" + formData.email + "</a></p>";
      body += "<p><strong>Phone:</strong> <a href='tel:" + formData.phone + "' target='_blank'>" + formData.phone + "</a></p>";
      body += "<p><strong>Message:</strong></p>";
      body += "<p>" + formData.message + "</p>";

      // Run the sendMail f(X) and get the response (boolean)
      const sentMail = sendMail(subject, body);
      // Check if errors occurred stopping the email from sending.
      if ( sentMail ) {
      console.log('Form submitted', formData);
      // Clear the form
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: '',
        });
      // Set the form to submitted to display the success message.
      setFormIsSubmitted(true);
        setErrors({});
      } else {
        alert("There was an error sending your message. Please try again, in a moment.");
        setFormIsSubmitted(false);
      }
    }
  };

  useEffect(() => {
  }, [formIsSubmitted]);

  return (
    <>
      <div style={backgroundStyle}>
        <div className="contact_us_body" data-testid="contact_us__page">
          <div className="contact_us_header__section" style={headerBackgroundImageStyle}>

            <Header/>
            
            <div className="contact_us__card m-6" data-testid="contact_us__card">
              <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
                <div className="rounded-t-lg flex items-center justify-center">

                  <SubHeading 
                    text="CONTACT US"
                    alt="Contact Us - CypherTech Labs - Technology Company - Let's Get It Touch!"
                    id="contact_us__heading"
                    data-testid="contact_us__heading_text"
                    className="px-3"
                  />
                  
                </div>
              </div>
              <div className="bottom_contact_us__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
                <div className="p-3">
                  <P className="contact_us__general_description w-full text-center text-2xl">
                    Thank you for your interest in CypherTech-Labs.io. Please fill out the form below and we will get back to you as soon as possible.
                  </P>

                  <div className="flex flex-col md:flex-row">
                    <div className="w-full px-4 md:w-1/2 md:px-4">
                      {!formIsSubmitted && ( <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div>
                            <label className="block text-white mb-2">First Name</label>
                            <input
                              type="text"
                              name="firstName"
                              value={formData.firstName}
                              onChange={handleChange}
                              className={`contact_form_input placeholder-gray-700 ${errors.firstName && 'border border-red-500'}`}
                              placeholder="John"
                              required
                            />
                            {errors.firstName && <span className="text-red-500">{errors.firstName}</span>}
                          </div>
                          <div>
                            <label className="block text-white mb-2">Last Name</label>
                            <input
                              type="text"
                              name="lastName"
                              value={formData.lastName}
                              onChange={handleChange}
                              className={`contact_form_input placeholder-gray-700 ${errors.lastName && 'border border-red-500'}`}
                              placeholder="Smith"
                              required
                            />
                            {errors.lastName && <span className="text-red-500">{errors.lastName}</span>}
                          </div>
                          <div>
                            <label className="block text-white mb-2">Your Email</label>
                            <input
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              className={`contact_form_input placeholder-gray-700 ${errors.email && 'border border-red-500'}`}
                              placeholder="my_handle@server.com"
                              required
                            />
                            {errors.email && <span className="text-red-500">{errors.email}</span>}
                          </div>
                          <div>
                            <label className="block text-white mb-2">Phone</label>
                            <input
                              type="tel"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                              className={`contact_form_input placeholder-gray-700 ${errors.phone && 'border border-red-500'}`}
                              placeholder="+1(808) 223 5669"
                              required
                            />
                            {errors.phone && <span className="text-red-500">{errors.phone}</span>}
                          </div>
                        </div>
                        <div className="mb-6 mt-4">
                          <label className="block text-white mb-2">Enter your message below.<br/>
                          <small>Please be as descriptive as possible!</small></label>
                          <center>
                            <textarea
                              name="message"
                              value={formData.message}
                              onChange={handleChange}
                              className={`contact_form_input h-[266px] placeholder-gray-700 ${errors.message && 'border border-red-500'}`}
                              placeholder="Your message here..."
                              required
                            />
                            {errors.message && <span className="text-red-500">{errors.message}</span>}
                          </center>
                          <div className="mb-4 text-red-500">
                            *ALL FIELDS REQUIRED
                          </div>
                        </div>
                        <div className="text-center">
                          <Button
                            text="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SEND MESSAGE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" testId="contact_us_form__submit_button"
                          />
                        </div>
                      </form> )}
                      {formIsSubmitted && <SubmissionSuccess />}
                    </div>
                    <div className="w-full mt-12 md:w-1/2 md:px-4 md:mt-0">
                      <center>
                      <label className="block text-white mb-2">Find Us in Atlanta, Ga</label>
                      <APIProvider apiKey={process.env.GOOGLE_MAPS_API_KEY}>
                        <Map
                          style={{width: '100%', height: '530px'}}
                          defaultCenter={{ lat: 33.7490, lng: -84.3880 }}
                          defaultZoom={13}
                          gestureHandling={'greedy'}
                          disableDefaultUI={true}
                          options={{
                            styles: darkStyling,
                          }}
                        />
                      </APIProvider>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs

const SubmissionSuccess = () => {
  return (
    <div className="w-full">
      <div className="w-full">
        <center>
          <FcOk className="text-8xl text-green-400 my-6" />
          <h1 className="text-3xl font-bold text-green-400 mt-12">Success!</h1>
          <p className="text-green-300 text-2xl mt-6">Thank you for submitting your message. We will get back to you as soon as possible. If you do not hear from us quickly, please email us directly at <a href="mailto:cyphertech-labs.io" target="_blank" style={{textDecoration: "underline"}}>contact@cyphertech-labs.io</a></p>
        </center>
      </div>
    </div>
  );
}