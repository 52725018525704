import React from 'react';

import whyWorkWithUs from '../../../../assets/images/why_work_with_us/why_work_with_us.png';

const WhyWorkWithUsTitle = () => {
  return ( 
    <>
      <br/><br/><br/>
      <div className="bg-black flex items-center w-full sm:mb-6 md:mb-12 lg:mb-24" data-testid="work_with_us__section">
        <div className="flex-row w-full">
          <center>
            <img
              src={whyWorkWithUs}
              alt="Why Work With Us"
              className="px-12"
            />
          </center>
        </div>
      </div>
    </>
  );
}
 
export default WhyWorkWithUsTitle;