import React from 'react'
import Navigation from './Navigation'

const Header = () => {
  return ( 
    <>
      <Navigation />
    </>
   )
}
 
export default Header;