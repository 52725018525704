import React from "react";

import auditBadge from "../../../assets/images/audit_badge/audit_badge__text.png";
import auditBadgeIcon from "../../../assets/images/audit_badge/audit_badge__icon.png";
import requestAnAudit from "../../../assets/images/audit_badge/request_an_audit__button.png";

const AuditBadge = () => {
  return ( 
    <>
      <div className="w-full mb-12">
        <center>
          <img
            src={auditBadge}
            alt="Cypher-Tech Labs - Audit Badge - Seal of Safety and Authenticity"
            className="audit_badge__text"
          />
        </center>
      </div>
      <div className="flex flex-col-reverse lg:flex-row w-full" id="audit_badge__bottom_section">
        <div className="flex-col w-full mt-6 md:mt-12 lg:mt-24 lg:w-2/3">
          <div className="text-md text-white px-12">
            In the ever-changing world of cryptocurrencies, where new ideas and chances to succeed are everywhere,
            making sure things are safe and trustworthy is important. The Audit Badge, a special mark given
            to projects that have been carefully checked by our cyber security experts, and is a big part of
            keeping this system safe.
            <br /><br />
            <strong>1. Enhanced Trust and Reliability:</strong>
            <br />
            In a place where scams are common, the Audit Badge shows you who you can trust. It means that experts
            have carefully checked a project, looked at its code, and tested its security. This assurance is invaluable
            to investors and users alike, fostering confidence in the project's integrity.
            <br /><br />
            <strong>2. Transparency and Accountability:</strong>
            <br />
            Cryptocurrency projects bearing the Audit Badge demonstrate a commitment to transparency and accountability.
            By subjecting themselves to independent audits, they open their operations to inspection, showcasing their
            adherence to best practices and industry standards. This transparency not only builds trust but also establishes
            a precedent for responsible conduct within the community.
            <br /><br />

            <strong>3. Mitigation of Risks:</strong>
            <br />
            With billions of dollars at stake in the cryptocurrency market, the potential for financial loss due to security
            vulnerabilities is a significant concern. Projects that have earned the Audit Badge have taken proactive measures
            to identify and rectify such vulnerabilities, mitigating risks for investors and users. This proactive approach not
            only safeguards assets but also contributes to the overall stability of the ecosystem.
            <br /><br />

            <strong>4. Market Differentiation and Credibility:</strong>
            <br />
            In a crowded marketplace flooded with new projects vying for attention, the Audit Badge serves as a powerful differentiator.
            Projects adorned with this badge stand out from the crowd, signaling their commitment to security and professionalism.
            This credibility can significantly impact investor sentiment, attracting capital and fostering long-term growth.
            <br /><br />

            <strong>5. Recognition and Validation: </strong>
            <br />
            For cryptocurrency projects, earning the Audit Badge is more than just a symbolic gesture—it is a tangible validation of
            their efforts. It serves as recognition of their dedication to security and their willingness to uphold the highest
            standards of integrity. This validation not only instills confidence within the project team but also garners respect
            and admiration from the broader community.
            <br /><br />
            In conclusion, the Audit Badge holds immense significance in the cryptocurrency ecosystem, serving as a symbol of trust,
            transparency, and credibility. As the industry continues to evolve, its importance will only grow, reinforcing the
            principles of security and accountability that underpin its success. For investors and users alike, the Audit Badge
            is not just a mark of approval—it is a beacon of assurance in an ever-changing landscape.
          </div>
        </div>
        <div className="flex items-center justify-center flex-col mt-4 ml-4 md:mt-12 md:ml-9 lg:w-1/3 lg:ml-18 lg:mt-24 lg:items-start">
          <center>
            <img
              src={auditBadgeIcon}
              alt="Cypher-Tech Labs - Audit Badge - Seal of Safety and Authenticity"
              className="pb-6"
            />
            <a href="https://docs.google.com/forms/d/e/1FAIpQLScUbcQFk7lPlGk4_xgGWVkPkhcXovAwZpCVXhLKoEiJ6C5jNw/viewform?usp=sf_link" alt="Opens New Window to Request an Audit" target="_blank">
              <img
                src={requestAnAudit}
                alt="Cypher-Tech Labs - Request an Audit"
              />
            </a>
          </center>
        </div>
      </div>
      <div className="pb-12 sm:pb-12 md:pb-12 lg:pb-12"/>
    </>
  );
}
 
export default AuditBadge;