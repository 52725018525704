import React from 'react'

import DesktopNav from './navigation/DesktopNav'
import TabletNav from './navigation/TabletNav'
import MobileNav from './navigation/MobileNav'

const Navigation = () => {
  return (
    <>
      <DesktopNav />
      <TabletNav />
      <MobileNav />
      <div id="header_bottom_spacer" className="hidden lg:flex"/>
    </>
  )
}

export default Navigation
