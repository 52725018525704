import React from 'react';
import { cn } from '../../lib/utilities';

const IntroParagraph = ({ children, className, id, testId }) => {
  return (
    <p className={cn("text-[1.25rem] m-6 mx-12 mt-5 sm:text-[1.25rem] md:text-[1.5rem] md:ml-6 md:mr-10 lg:text-[2rem] lg:ml-32 lg:mr-24", className, {})} id={id} data-testid={testId}>
      {children}
    </p>
  );
};

export default IntroParagraph;