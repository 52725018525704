import React from 'react'
import Header from '../global/Header'
import Footer from '../global/Footer'
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png'
import IOSDevelopment from './custom_development/IOSDevelopment'

function IOSAppDevelopment() {
  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="ios_app_development_body" data-testid="ios_app_development__page">
          <div className="ios_app_development__section" style={headerBackgroundImageStyle}>
            <Header/>
            <IOSDevelopment />
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default IOSAppDevelopment