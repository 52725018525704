import React from 'react'

import InitialSpecialties from './our_specialties/InitialSpecialties'
import EthereumEnvironment from './our_specialties/EthereumEnvironment'
import BaseEnvironment from './our_specialties/BaseEnvironment'
import SolanaEnvironment from './our_specialties/SolanaEnvironment'
import DogeEnvironment from './our_specialties/DogeEnvironment'

const OurSpecialties = () => {
  return ( 
    <>
      <div className="w-full homepage_body__our_capabilities">
        <div
          className="flex flex-wrap lg:flex-nowrap lg:px-24"
          id="our_specialties__section"
          data-testid="our_specialties__section"
        >
          <InitialSpecialties />
          <EthereumEnvironment />
          <BaseEnvironment />
          <SolanaEnvironment />
          <DogeEnvironment />
        </div>
      </div>
    </>
  );
}
 
export default OurSpecialties;