import React, { useEffect } from 'react';
import Header from '../global/Header'
import Footer from '../global/Footer'
import CryptoEnvironmentsHeroBlock from './crypto_environments/CryptoEnvironmentsHeroBlock';
import RequestAnAudit from './crypto_environments/RequestAnAudit';
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png';
import EthereumEnvironments from './crypto_environments/EthereumEnvironments';
import BaseEnvironments from './crypto_environments/BaseEnvironments';
import DogeEnvironments from './crypto_environments/DogeEnvironments';
import SolanaEnvironments from './crypto_environments/SolanaEnvironments';

function CryptoEnvironments() {
  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="crypto_environments_body" data-testid="crypto_environments__page">
          <div className="crypto_environments_header__section" style={headerBackgroundImageStyle}>
            <Header/>
            <CryptoEnvironmentsHeroBlock/>
            <RequestAnAudit/>
            <EthereumEnvironments/>
            <RequestAnAudit/>
            <BaseEnvironments/>
            <RequestAnAudit/>
            <SolanaEnvironments/>
            <RequestAnAudit/>
            <DogeEnvironments/>
            <RequestAnAudit/>
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CryptoEnvironments