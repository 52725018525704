import React from 'react'
import Button from '../../../ui/Button';
import SubHeading from '../../../ui/SubHeading';
import SmallSubHeading from '../../../ui/SmallSubHeading';

const KYCVerifications = () => {
  return (
    <div className="kyc_verifications__card m-6" data-testid="kyc_verifications__card">
      <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
        <div className="rounded-t-lg flex items-center justify-center">
          <SubHeading
            text="KYC VERIFICATIONS"
          />
        </div>
      </div>
      <div className="bottom_kyc_verifications__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
        <div className="p-3">
          <div className="kyc_verifications__seperator border-b-2 mb-12" />

          <SubHeading text="WHAT IS OUR KYC VERIFICATION SERVICE?" />
          <p className="text-gray-400 text-2xl mb-12" data-testid="kyc_verification__description_paragraph">
            Our KYC service involves a comprehensive verification process for project teams and individuals involved in blockchain projects. With this service,
            we will prevent fraud, enhance your partnership and your projects transparency which will in turn promote ethical practices within the crypto space
            ensuring legitimacy and transparency.
          </p>

          <SubHeading text="HOW DOES OUR KYC VERIFICATION PROCESS WORK?" />
          <p className="text-gray-400 text-2xl mb-12">
            <span className="text-gray-300 font-bold" data-testid="kycVerificationProcessItemOne">1. Identity Verification</span><br/>
            We verify the identities of project team members through official documents, and identity checks.
          <br/>
            <span className="text-gray-300 font-bold" data-testid="kycVerificationProcessItemTwo">2. Background Checks</span><br/>
            Our process includes background checks to ensure that team members have a clean track record.
          <br/>
            <span className="text-gray-300 font-bold" data-testid="kycVerificationProcessItemThree">3. Risk Assessment</span><br/>
            We assess potential risks associated with team members, to make sure that there is limited risk for 
            potential investors.
          </p>

          <p className="good_times_font__heading text-2xl mb-1" data-testid="what_are_the_benefits_of_being_kyc_verified?__subheading">WHAT ARE THE BENEFITS OF BEING KYC VERIFIED?</p>
          <p className="text-gray-400 text-2xl mb-1">
            By utilizing our KYC service, project owners can:
          </p>
          <ul className="text-gray-400 text-2xl mb-12">
            <li className="mb-2" data-testid="kycBenefitsItemOne"><span className="text-gray-300 font-bold">Boost Credibility </span><br/>
                Display the Cypher-Tech Labs KYC Verified Badge, showing that your team has undergone a thorough verification process.
            </li>
            <li className="mb-2" data-testid="kycBenefitsItemTwo"><span className="text-gray-300 font-bold">Attract Investors </span><br/>
                Investors value projects that prioritize transparency and legitimacy, making your project more appealing.
            </li>
            <li className="mb-2" data-testid="kycBenefitsItemThree"><span className="text-gray-300 font-bold">Ensure Compliance </span><br/>
                Align with regulatory standards by conducting proper due diligence on team members.
            </li>
          </ul>

          <SubHeading text="WHAT ARE THE BENEFITS FOR YOUR INVESTORS?" />
          <p className="text-gray-400 text-2xl mb-1">
            Investors benefit from our KYC Verification Service by:
          </p>
          <ul className="text-gray-400 text-2xl mb-4">
            <li className="mb-2"><span className="text-gray-300 font-bold">Reducing Risks </span><br/>
                Invest in projects with verified team members, reducing the likelihood of scams and fraudulent activities.
            </li>
            <li className="mb-2"><span className="text-gray-300 font-bold">Ensuring Transparency </span><br/>
                Support projects that prioritize transparency and ethical practices, contributing to a healthier crypto ecosystem.
            </li>
          </ul>
          <p className="text-gray-400 text-2xl mb-12">
            At Cypher-Tech Labs, we are committed to elevating the standards of integrity and security in the crypto industry. Our KYC service is a critical step toward building 
            a trustworthy and transparent blockchain environment.
          </p>

          <SubHeading text="HOW TO GET STARTED WITH KYC VERIFICATION?" />
          <p className="text-gray-400 text-2xl mb-12">
            To get started with KYC, you need to contact us and request KYC Service. We will than get in touch with you informing you of the process and sending you the list of documents and information we require to complete the KYC process.<br/>
          </p>
          
          <div className="w-full">
            <center>
              <SmallSubHeading text="CONTACT US TODAY TO GET STARTED WITH KYC VERIFICATION" />
            </center>
          </div>
          <div className="flex flex-col lg:flex-row w-full mb-6">
            <div className="w-full">
              <Button
                url="/contact-us"
                text="KYC Verification Request Form"
                dataTestId="kyc_verification_request__form_button"
              />
            </div>
          </div>

          <div className="w-full">
            <div className="text-red-400 text-lg text-right">
              The KYC Verified Badge does not mean we approve of or endorse your project.<br/>
              All investors should apply due diligence to any investment opportunity.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KYCVerifications