import React from 'react'
import smartContractDevelopmentAndAuditsIcon from '../../../../assets/images/smart_contracts_development/smart_contract_development_and_audits__heading_icon.png'

const HeroRight = () => {
  return ( 
    <>
      <div className="hidden w-full justify-center lg:flex lg:w-full">
        <img
          src={smartContractDevelopmentAndAuditsIcon}
          alt="Use your mobile devices with peace of mind with proper security policies."
          id="smart_contracts_development_image"
          data-testid="smart_contracts_development__icon"
          className="lg:mt-6"
        />
      </div>
      <div className="flex w-full justify-center items-center lg:hidden">
        <img
          src={smartContractDevelopmentAndAuditsIcon}
          alt="Use your mobile devices with peace of mind with proper security policies."
          id="smart_contracts_development_image__mobile"
          data-testid="smart_contracts_development__icon__mobile"
          className="p-12"
        />
      </div>
    </>
   );
}
 
export default HeroRight;