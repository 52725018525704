import React from 'react'

import SubHeading from '../../ui/SubHeading';
import P from '../../ui/P';

const BaseEnvironments = () => {
  return (
    <>
      <div className="base_environments__card m-6" id="bug_bounty" data-testid="base_environments__card">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="rounded-t-lg flex items-center justify-center">
            <SubHeading
              text="BASE ENVIRONMENTS"
            />
          </div>
        </div>
        <div className="bottom_base_environments__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="p-3">
            <div className="base_environments__seperator border-b-2 mb-12" />

            <SubHeading text="BASE SMART CONTRACT AUDITING" className="text-xl" />
            <p className="text-gray-400 text-xl mb-8">
              Discover how the BASE network, incubated within Coinbase, is revolutionizing Ethereum Layer 2 (L2) with security,
              scalability, and accessibility in mind. At Cypher-Tech Labs, we bring you the assurance and transparency you need
              for your BASE-based projects.
            </p>
            
            <SubHeading text="WHAT IS BASE?" className="text-xl" />
            <P className="text-gray-400 text-xl mb-12">
              BASE is a secure, low-cost, and builder-friendly Ethereum Layer 2 (L2) solution, designed to usher in the next wave
              of users into the blockchain ecosystem. Incubated within Coinbase, BASE combines the stability, security, and scalability
              needed to power your decentralized applications (dApps).
            </P>

            <SubHeading text="THE IMPORTANCE OF BASE SMART CONTRACT AUDIT" className="text-xl" />
            <P className="text-gray-400 text-xl mb-3">
              BASE Smart Contract Audit is a comprehensive security assessment service tailored for projects built on the BASE network. It's crucial for identifying vulnerabilities, ensuring compliance, and enhancing security for your BASE-based applications.
            </P>
            <P className="text-gray-400 text-xl mb-3">
              BASE Smart Contract Audit is your assurance of robust security and reliability for projects built on the BASE network. Here's why you
              should choose our audit service
            </P>

            <div className="text-gray-400 text-xl mb-3">
              <div className="text-gray-300 font-bold">1. Vulnerability Identification</div>
              Our expert auditors perform a meticulous examination of your BASE-based smart contracts, identifying vulnerabilities and potential risks.<br/><br/>

              <div className="text-gray-300 font-bold">2. Decentralization Confidence</div>
              BASE is committed to progressive decentralization. Our audit validates that your project aligns with the principles of decentralization, creating trust among your users.<br/><br/>

              <div className="text-gray-300 font-bold">3. Open Source Foundation</div>
              BASE is built on open-source technology. Our audit helps ensure your project contributes to the open, global crypto-economy accessible to everyone.<br/><br/>

              <div className="text-gray-300 font-bold">4. Mitigating Discrepancies</div>
              We identify and address any discrepancies between BASE and Ethereum, ensuring a smooth development and user experience.<br/><br/>

              <div className="text-gray-300 font-bold">5. Smart Contract Integrity</div>
              We ensure the integrity of your BASE smart contracts, enhancing their functionality and security.<br/><br/>

              <div className="text-gray-300 font-bold">6. Compatibility Assurance</div>
              BASE is designed to be Ethereum-compatible. Our audit verifies that your project seamlessly integrates with Ethereum L1, Coinbase, and other interoperable chains.<br/><br/>

              <div className="text-gray-300 font-bold">7. Coinbase Integration</div>
              Leveraging Coinbase's products and distribution is made seamless through BASE. Our audit confirms that your project can access Coinbase's user base and fiat onramps.<br/><br/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BaseEnvironments