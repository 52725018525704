import React from 'react';

import SubHeading from '../../ui/SubHeading';

import androidServicesUpper from '../../../assets/images/android_service__upper.png';
import microsoftAzureServicesUpper from '../../../assets/images/microsoft_azure_service__upper.png';
import amazonWebServicesUpper from '../../../assets/images/amazon_web_services__upper.png';
import aiServicesUpper from '../../../assets/images/ai_service__upper.png';

import RequestAQuote from '../RequestAQuote';

const OtherServices = () => {
  return (
    <>
      <div className="other_services__card m-6" id="bug_bounty" data-testid="other_services__component">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="rounded-t-lg flex items-center justify-center">
            <SubHeading
              text="OTHER SERVICES"
            />
          </div>
        </div>
        <div className="bottom_other_services__card bg-[#0C1320] text-white rounded-b-lg shadow-xl w-full">
          <div className="p-6" />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-6 lg:px-24 mb-6">
            <div className="max-w-md bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-auto md:mx-2 md:mb-3 lg:mb-3">
              <a href="/android-app-development">
                <img className="rounded-t-lg" src={androidServicesUpper} alt="Android Development Services Upper" />
              </a>
              <div className="p-5">
                <center>
                  <a href="/android-app-development">
                    <SubHeading text="ANDROID" />
                  </a>
                  <div className="our_specialties__seperator border-b-2 my-6" />
                </center>
                <p className="w-full mb-3 font-normal text-gray-400 text-xl">
                  Android development focuses on creating mobile apps for the Android OS, using Java or Kotlin with the Android SDK to build, test, and deploy on the Google Play Store, Bespoke OSes, and other Android-compatible platforms.
                </p>
              </div>
            </div>

            <div className="max-w-md bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-auto md:mx-2 md:mb-3 lg:mb-3">
              <a href="/amazon-web-services">
                <img className="rounded-t-lg" src={amazonWebServicesUpper} alt="Amazon Web Services Management & Development Services Upper" />
              </a>
              <div className="p-5">
                <center>
                  <a href="/amazon-web-services">
                    <SubHeading text="AWS" />
                  </a>
                  <div className="our_specialties__seperator border-b-2 my-6" />
                </center>
                <p className="w-full mb-3 font-normal text-gray-400 text-xl">
                  AWS development focuses on leveraging Amazon’s cloud services to build scalable and secure applications. Developers use a range of AWS tools and services, such as EC2, S3, and Lambda, to create, deploy, and manage cloud-based applications.
                </p>
              </div>
            </div>

            <div className="max-w-md bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-auto md:mx-2 md:mb-3 lg:mb-3">
              <a href="/microsoft-azure">
                <img className="rounded-t-lg" src={microsoftAzureServicesUpper} alt="Microsoft Azure Development Services Upper" />
              </a>
              <div className="p-5">
                <center>
                  <a href="/microsoft-azure">
                    <SubHeading text="Azure" />
                  </a>
                  <div className="our_specialties__seperator border-b-2 my-6" />
                </center>
                <p className="w-full mb-3 font-normal text-gray-400 text-xl">
                  Azure development entails building and deploying applications using Microsoft's cloud platform. Developers utilize Azure services like Azure App Services, Azure Functions, and Azure Storage to create scalable, reliable, and secure cloud-based applications.
                </p>
              </div>
            </div>

            <div className="max-w-md bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-auto md:mx-2 md:mb-3 lg:mb-3">
              <a href="/ai-services">
                <img className="rounded-t-lg" src={aiServicesUpper} alt="AI Development Services Upper" />
              </a>
              <div className="p-5">
                <center>
                  <a href="/ai-services">
                    <SubHeading text="AI" />
                  </a>
                  <div className="our_specialties__seperator border-b-2 my-6" />
                </center>
                <p className="w-full mb-3 font-normal text-gray-400 text-xl">
                  AI development focuses on building software that mimics human intelligence, using machine learning, natural language processing, and neural networks for tasks like decision-making, speech recognition, and predictive analytics.
                </p>
              </div>
            </div>
          </div>

          <RequestAQuote />
          <br/>
        </div>
      </div>
    </>
  );
}

export default OtherServices;