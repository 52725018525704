import React from 'react';

import cyberSecLock from '../../../../assets/images/why_work_with_us/cybersec_lock.png';  

const SecondCall = () => {
  return ( 
    <>
      <div className="flex-row w-full lg:flex-col lg:w-1/3">
          <div className="flex flex-row">
            <div>
              <center>
                <img
                  src={cyberSecLock}
                  alt="Cypher-Tech Labs - Why Work With Us - Cyber Security, In Pace"
                  className="p-12 py-24" />
              </center>
              <p className="text-lg text-gray-300 leading-relaxed m-14">
                In summary, while <a href="https://spl.solana.com/token" target="_blank" style={{textDecoration: "underline"}}>The Token Program</a> may provide a level of assurance regarding the token creation process,
                obtaining an audit from a reputable third-party auditing firm is generally advisable, especially if your
                token involves significant value or complexity. An audit can help ensure regulatory compliance, increase
                investor confidence, enhance security, and build trust within your community.
              </p>
            </div>
          </div>
        </div>
    </>
   );
}
 
export default SecondCall;