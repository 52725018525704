import React, {useEffect, useRef, useState} from 'react'
import SubHeading from '../../ui/SubHeading';
import web2DevelopmentImageTop from '../../../assets/images/custom_development/web_2_design_and_development__top_image.png';
import web2DevelopmentImageBottom from '../../../assets/images/custom_development/web_2_design_and_development__bottom_image.png';
import RequestAQuote from '../RequestAQuote';

function useIntersectionObserver(options = {}) {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [options]);

  return [ref, isVisible];
}

const Web2Development = () => {

  const [topImageRef, isTopImageVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  
  const [bottomImageRef, isBottomImageVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  return (
    <>
      <div className="web_2.0_design_and_development__card m-6" id="bug_bounty" data-testid="web2_development__component">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="rounded-t-lg flex items-center justify-center">
            <SubHeading
              text="WEB-2 DESIGN AND DEVELOPMENT"
            />
          </div>
        </div>

        <div className="web_2.0_website_design_and_development__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="flex flex-col lg:flex-row lg:mb-24">
            <div className="w-full lg:w-1/2">
              <div className="p-3">
                <SubHeading 
                  text="WEB 2.0 WEBSITE DESIGN AND DEVELOPMENT"
                />
                <p className="text-gray-400 text-xl mb-8" data-testid="web_2.0_website_design_and_development__description">
                  While WEB 2.0 is not our main focus, we are immenently capable of design and development of custom websites
                  as they exist today. With developers with over 25 years of experience, we can help you with your project.<br/><br/>

                  Unlock a new potential for your business with a custom, professionally designed and built website.<br/><br/>

                  Are you looking for custom development for your project, or even a new look or additional functionality?
                  You are at the right place. We have developed many custom websites, and our team is ready to help you look
                  professional.
                </p>

                <SubHeading text="WHY CHOOSE US FOR WEBSITE DEVELOPMENT?" />
                <div className="text-gray-400 text-xl mb-8">
                  <div className="mb-2">
                    <span className="text-gray-300 font-bold mb-1">Custom Design</span><br/>
                    Tailored to align with your brand, our responsive designs
                    ensure captivating user experiences across all devices.
                  </div>
                  
                  <div className="mb-2">
                    <span className="text-gray-300 font-bold mb-1">Secure Backend and Authentication</span><br/>
                      We emphasize security and cross-platform compatibility to provide
                      a seamless and safe user experience.
                  </div>

                  <div className="mb-2">
                    <span className="text-gray-300 font-bold mb-1">Containers, DevOps, and Hosting Expertise</span><br/>
                    With meticulous planning and reliable hosting on AWS, GoDaddy or other platforms, we guarantee optimal
                    performance and reliability.
                  </div>

                  <div className="mb-2">
                    <span className="text-gray-300 font-bold mb-1">Expert Database Management</span><br/>
                    Effortlessly build and manage databases for efficient data control.
                  </div>

                  <div className="mb-2">
                    <span className="text-gray-300 font-bold mb-1">Integrated Payment Solutions</span><br/>
                    Simplify transactions with integrated payment systems such as Stripe and PayPal Checkout SDK.
                  </div>
                </div>
                
                <div className="text-xl mb-8">
                  <SubHeading className="text-gray-300" text="EXTENSIVE EXPERIENCE WITH THE FOLLOWING TECH" />
                  <ul className="link_list text-gray-300 text-xl mb-3">
                    <li><a href="https://nodejs.org/en" target="_blank">NodeJS</a>, <a href="https://webpack.js.org/" target="_blank">Webpack</a>, <a href="https://expressjs.com/" target="_blank">Express.js</a>, HTML, CSS</li>
                    <li><a href="https://react.dev/" target="_blank">React</a> <a href="https://nextjs.dev" target="_blank">NextJS</a> <a href="https://vuejs.org" target="_blank">Vue</a></li>
                    <li><a href="https://redux.js.org/" target="_blank">Redux and State Mangement</a></li>
                    <li><a href="https://angular.dev/" target="_blank">Angular</a></li>
                    <li><a href="https://rubyonrails.org">Ruby on Rails (Rails)</a></li>
                    <li><a href="https://php.net" target="_blank">PHP</a> (<a href="https://wordpress.org">WordPress</a>, OOP, legacy codebases)</li>
                    <li><a href="https://www.python.org" target="_blank">Python</a></li>
                    <li><a href="https://en.wikipedia.org/wiki/SQL">SQL</a>, <a href="https://www.mysql.com/" target="_blank">MySQL</a>, <a href="https://www.postgresql.org/" target="_blank">PostgreSQL</a>, and <a href="https://neon.tech" target="_blank">Neon</a> - Traditional, relational databases</li>
                    <li><a href="https://en.wikipedia.org/wiki/NoSQL">NoSQL</a>, <a href="https://mongodb.com/" target="_blank">MongoDB</a>, <a href="https://cloud.google.com/products/datastore" target="_blank">Google Cloud Datastore</a> Non-relational, single object databases</li>
                    <li><small>* This list should not be considered complete or as a finite list of our programming cababilities.</small></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="inner_web2_development_left__image_container ml-6">
                <div className="hidden lg:flex">
                  <br/><br/><br/>
                </div>
                <div
                  ref={topImageRef}
                  className={`transition-opacity duration-1000 ease-in-out ${
                    isTopImageVisible ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <img
                    src={web2DevelopmentImageTop}
                    alt="Web 2.0 Development Top Image"
                    className="w-full rounded-lg"
                  />
                </div>
                
                <div className="mb-20 lg:mb-32" />
                
                <div
                  ref={bottomImageRef}
                  className={`transition-opacity duration-1000 ease-in-out ${
                    isBottomImageVisible ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <img
                    src={web2DevelopmentImageBottom}
                    alt="Web 2.0 Development Bottom Image"
                    className="w-full rounded-lg"
                  />
                </div>
              </div>
              <RequestAQuote />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Web2Development