import React from 'react';

import Consultation from './how_audits_work/Consultation';
import InitialHowDoAuditsWork from './how_audits_work/InitialHowDoAuditsWork';
import Analysis from './how_audits_work/Analysis';
import Assessment from './how_audits_work/Assessment';
import Reporting from './how_audits_work/Reporting';
import Mitigation from './how_audits_work/Mitigation';

const HowAuditsWork = () => {
  return ( 
    <>
      <div className="container mx-auto px-4 py-8" data-testid="how_audits_work__section">
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-24">
          <InitialHowDoAuditsWork />
          <Consultation />
          <Assessment />
          <Analysis />
          <Reporting />
          <Mitigation />
        </div>
      </div>
    </>
  );
}
 
export default HowAuditsWork;