import React from 'react'

import fireInterestIcon from '../../../assets/images/crypto_environments/ethereum__fire_interest__icon.png';
import ethereumSmartContractIcon from '../../../assets/images/crypto_environments/ethereum__smart_contract__icon.png';

import ethereumAuditIconOne from '../../../assets/images/crypto_environments/ethereum_audit_1.png';
import ethereumAuditIconTwo from '../../../assets/images/crypto_environments/ethereum_audit_2.png';
import ethereumAuditIconThree from '../../../assets/images/crypto_environments/ethereum_audit_3.png';
import ethereumAuditIconFour from '../../../assets/images/crypto_environments/ethereum_audit_4.png';

import SubHeading from '../../ui/SubHeading';
import P from '../../ui/P';

const EthereumEnvironments = () => {
  return (
    <div className="ethereum_environments__card m-6" id="bug_bounty" data-testid="ethereum_environments__card">
      <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
        <div className="rounded-t-lg flex items-center justify-center">
          <SubHeading
            text="ETHEREUM ENVIRONMENTS"
          />
        </div>
      </div>
      <div className="bottom_ethereum_environments__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
        <div className="p-3">
          <div className="ethereum_environments__seperator border-b-2 mb-12" />

          <SubHeading text="ETHEREUM SMART CONTRACT AUDITING" className="text-xl" />
          <P className="text-gray-400 text-xl mb-12">
            Get a professional Ethereum Smart Contract Audit to ensure the security and reliability of your code. Our comprehensive audit will identify potential vulnerabilities, optimize your code, address security concerns, and suggest improvements.
          </P>

          <div className="flex flex-col-reverse lg:flex-row lg:mb-12">
            <div className="w-full lg:w-1/2">
              <SubHeading text="WHY IS IT IMPORTANT TO AUDIT AN ETHEREUM SMART CONTRACT?" className="text-xl" />
              <div className="flex items-center my-6">
                <img
                  src={ethereumAuditIconOne}
                  alt="First Ethereum Audit Table Icon"
                  id="first_ethereum_audit_table_icon__image"
                  className="pt-2 px-1 my-6 hidden lg:flex"
                />
                <P className="text-gray-400 text-xl mb-3 lg:ml-4">
                  By using an external team of experts, you can ensure that your contract has no critical bugs, vulnerabilities or security flaws.
                </P>
              </div>
              <div className="flex items-center my-6">
                <img
                  src={ethereumAuditIconTwo}
                  alt="Second Ethereum Audit Table Icon"
                  id="second_ethereum_audit_table_icon__image"
                  className="pt-2 px-1 my-6 hidden lg:flex"
                />
                <P className="text-gray-400 text-xl mb-3 lg:ml-4">
                  Provides an unbiased third-party review of the code. With a thorough audit, it guarantees that your idea will be taken seriously by investors and partners alike.
                </P>
              </div>
              <div className="flex items-center my-6">
                <img
                  src={ethereumAuditIconThree}
                  alt="Third Ethereum Audit Table Icon"
                  id="third_ethereum_audit_table_icon__image"
                  className="pt-2 px-1 my-6 hidden lg:flex"
                />
                <P className="text-gray-400 text-xl mb-3 lg:ml-4">
                  Keeps your business and users safe from risk. If there are any problems with the functionality or security of your smart contract, it could put you and others at risk!
                </P>
              </div>
              <div className="flex items-center my-6">
                <img
                  src={ethereumAuditIconFour}
                  alt="Fourth Ethereum Audit Table Icon"
                  id="fourth_ethereum_audit_table_icon__image"
                  className="pt-2 px-1 my-6 hidden lg:flex"
                />
                <P className="text-gray-400 text-xl mb-3 lg:ml-4">
                  Having an audit, gives you a detailed report on specific risks that your developers may not have considered.
                </P>
              </div>
            </div>
            <div className="w-full lg:w-1/2 lg:ml-12">
              <table className="w-full" width="100%">
                <tbody>
                  <tr>
                    <td width="100%">
                      <SubHeading text="BRING SAFETY TO YOUR INVESTORS" className="text-xl" />
                    </td>
                  </tr>
                  <tr>
                    <td width="100%">
                      <P className="text-gray-400 text-xl mb-3">
                        Developers may have overlooked security risks, they may not have understood the implications of certain code and they may have made mistakes in the code.
                        You can help by auditing the contracts before they are deployed to the Ethereum Mainnet, Ropsten Ethereum Testnet, Kovan Ethereum Testnet,
                        Ethereum Testnet or Goerli Ethereum Testnet.
                      </P>
                      <P className="text-gray-400 text-xl mb-3">
                        The main aim of smart contract audit is to ensure that your business does not face any risks associated with a faulty code. This can help you save time
                        and money that is otherwise spent on fixing bugs and security flaws. It also helps you to build trust with your investors and partners.
                      </P>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <SubHeading text="SMART CONTRACTS AND THE ETHEREUM BLOCKCHAIN" className="text-xl" />
          <P className="text-gray-400 text-xl mb-3">
            Smart contract is an emerging technology that is growing in popularity. A smart contract is a self-executing digital contract which
            facilitates the exchange of money, property or anything of value.
          </P>
          <P className="text-gray-400 text-xl mb-3">
            Smart contracts have been implemented on various blockchain platforms such as Ethereum blockchain. More and more companies are beginning to turn to blockchain to improve their existing processes and implement brand new ones. Smart contract audit is important for Ethereum blockchain based businesses.
          </P>
          <P className="text-gray-400 text-xl mb-3">
            Ethereum is an open-source, public, blockchain-based distributed computing platform and operating system featuring smart contract (scripting) functionality. It supports a modified version of Nakamoto consensus via transaction-based state transitions. Ethereum was proposed in late 2013 by Vitalik Buterin, a cryptocurrency researcher and programmer.
          </P>
          <p className="text-gray-400 text-xl mb-12">
            Development was funded by an online crowd sale that took place between July and August 2014. The system went live on 30 July 2015,
            with 11.9 million coins "pre-mined". This accounts for about 13 percent of the total circulating supply in 2019.
          </p>

          <SubHeading text="AN EMERGING TECHNOLOGY THAT IS GROWING IN POPULARITY" className="text-xl" />
          
          <div className="flex items-start">
            <img
              src={fireInterestIcon}
              alt="Ethereum Is A Rapidly Growing Sector Image"
              id="ethereum_environments_rapidly_growing_sector__image"
              className="pt-6 px-3 hidden lg:flex"
            />
            <P className="text-gray-400 text-xl mb-12">
              You may have heard of smart contracts or the Ethereum blockchain, but there's a lot to learn. Smart contract auditing is an important 
              part of any business that is involved in the web3 and blockchain domain.<br/><br/>

              When you opt for a smart contract audit, you'll get the knowledge that will help you understand why an audit is so valuable and how
              it can benefit your business.<br/><br/>

              Smart contracts are computer programs that facilitate, verify, or enforce the negotiation or performance of a contract between 
              two parties without requiring human intervention. A smart contract allows its participants to exchange money 
              (or anything else of value) in a transparent way without relying on third parties like banks or other financial institutions.

            </P>
          </div>
          
          <div className="flex items-start">
            <img
              src={ethereumSmartContractIcon}
              alt="More Businesses Are Flocking To The Blockchain Image"
              id="ethereum__more_and_more_flocking_to_the_blockchain__image"
              className="pt-6 px-3 hidden lg:flex"
            />
            <P className="text-gray-400 text-xl mb-12">
              Blockchain is widely known as the technology behind cryptocurrency, but it is also a distributed ledger technology that is used
              for many different types of applications. Blockchain provides a way to create a shared database (for example, you can use
              blockchain to record transactions or store data) that multiple parties can trust and maintain in sync.<br/><br/>

              A blockchain database isn't stored in one place; rather, it's maintained by all participants in the network. Once data has been
              entered into the chain, it cannot be altered retroactively without altering all subsequent blocks—which would require enormous
              computing power.
            </P>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EthereumEnvironments