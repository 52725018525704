import React from 'react'

import Header from '../global/Header'
import Footer from '../global/Footer'
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png'
import RequestAQuote from './RequestAQuote';
import SubHeading from '../ui/SubHeading';

import aiServicesPageHeading from '../../assets/images/ai__page_heading.png';
import P from '../ui/P';

function AIServices() {
  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="ai_services_body" data-testid="ai_services__page">
          <div className="ai_services__section" style={headerBackgroundImageStyle}>
            <Header/>
            <div className="ai_services__card mb-6 mx-6" id="ai_services" data-testid="ai_services__component">
              <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
                <div className="rounded-t-lg flex items-center justify-center">
                  <SubHeading
                    text="AI SERVICES"
                  />
                </div>
              </div>
              <div className="bottom_other_services__card bg-[#0C1320] text-white rounded-b-lg shadow-xl w-full pb-6">
                <img
                  src={aiServicesPageHeading}
                  className="w-full border-b-2 border-gray-600 xs:border-b-1 sm:border-b-1"
                />
                <div className="p-6">
                  <P className="text-2xl">
                    The newest and most exciting technology in the world of computing is finally here, Artificial Intelligence. We can help you build psuedo-sentient AI applications that will automate many of your internal business processes, improve customer service, and increase your revenue. Our team of AI experts can help you with everything from setting up your AI account to managing your AI applications.
                  </P>
                  <P className="text-2xl">
                    One of the greatest benefits of AI is that it can help you build applications that can learn and adapt to new situations. This means that your AI applications can improve over time, becoming more efficient and effective as they learn from their mistakes. Our team of AI experts can help you build AI applications that can learn and adapt to new situations, improving over time.
                  </P>
                  <P className="text-2xl">
                    When we help our clients build AI applications, we focus on building applications that are secure, scalable, and reliable. We can help you build AI applications that can handle millions of users without breaking a sweat. Our team of experts can help you with everything, we can even audit your business and locate the tasks and processes which, if replicated by AI will decrease your bottom line overtime, increase your productivity, and provide your customers with the assurances that put your business on the map.
                  </P>
                  <P className="text-2xl">
                    Ask us how AI can bring your business into the future and save you both time and money!
                  </P>
                </div>
                <RequestAQuote />
              </div>
            </div>
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AIServices