import React from 'react'

import Header from '../global/Header'
import Footer from '../global/Footer'
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png'
import RequestAQuote from './RequestAQuote';
import SubHeading from '../ui/SubHeading';

import androidAppDevelopmentPageHeading from '../../assets/images/android__page_heading.png';
import P from '../ui/P';

function AndroidAppDevelopment() {
  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="android_app_development_body" data-testid="android_app_development__page">
          <div className="android_app_development__section" style={headerBackgroundImageStyle}>
            <Header/>
            <div className="android_app_development__card mb-6 mx-6" id="android_app_development" data-testid="android_app_development__component">
              <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
                <div className="rounded-t-lg flex items-center justify-center">
                  <SubHeading
                    text="ANDROID APP DEVELOPMENT"
                  />
                </div>
              </div>
              <div className="bottom_other_services__card bg-[#0C1320] text-white rounded-b-lg shadow-xl w-full pb-6">
                <img
                  src={androidAppDevelopmentPageHeading}
                  className="w-full border-b-2 border-gray-600 xs:border-b-1 sm:border-b-1"
                />
                <div className="p-6">
                  <P className="text-2xl">
                    Android is the most popular mobile operating system in the world, and for good reason. It's open-source, customizable, and easy to use. Our team of Android experts can help you build Android applications that are secure, scalable, and reliable. We can help you build Android applications that can handle millions of users without breaking a sweat. Our team of experts can help you with everything from setting up your Android account to managing your Android applications.
                  </P>
                  <P className="text-2xl">
                    We are proud to support the Google Play Store, the largest app store in the world. We can help you build Android applications that can be downloaded by millions of users worldwide. Our team of Android experts can help you build Android applications that are secure, reliable, and scalable. We can help you build Android applications that can handle millions of users without breaking a sweat.
                  </P>
                  <P className="text-2xl">
                    From gaming to business tools and everything in between, we can help you build Android applications that will take your business to the next level. Discover how Android Application Development can propel your business into the future today!
                  </P>
                  <P className="text-2xl">
                    Begin your journey with our Android experts now!
                  </P>
                </div>
                <RequestAQuote />
              </div>
            </div>
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AndroidAppDevelopment