import React from 'react'

import Header from '../global/Header'
import Footer from '../global/Footer'
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png'
import RequestAQuote from './RequestAQuote';
import SubHeading from '../ui/SubHeading';

import amazonWebServicesPageHeading from '../../assets/images/amazon_web_services__page_heading.png';
import P from '../ui/P';

function AmazonWebServices() {
  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="amazon_web_services_body" data-testid="amazon_web_services__page">
          <div className="amazon_web_services__section" style={headerBackgroundImageStyle}>
            <Header/>
            <div className="other_services__card mb-6 mx-6" id="bug_bounty" data-testid="other_services__component">
              <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
                <div className="rounded-t-lg flex items-center justify-center">
                  <SubHeading
                    text="AMAZON WEB SERVICES"
                  />
                </div>
              </div>
              <div className="bottom_other_services__card bg-[#0C1320] text-white rounded-b-lg shadow-xl w-full pb-6">
                <img
                  src={amazonWebServicesPageHeading}
                  className="w-full border-b-2 border-gray-600 xs:border-b-1 sm:border-b-1"
                />
                <div className="p-6">
                  <P className="text-2xl">
                    Amazon Web Services offer some of the most robust server solutions in the world. We can help you leverage the power of AWS to build scalable, secure, and reliable applications. Our team of AWS experts can help you with everything from setting up your AWS account to managing your servers and applications.
                  </P>
                  <P className="text-2xl">
                    Utilizing the security features which AWS provides, we can help you build secure applications that are protected from cyber threats. We can also help you build scalable applications that can handle millions of users without breaking a sweat. Our team of AWS experts can help you with everything from setting up your AWS account to managing your servers and applications.
                  </P>
                  <P className="text-2xl">
                    Fulfilling your dreams of building a successful online business is our mission. We can help you build a secure, scalable, and reliable application that can handle millions of users without breaking a sweat. Our team of AWS experts can help you with everything from setting up your AWS account to managing your servers and applications.
                  </P>
                  <P className="text-2xl">
                    We can help you build a secure, scalable, and reliable application that can handle millions of users without breaking a sweat. Our team of AWS experts can help you with everything from setting up your AWS account to managing your servers and applications.
                  </P>

                  <P className="text-2xl">
                    Ask us about getting started with your AWS project today!
                  </P>
                </div>
                <RequestAQuote />
              </div>
            </div>
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AmazonWebServices