import React from 'react'
import Button from '../../../ui/Button';
import SubHeading from '../../../ui/SubHeading';
import SmallSubHeading from '../../../ui/SmallSubHeading';

const BugBountyPrograms = () => {
  return (
    <div className="penetration_testing__card m-6" id="bug_bounty" data-testid="penetration_testing__card">
      <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
        <div className="rounded-t-lg flex items-center justify-center">
          <SubHeading
            text="BUG-BOUNTY PROGRAMS"
          />
        </div>
      </div>
      <div className="bottom_bug_bounty_programs__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
        <div className="p-3">
          <div className="bug_bounty_programs__seperator border-b-2 mb-12" />

          <SubHeading text="WHAT ARE BUG BOUNTY PROGRAMS?" />
          <p className="text-gray-400 text-2xl mb-12">
          In an era defined by relentless digital advancement, safeguarding your online assets is paramount. Our service bridges the gap between traditional security
          measures and the agile landscape of modern cybersecurity. Stay ahead of potential threats with timely insights from ethical hackers, addressing security
          flaws before they become opportunities for cybercriminals, by taking a proactive approach with a bug-bounty testing program.<br/><br/>

          Our bug bounty programs are open invitations to security experts, developers, and enthusiasts to actively participate in improving the security of blockchain
          projects. By incentivizing the discovery of vulnerabilities, we create a safer environment for projects and investors.
          This is a collaborative effort where you can evaluate, discover, report, earn, and repeat...
          </p>

          <SubHeading text="HOW DO OUR BUG BOUNTY PROGRAMS WORK?" />
          <p className="text-gray-400 text-2xl mb-12">
            <span className="text-gray-300 font-bold">1. Community Engagement</span><br/>
            We welcome ethical hackers from around the world to examine projects and identify potential vulnerabilities.
            <br/><br/>
            <span className="text-gray-300 font-bold">2. Reporting vulnerabilities</span><br/>
            Participants report discovered vulnerabilities through our secure channels, helping us assess and address potential
            risks.
            <br/><br/>
            <span className="text-gray-300 font-bold">3. Rewards and Recognition</span><br/>
            Valid vulnerabilities are rewarded with bounties, acknowledging the valuable contribution of the security community.
            <br/><br/>
            <span className="text-gray-300 font-bold">4. Transparent Process</span><br/>
            Clearly Defined Guidelines and Criteria Our bug bounty program operates on a foundation of transparency. Researchers
            and clients benefit from well-defined guidelines and criteria, ensuring a streamlined and fair process. Clarity is
            our commitment, from submission to reward.
            <br/><br/>
            <span className="text-gray-300 font-bold">5. Continuous Improvement</span><br/>
            In the dynamic realm of cybersecurity, stagnation is not an option. Our bug bounty service facilitates continuous
            improvement by adopting an environment of vigilance, innovation, and ongoing enhancements to your digital security.
            <br/><br/>
            <span className="text-gray-300 font-bold">6. Customizable Bounties</span><br/>
            Tailor Rewards to Vulnerability Severity. Our flexible approach extends to rewards. We understand that
            vulnerabilities vary in severity. That's why we offer custom bounties, ensuring that the efforts are appropriately
            recognized based on the impact of the findings.
            <br/><br/>
            <span className="text-gray-300 font-bold">7. Dedicated Support</span><br/>
            Our Team is Here to Assist You with navigating the bug bounty landscape can be complex. Our dedicated support
            team is here to assist you every step of the way. Whether you have questions about submission, guidelines, or
            rewards, we're just a message away, committed to your success.
            <br/><br/>
          </p>

          <SubHeading text="WHAT ARE THE BENEFITS TO PROJECT OWNERS?" />
          <p className="text-gray-400 text-2xl mb-12">
            By embracing our bug bounty program, project owners can:<br/>
            <span className="text-gray-300 font-bold">Harness Global Talent</span>&nbsp;&nbsp;Leverage the skills of ethical hackers to uncover vulnerabilities that might otherwise be overlooked.<br/>
            <span className="text-gray-300 font-bold">Proactive Defense</span>&nbsp;&nbsp;Address vulnerabilities before malicious actors can exploit them, strengthening your project's security.<br/>
            <span className="text-gray-300 font-bold">Build Community Trust</span>&nbsp;&nbsp;Demonstrate your commitment to security and transparency by engaging the broader security community.<br/><br/>

            By embracing these key benefits, you embark on a journey toward fortified cybersecurity and enhanced digital resilience. Our bug bounty service is designed to empower you with the tools, expertise, and collaborative energy needed to safeguard your digital assets effectively.<br/><br/>

            The financial repercussions of data breaches can be staggering. Our bug bounty program offers a cost-effective defense mechanism by enabling you to address vulnerabilities early, reducing potential financial and reputation damages.
          </p>

          <SubHeading text="WHAT ARE THE BENEFITS FOR SECURITY ENTHUSIASTS?" />
          <div className="text-gray-400 text-2xl mb-12">
            Security enthusiasts and ethical hackers benefit from our bug bounty program by:<br/><br/>
            <span className="text-gray-300 font-bold">Monetary Rewards</span>&nbsp;&nbsp;Earn bounties for responsibly disclosing vulnerabilities, and contributing to the security ecosystem.<br/>
            <span className="text-gray-300 font-bold">Skill Enhancement</span>&nbsp;&nbsp;Enhance your skills by actively testing and identifying security weaknesses in real-world scenarios.<br/>
            <span className="text-gray-300 font-bold">Community Impact</span>&nbsp;&nbsp;Make a positive impact by ensuring the security of projects that drive innovation in the crypto space.<br/><br/>
            
            <div className="text-gray-300 text-2xl font-bold">
                At Cypher-Tech Labs, we recognize the power of collaboration in fortifying security. Our bug bounty program embodies this spirit, uniting experts and enthusiasts in a common goal
            </div>
            <div className="text-gray-300 text-2xl font-bold">
              A more secure crypto landscape. Choose to be part of the solution, by collaborating with us and where your skills will make a tangible difference.
            </div>
          </div>

          <SubHeading text="FREQUENTLY ASKED QUESTIONS ABOUT BUG BOUNTY PROGRAMS" />
          <p className="text-gray-400 text-2xl mb-12">
            <span className="text-gray-300 font-bold">1. Who can participate in the bug bounty program? </span>Our bug bounty program welcomes ethical hackers, security researchers, and individuals passionate about enhancing cybersecurity. If you have a knack for identifying vulnerabilities, we encourage you to join our community!<br/><br/>
            <span className="text-gray-300 font-bold">2. How are bounties calculated? </span>Bounties are calculated based on the severity of the reported vulnerability. Our team evaluates factors such as impact, exploitability, and potential consequences. Depending on the severity, you can get a reward that reflects the identified vulnerability criticality.<br/><br/>
            <span className="text-gray-300 font-bold">3. How do you ensure the security of the reporting process? </span>Submissions are made through private channels, protecting the integrity of your data. Rest assured that our platform is designed to safeguard both researchers and clients.<br/><br/>
            <span className="text-gray-300 font-bold">4. How quickly are rewards typically processed? </span>Upon validating a reported vulnerability, we aim to process rewards promptly. The exact timeframe may vary depending on the complexity of the vulnerability and the verification process.<br/><br/>
            <span className="text-gray-300 font-bold">5. What kind of monetary reward is it? </span>We pay out the rewards in Crypto. You will have a choice of BTC, ETH, or any other Coin or Token in the current top 10 by total market cap.<br/><br/>
          </p>

          <div className="w-full">
            <center>
              <SmallSubHeading text="CONTACT US FOR INFO ON BUG BOUNTIES" />
            </center>
          </div>
          <div className="flex flex-col lg:flex-row w-full mb-6">
            <div className="w-full">
              <Button
                url="/contact-us"
                text="Bug Bounty Information Request Form"
                dataTestId="bug_bounty_request__form"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BugBountyPrograms