import React from 'react';

import smartContractAuditFirstReview from '../../../assets/images/smart_contracts_development/smart_contracts_audit__first_review.png';
import smartContractAuditFormalAudit from '../../../assets/images/smart_contracts_development/smart_contracts_audit__formal_audit.png';
import smartContractAuditBugFixes from '../../../assets/images/smart_contracts_development/smart_contracts_audit__bug_fixes.png';
import smartContractAuditReAudit from '../../../assets/images/smart_contracts_development/smart_contracts_audit__re_audit.png';
import smartContractAuditFinalReport from '../../../assets/images/smart_contracts_development/smart_contracts_audit__final_report.png';

import nftAuditService from '../../../assets/images/smart_contracts_development/nft_audit_service.png';
import importanceOfAuditingNFTs from '../../../assets/images/smart_contracts_development/importance_of_auditing_nfts.png';
import howAuditingNFTsWorks from '../../../assets/images/smart_contracts_development/how_nfts_work.png';
import howSecureAreNfts from '../../../assets/images/smart_contracts_development/nft_security.png';
import solutionToNFTAttacks from '../../../assets/images/smart_contracts_development/nft_attacks_1.png';

import SubHeading from '../../ui/SubHeading';
import Button from '../../ui/Button';
import P from '../../ui/P';

const CustomSmartContractDevelopment = () => {
  return ( 
    <>
      <div className="custom_smart_contract_development__card m-6" data-testid="custom_smart_contract_development__card">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="rounded-t-lg flex items-center justify-center">
            <SubHeading
              text="CUSTOM SMART CONTRACT DEVELOPMENT"
              className="text-white text-xl md:text-2xl lg:text-3xl px-3"
            />
          </div>
        </div>
        <div className="bottom_custom_smart_contract_development__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="p-3">
            <div className="custom_smart_contracts_development__seperator border-b-2 mb-12" />
            
            <SubHeading text="SMART CONTRACT DEVELOPMENT" />
            <P className="text-gray-400 text-2xl mb-12" testId="smart_contract_development__description">
              Cypher-Tech Labs is providing Secure and Innovative Blockchain Solutions.<br/>
              Our smart contract development service is designed to create secure, efficient, and reliable solutions for the evolving crypto landscape.<br/>
              Read on to learn more about our custom smart contract development services and what you need to know.
            </P>

            <SubHeading text="WHAT IS CYPHER-TECH LABS SMART CONTRACT DEVELOPMENT" />
            <P className="text-gray-400 text-2xl mb-12" testId="what_is_cyphertech_labs_smart_contract_development__description">
              Our smart contract development service is a comprehensive solution for building and deploying smart contracts on various blockchain platforms.
              Smart contracts are self-executing agreements with the potential to revolutionize industries by automating processes and enhancing transparency.
            </P>

            <SubHeading text="HOW OUR SMART CONTRACT DEVELOPMENT WORKS" />
            <ul className="text-gray-400 text-2xl mb-12" data-testid="how_our_smart_contract_development_works__description">
              <li className="mb-3">
                <strong className="text-gray-200">Requirements Analysis</strong><br/>
                We collaborate with you to understand your project's objectives, ensuring that the smart contract aligns with your vision.
              </li>
              <li className="mb-3">
                <strong className="text-gray-200">Code Creation</strong><br/>
                Our experienced developers write clean, efficient, and secure smart contract code that meets industry standards.
              </li>
              <li className="mb-3">
                <strong className="text-gray-200">Security Audits</strong><br/>
                Every smart contract undergoes rigorous security audits to identify vulnerabilities and ensure the highest level of security.
              </li>
            </ul>

            <SubHeading text="BENEFITS FOR PROJECT OWNERS" />
            <P className="text-gray-400 text-2xl mb-3" testId="benefits_for_project_owners__description">
              By choosing our smart contract development service, project owners can
            </P>
            <ul className="text-gray-400 text-2xl mb-12">
              <li className="mb-3">
                <strong className="text-gray-200">Innovate with Confidence</strong><br/>
                Develop complex decentralized applications and solutions that leverage the power of blockchain technology.
              </li>
              <li className="mb-3">
                <strong className="text-gray-200">Expert Code Development</strong><br/>
                Benefit from our expertise in creating secure smart contracts, minimizing the risk of vulnerabilities and breaches.
              </li>
              <li className="mb-3">
                <strong className="text-gray-200">Project Assurance with Security Audits</strong><br/>
                Tailor-made smart contracts that precisely fit your project's unique requirements.
              </li>
            </ul>
            <P className="text-gray-400 text-2xl mb-12">
              At Cypher-Tech Labs, we're passionate about driving blockchain innovation through secure and efficient smart contract development. Choose us as your partner to transform
              your ideas into reality while maintaining the highest standards of security and reliability.
            </P>

            <div className="w-full mb-2">
              <center>
                <SubHeading text="LET'S GET STARTED" />
              </center>
            </div>
            <div className="flex flex-col w-full">
              <div className="w-full mb-4 lg:mb-0">
                <center>
                  <Button
                    url="https://docs.google.com/forms/d/1Xr_0RMUBzc_9I8Rn5gN11mJpV0fk7j2ffH6X9ev4hqk/viewform"
                    text="Smart Contract Development Request Form"
                    dataTestId="smart_contract_request__form_button"
                    className="text-2xl"
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="custom_smart_contract_development__card m-6" data-testid="custom_smart_contract_development__card">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="rounded-t-lg flex items-center justify-center">
            <SubHeading
              text="SMART CONTRACT AUDITS"
              className="text-white text-xl md:text-2xl lg:text-3xl px-3"
            />
          </div>
        </div>
        <div className="bottom_custom_smart_contract_audit_process__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="p-3">
            <div className="custom_smart_contracts_audit_process__seperator border-b-2 mb-12" />

            <SubHeading text="SMART CONTRACT AUDIT PROCESS" />
            <p className="text-gray-400 text-2xl mb-12">
            Prior to understanding the architecture, the auditors must first comprehend the business logic. The auditor will next go over each line
            of code manually, analyze each smart contract using static analysis, put each contract through unit testing, and look at edge-case business
            logic scenarios.<br/><br/>
            The performance will be examined by our auditors, who will also search for any corner cases. They will conduct an all-encompassing assessment
            of the project's architecture, taking into account security flaws and standard best practices for the creation and implementation of code.
            </p>

            <center>
              <hr width="85%" size="2" color="#2D3748" className="mb-12" />
            </center>
            
            <table className="w-100">
              <tbody>
                
                <tr>
                  <td width="8%" valign="top">
                    <center>
                      <SubHeading text="FIRST REVIEW" className="mb-3 pt-3"/>
                      <img
                        src={smartContractAuditFirstReview}
                        alt="Smart Contract Audit First Review"
                        id="smart_contract_audit__first_review"
                        className="py-6"
                      />
                    </center>
                  </td>
                  <td width="92%" valign="middle">
                    <p className="text-gray-400 text-2xl mb-12 pl-4">
                      The team does an initial examination of the smart contract code to find any potential problems or vulnerabilities before starting
                      the full audit process. The smart contract audit process commences with a comprehensive understanding of the business logic,
                      followed by a thorough examination of the architecture. We take pride in our tailored approach, customizing our audit processes
                      to meet the unique needs of each client.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br/>
                  </td>
                </tr>

                <tr>
                  <td width="8%" valign="top">
                    <center>
                      <SubHeading text="FORMAL AUDIT" className="mb-3 pt-3"/>
                      <img
                        src={smartContractAuditFormalAudit}
                        alt="Smart Contract Audit Formal Audit"
                        id="smart_contract_audit__formal_audit"
                        className="py-6"
                      />
                    </center>
                  </td>
                  <td width="92%" valign="middle">
                    <p className="text-gray-400 text-2xl mb-12 pl-4">
                      A crucial step in the audit process is the manual review, during which auditors meticulously examine the code and its intended
                      implementation. They carefully scrutinize the code, identifying and documenting potential issues or discrepancies between the code
                      and the specified business logic requirements.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br/>
                  </td>
                </tr>

                <tr>
                  <td width="8%" valign="top">
                    <center>
                      <SubHeading text="BUG FIXES" className="mb-3 pt-3"/>
                      <img
                        src={smartContractAuditBugFixes}
                        alt="Smart Contract Audit Bug Fixes"
                        id="smart_contract_audit__bug_fixes"
                        className="py-6"
                      />
                    </center>
                  </td>
                  <td width="92%" valign="middle">
                    <p className="text-gray-400 text-2xl mb-12 pl-4">
                      We will strive to resolve any problems or vulnerabilities found in the smart contract code in accordance with the audit's findings.
                      Due to the extremely iterative nature of the audit process, we won't release a report or a fix suggestion unless there is a mutual
                      agreement with the client. The auditors maintain alignment with the latest hacking techniques and patterns.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br/>
                  </td>
                </tr>

                <tr>
                  <td width="8%" valign="top">
                    <center>
                      <SubHeading text="RE-AUDIT" className="mb-3 pt-3"/>
                      <img
                        src={smartContractAuditReAudit}
                        alt="Smart Contract Audit Re-Audit"
                        id="smart_contract_audit__re_audit"
                        className="py-6"
                      />
                    </center>
                  </td>
                  <td width="92%" valign="middle">
                    <p className="text-gray-400 text-2xl lg:pt-6 mb-12 pl-4">
                      A smart contract's re-audit refers to a thorough security examination that is carried out on the contract after any alterations or updates
                      have been performed. Our team will undertake a Re-Audit, a thorough security evaluation, to find any security flaws or vulnerabilities that
                      could have been created during the upgrade procedure. The contract's functionality will be examined, its source code will be looked at,
                      and it will be tested for any vulnerabilities. A smart contract audit and re-audit can definitely ensure an environment that is safe and
                      reliable and that it is able to offer the required degree of protection for your investments.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br/>
                  </td>
                </tr>

                <tr>
                  <td width="8%" valign="top">
                    <center>
                      <SubHeading text="FINAL REPORT" className="mb-3 pt-3"/>
                      <img
                        src={smartContractAuditFinalReport}
                        alt="Smart Contract Audit Final Report"
                        id="smart_contract_audit__final_report"
                        className="py-6"
                      />
                    </center>
                  </td>
                  <td width="92%" valign="middle">
                    <p className="text-gray-400 text-2xl mb-12 pl-4">
                      Cypher-Tech Labs will issue a report in its entirety, outlining its conclusions and suggestions for enhancing the security and integrity
                      of the Smart Contract code. To verify the security and dependability of your contract, a smart contract audit is something we strongly suggest.
                      Our team is aware of how crucial it is to keep your clients' confidence and safeguard their funds. We may find any vulnerabilities or flaws
                      in the code and offer workable ideas to fix them by doing a comprehensive audit. Don't leave your smart contract's security up to chance,
                      invest in an audit today and take pleasure in the peace of mind that comes from knowing your system is protected.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br/>
                  </td>
                </tr>

              </tbody>
            </table>

            <div className="flex flex-col lg:flex-row w-full mb-6">
              <div className="w-full">
                <Button
                  url="https://docs.google.com/forms/d/15cJiPQIiGMDeblD6koKPQLHZa_xNF35SlPuWB-eQctA/viewform"
                  text="Smart Contract Audit Request Form Link"
                  dataTestId="smart_contract_request_form__button"
                />
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="nft_audits__card m-6" data-testid="nft_audits__card">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="rounded-t-lg flex items-center justify-center">
            <SubHeading
              text="NFT AUDITS"
              className="text-white text-xl md:text-2xl lg:text-3xl px-3"
            />
          </div>
        </div>
        <div className="bottom_custom_smart_contract_audit_process__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="p-3">
            <div className="custom_smart_contracts_audit_process__seperator border-b-2 mb-12" />
            
            <SubHeading text="WHAT ARE NFT'S OR NON-FUNGIBLE TOKENS?" />
            <p className="text-gray-400 text-2xl mb-12">
              Non-fungible tokens (NFTs) are unique digital assets that another token of the same type can't replace. They are created on blockchain
              platforms like Ethereum and EOS, and they can represent a wide range of concepts, from collectibles to securities.<br/>
              NFTs were initially developed for use in games or for authentication purposes however lately, have grown in popularity as developers
              begin exploring how they can be applied to real-world scenarios. The rise of NFTs has been increasing in demand and popularity. This
              has led to a rise in the number of artists, musicians and even sports teams releasing their own NFTs.
            </p>

            <center>
              <hr width="85%" size="2" color="#2D3748" className="mb-12" />
            </center>

            <table className="w-100">
              <tbody>
                <tr>
                  <td width="8%">
                    <center>
                      <img
                        src={nftAuditService}
                        alt="NFT Audit Service"
                        id="nft_audit_service__icon"
                        className="py-4"
                      />
                    </center>
                  </td>
                  <td width="92%">
                    <div className="pl-8">
                      <SubHeading text="WHAT ARE NFT'S OR NON-FUNGIBLE TOKENS?" className="mb-3 pl-8 pt-3" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" width="100%">
                    <p className="text-gray-400 text-2xl pt-4 mb-12">
                      Audit service is the most important step during the development of your NFT smart contract. It will help you avoid all potential
                      vulnerabilities and security concerns, help your team optimize the code and point out possible improvements, as well as make
                      sure that every detail is taken into account. The audited NFTs have a lesser risk quotient of getting hacked and act as a security
                      certificate for your investors.
                    </p>
                  </td>                  
                </tr>
                <tr>
                  <td width="8%">
                    <center>
                      <img
                        src={importanceOfAuditingNFTs}
                        alt="Importance of Auditing Your NFT Project"
                        id="importance_of_auditing_nft_projects__icon"
                        className="py-4"
                      />
                    </center>
                  </td>
                  <td width="92%">
                    <div className="pl-8">
                      <SubHeading text="THE IMPORTANCE OF AUDITING YOUR NFT PROJECT" className="mb-3 pl-8 pt-3" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" width="100%">
                    <p className="text-gray-400 text-2xl pt-4 mb-12">
                      Even though there are numerous ways to exploit an NFT, the biggest NFT security concern is attributable to the underlying smart contract.
                      The list of main smart contract risks includes DoS attacks, reentrancy attacks, and front-running.<br/><br/>

                      New research has revealed that hackers have quadrupled their attacks on NFT projects in the last year. Data shows that in 2022 alone,
                      $86.6 million worth of digital tokens were stolen by hackers. The study also reveals that around 150 targeted attacks were conducted on
                      different NFT projects this year alone, with March being the most productive month for hackers when they managed to make 31 exploits in
                      the NFT sector. In total, hackers have already stolen 330% more from NFT users this year.
                    </p>
                  </td>                  
                </tr>
                <tr>
                  <td width="8%">
                    <center>
                      <img
                        src={howAuditingNFTsWorks}
                        alt="How Auditing NFTs Works"
                        id="how_nft_audits_work__icon"
                        className="py-4"
                      />
                    </center>
                  </td>
                  <td width="92%">
                    <div className="pl-8">
                      <SubHeading text="HOW IT WORKS" className="mb-3 pl-8 pt-3" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" width="100%">
                    <p className="text-gray-400 text-2xl pt-4 mb-12">
                      Our team of experts will perform a full audit of your NFT smart contract, ensuring that your NFT has no critical bugs vulnerabilities or
                      security flaws. Should we find any abnormalities, flaws or risks, we will report them to you and suggest fixes. This will optimize the
                      level of performance of your NFT. Additionally, this will give investors the confidence they need, and reassures them that their money
                      is safe and secure.
                    </p>
                  </td>                  
                </tr>
                <tr>
                  <td width="8%">
                    <center>
                      <img
                        src={howSecureAreNfts}
                        alt="How Secure Are NFTs In General"
                        id="how_secure_are_nfts__icon"
                        className="py-4"
                      />
                    </center>
                  </td>
                  <td width="92%">
                    <div className="pl-8">
                      <SubHeading text="HOW SECURE ARE NFTS IN GENERAL" className="mb-3 pl-8 pt-3" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" width="100%">
                    <p className="text-gray-400 text-2xl pt-4 mb-12">
                      Virtual assets, such as NFTs, are subject to theft. In addition to the traditional methods for stealing digital goods, such as phishing and malware,
                      threats can also arise from vulnerabilities in the smart contract code or from social engineering techniques.<br/><br/>

                      As smart contracts are continuing to fuel DeFi and NFT innovation to greater heights, it is critical to remember that each attack erodes trust
                      in a system that could potentially play a major role in the future of global finance. Therefore, knowing how to perform a smart contract audits for
                      your business or project is a key step in ensuring that your code is not only secure, but can also run at optimum efficiency and deliver better value
                      and a superior experience for the people who interact with it.
                    </p>
                  </td>                  
                </tr>
                <tr>
                  <td width="8%">
                    <center>
                      <img
                        src={solutionToNFTAttacks}
                        alt="What is the Solution to NFT Attacks"
                        id="solution_to_nft_attacks__icon"
                        className="py-4"
                      />
                    </center>
                  </td>
                  <td width="92%">
                    <div className="pl-8">
                      <SubHeading text="WHAT IS THE SOLUTION TO NFT ATTACKS" className="mb-3 pl-8 pt-3" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" width="100%">
                    <p className="text-gray-400 text-2xl pt-4 mb-12">
                      NFT smart contracts may seem to be more secure compared to Fungible Token Smart Contracts since their code is simpler and the NFT ecosystem is not
                      so complex compared to DeFi. The hacks that happened were mostly attributable to user mistakes when trying to minimize gas fees or trying to find
                      ways of getting NFTs almost for free.<br/><br/>

                      The projects need to pay greater attention to auditing their smart contracts, to avoid mistakes and prevent theft from happening. The smart contracts
                      themselves were not inherently malicious, but the functionality enabled non-malicious exploitations.<br/><br/>

                      Our experience with blockchain, DeFi, and smart contracts since 2020 has allowed us help a lot of users, businesses and projects. We will continue to
                      work with these cutting-edge technologies to help others realize their full potential while staying secure from possible threats. We offer in-depth 
                      vulnerability assessment reports that help you to effortlessly recognize and rectify the vulnerabilities present in the source code. We use a combination
                      of static analysis and dynamic testing to ensure that your smart contracts are safe, secure, and efficient.<br/><br/>

                      Our team understand that your time is valuable and our streamlined auditing process ensures that we complete your audit within a reasonable time frame,
                      with the minimum possible paperwork.<br/><br/>

                      When making a decision about a provider of smart contract auditing services, NFT projects should prioritize a company's expertise and reputation. We have
                      a highly-qualified team of security experts who have practical experience in conducting audits, as well as a customized approach that is tailored to your
                      specific needs. When it comes to auditing NFT smart contracts, Cypher-Tech Labs is the clear and best choice for you.
                    </p>
                  </td>                  
                </tr>
              </tbody>
            </table>

            <div className="w-full">
              <center>
                <SubHeading text="READY TO ENSURE YOUR PROJECT'S SECURITY?" className="mb-3 pl-8 pt-3" />
              </center>
            </div>
            <div className="flex flex-col lg:flex-row w-full mb-6">
              <div className="w-full">
                <Button
                  url="https://docs.google.com/forms/d/15cJiPQIiGMDeblD6koKPQLHZa_xNF35SlPuWB-eQctA/viewform"
                  text="REQUEST YOUR AUDIT TODAY"
                  dataTestId="nft_audit_request_form__button"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
   );
}
 
export default CustomSmartContractDevelopment;