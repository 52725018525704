import React from 'react';

import SubHeading from '../../ui/SubHeading';
import P from '../../ui/P';

const DogeEnvironments = () => {
  return (
  <>
    <div className="doge_environments__card m-6" data-testid="doge_environments__card">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="rounded-t-lg flex items-center justify-center">
            <SubHeading
              text="DOGE ENVIRONMENTS"
            />
          </div>
        </div>
        <div className="bottom_doge_environments__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="p-3">
            <div className="doge_environments__seperator border-b-2 mb-12" />

            <SubHeading text="DOGE SMART CONTRACT AUDIT" className="text-xl" />
            <P className="text-xl">
              Dogechain is a proof-of-stake (PoS) blockchain that runs on the Polygon network, which is Ethereum's largest sidechain. Dogechain supports apps and games developed for Ethereum. The $DC token is the official token of the project, it has been airdropped to users and they can stake their $DC tokens and earn a passive income stream. Users can bridge their DOGE and receive Wrapped Dogecoin (wDOGE) in return.
            </P>

            <P className="text-xl">
              With Dogecoin's recent price increase, Dogecoin investors could be looking to use their holdings as collateral to secure funding and lending on the Dogechain network. In essence, they can then use their wDOGE to interact with DeFi apps, NFT marketplaces, DApps, and games on the Dogechain network. DOGE can also be used to pay transaction fees on Dogechain.
            </P>

            <P className="text-xl">
              The team behind Dogechain is "largely anonymous," according to crypto data platform CoinGecko. However, Quickswap co-founder Roc Zacharias has touted his involvement in the project in his Twitter bio. Zacharias is yet to respond to a request for comment from The Defiant.
            </P>

            <SubHeading text="WHAT MAKES DOGECHAIN UNIQUE?" className="text-xl" />
            <div className="text-gray-400 text-xl my-3">
              <div className="mb-6">
                <strong className="text-gray-300">1. EVM-compatible: </strong><br/>
                Existing smart contracts for Ethereum can be effortlessly transferred to Dogechain without needing any further adjustments.
              </div>
              <div className="mb-6">
                <strong className="text-gray-300">2. IBFT Proof-of-Stake (PoS) consensus: </strong><br/>
                The network is permissionless, which means that anyone can participate without seeking permission. It is also decentralized, which means consensus is reached among the users of the blockchain instead of being appointed by one central authority.
              </div>
              <div className="mb-6">
                <strong className="text-gray-300">3. Cross-chain compatibility: </strong><br/>
                The Dogechain network can easily use Dogecoin by wrapping it through the Dogechain bridge and sending it back to the Dogecoin network when necessary.
              </div>
              <div className="mb-6">
                <strong className="text-gray-300">4. Decentralized Governance: </strong><br/>
                The community members (token holders) can participate in the governance of the project. The token holders have a say on governance decisions and can propose ideas, assign authority and cast votes on blockchain-related issues & activities.
              </div>
            </div>

            <SubHeading text="IS DOGECHAIN RELATED TO DOGECOIN?" className="text-xl" />
            <ul className="text-gray-400 text-xl mb-12">
              <li>Dogechain is not affiliated with Dogecoin Core or the Dogecoin Foundation, nor does it utilize the Dogecoin blockchain. Dogechain's relation to Dogecoin is more so that it allows users to interact through Dogecoin.</li>
              <li>There are several well-known 'influencers' who appear to be promoting the project, which has led to more confusion.</li>
              <li>Others in the Dogecoin community have issued warnings against Dogechain, noting that there are many unknowns about the project which could pose "many risks and red flags".</li>
            </ul>

            <SubHeading text="SMART CONTRACTS AND DOGECHAIN" className="text-xl" />
            <P className="text-xl">
              Smart contracts are growing in popularity, especially with the introduction of new blockchain platforms. Dogechain, an Polygon-based blockchain platform, has been utilizing the smart contract technology to facilitate asset exchange, trade and commerce between businesses. Smart contract auditing is extremely important for companies wishing to venture by taking positions wihch provide leverage on the blockchain platform.
            </P>
            <P className="text-xl">
              The data from on-chain trackers is staggering. Some $4.6 million in value is now locked on Dogechain-based products since its launch earlier this week. Over 97% of that is locked on the Dogechain-based exchange DogeSwap alone. This is a travesty which can be avoided given the proper procedures are put in place to mitigate disastrous, avoidable outcomes.
            </P>

            <SubHeading text="WHY IS IT IMPORTANT TO AUDIT A DOGECHAIN SMART CONTRACT?" className="text-xl" />
            <ul className="text-gray-400 text-xl mb-12">
              <li className="mb-2">
                <strong className="text-gray-300">Smart Contract Auditing</strong><br/>
                Our security specialists carry out the smart contract audit according to a formal framework, while providing assurance of the target system's security. To do so, they analyze the source code of a particular smart contract and determine whether it complies with its requirements (in terms of functionality, reliability, performance and safety).</li>
              <li className="mb-2">
                <strong className="text-gray-300">Security Oriented</strong><br/>
                The team of smart contract auditors will aim to ensure your business does not face any risks associated with a faulty code. This can help you save time and money that is otherwise spent on fixing bugs and security flaws.
              </li>
              <li className="mb-2">
                <strong className="text-gray-300">Fixing Security Issues before they happen</strong><br/>
                With multiple parties involved in the development of a smart contract, there is always a chance for security risks to be overlooked. As you know, understanding the implications of certain code and making mistakes in the code are common occurrences. Auditing the contracts before they are deployed on the Dogechain mainnet will help prevent these issues from occurring.
              </li>
            </ul>

            <SubHeading text="ADVANTAGES OF AUDITS" className="text-xl" />
            <div className="text-xl">
              <span className="text-gray-400">A contract review by an external source can help ensure that any critical bugs, vulnerabilities and security flaws in the ending product are caught before they damage your business.</span>
            <div className="mb-6" />
              <span className="text-gray-400">Provides an independent code review to ensure your idea is taken seriously by investors and partners alike.</span>
            <div className="mb-6" />
              <span className="text-gray-400">Ensures that both you and your investors are safe, by ensuring there are no problems with the functionality or security of your smart contract.</span>
            <div className="mb-6" />
              <span className="text-gray-400">Analyzes code for potential vulnerabilities and provides actionable insights into possible risks or edge cases.</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default DogeEnvironments;