import React from 'react'
import baseEnvironmentIcon from '../../../../assets/images/base_environment__icon.png';
import SubHeading from '../../../ui/SubHeading';

const BaseEnvironment = () => {
  return ( 
    <>
      <div className="specialty__card flex-col m-6 mb-12 md:ml-12 md:m-2 md:w-5/12 2xl:m-6 2xl:w-1/5" data-testid="specialty__card">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="h-48 rounded-t-lg flex items-center justify-center">
            
            <img
              src={baseEnvironmentIcon}
              alt="Base Environment Top Image"
              id="base_environment__icon"
            />
            
          </div>
        </div>
        <div className="bottom_our_specialties__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="p-3">

            <div className="our_specialties__seperator border-b-2 mb-12" />
            
            <div className="w-full text-center">
              <center>
                <SubHeading
                  text="BASE ENVIRONMENT"
                  className="text-lg sm:text-sm md:text-md text-white mb-12"
                  alt="Base Environment Heading"
                  id="baseEnvironmentText"
                />
              </center>
            </div>

            <p className="text-gray-400 text-lg" data-testid="base_environment__description">
              BASE is a secure, low-cost, and builder-friendly Ethereum Layer 2 (L2) solution, designed
              to usher in the next wave of users into the blockchain ecosystem. Incubated within Coinbase,
              BASE combines the stability, security, and scalability needed to power your decentralized 
              applications (dApps).
            </p>

          </div>
        </div>
      </div>
    </>
  );
}
 
export default BaseEnvironment;