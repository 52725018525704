import React from 'react'
import ethereumEnvironmentIcon from '../../../../assets/images/ethereum_environment__icon.png';
import SubHeading from '../../../ui/SubHeading';

const EthereumEnvironment = () => {
  return ( 
    <>
      <div className="specialty__card flex-col m-6 mb-12 md:ml-12 md:m-2 md:w-5/12 2xl:m-6 2xl:w-1/5" data-testid="specialty__card">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="h-48 rounded-t-lg flex items-center justify-center">
            
            <img
              src={ethereumEnvironmentIcon}
              alt="Specialty Card Top Image"
              id="ethereum_environment__icon"
            />
            
          </div>
        </div>
        <div className="bottom_our_specialties__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="p-3">

            <div className="our_specialties__seperator border-b-2 mb-12" />

            <div className="w-full text-center">
              <center>
                <SubHeading
                  text="ETHEREUM ENVIRONMENT"
                  className="text-lg sm:text-sm md:text-md text-white mb-12"
                  alt="Ethereum Environment Heading"
                  id="ethereumEnvironmentText"
                />
              </center>
            </div>

            <p className="text-gray-400 text-lg" data-testid="ethereum_environment__description">
              Ethereum is an open-source, public, blockchain-based distributed computing platform and operating system featuring smart contract (scripting) functionality. It supports a modified version of Nakamoto consensus via transaction based state transitions.
              Ethereum was proposed in late 2013 by Vitalik Buterin.
            </p>
            
          </div>
        </div>
      </div>
    </>
  );
}
 
export default EthereumEnvironment;