import React from 'react'
import kycPenetrationTestingIcon from '../../../../assets/images/kyc_penetration_testing/kyc_penetration_testing__heading_icon.png'

const HeroRight = () => {
  return ( 
    <>
      <div className="hidden w-full lg:flex lg:w-full">
        <center>
          <img
            src={kycPenetrationTestingIcon}
            alt="KYC Penetration Testing Services Mask Icon"
            id="kyc_penetration_testing_image"
            className="lg:mt-3 lg:ml-32 xl:ml-48"
          />
        </center>
      </div>
      <div className="flex w-full justify-center items-center lg:hidden">
          <img
            src={kycPenetrationTestingIcon}
            alt="KYC Penetration Testing Services Mask Icon"
            id="kyc_penetration_testing_image__mobile"
            className="p-12"
          />
      </div>
    </>
   );
}
 
export default HeroRight;