import React from 'react'
import Header from '../global/Header'
import Footer from '../global/Footer'
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png'
import Web2Development from './custom_development/Web2Development';

function Web2DesignAndDevelopment() {
  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="web_2.0_design_and_development_body" data-testid="web_2.0_design_and_development__page">
          <div className="web_2.0_design_and_development__section" style={headerBackgroundImageStyle}>
            <Header/>
            <Web2Development />
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Web2DesignAndDevelopment