import React from 'react'

import customDevelopmentIcon from '../../../../assets/images/custom_development__icon.png';
import customDevelopmentText from '../../../../assets/images/custom_development__heading_text.png';

import SubHeading from '../../../ui/SubHeading';

const CustomDevelopment = () => {
  return ( 
    <>
      <div className="capability__card flex-col mb-12 md:ml-12 md:m-2 md:w-5/12 2xl:m-6 2xl:w-1/5" data-testid="capability__card">
        <a href="/custom-development">
          <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
            <div className="h-48 rounded-t-lg flex items-center justify-center">
              <img
                src={customDevelopmentIcon}
                alt="Capability Card Top Image"
                id="custom_development__icon"
              />
            </div>
          </div>
          <div className="bottom_our_capabilities__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
            <div className="p-3">
              <div className="our_capabilities__seperator border-b-2 mb-12" />
              <SubHeading
                text="CUSTOM DEVELOPMENT"
                id="custom_development__heading"
                alt="Capability Card Top Image - Custom Development"
                className="text-lg sm:text-sm md:text-md text-white mb-3"
              />
              <p className="text-gray-400 text-lg sm:text-sm md:text-md lg:text-xl"
              data-testid="custom_development__description">
                We recognize that customization is unique. Our service is highly
                customizable, allowing us to adapt to your specific needs. Whether
                you're developing a decentralized finance (DeFi) protocol, a non-fungible
                token (NFT) platform, or any other blockchain-based solution, we tailor
                our approach to match your project's requirements.
                <br/><br/><br/>
              </p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}
 
export default CustomDevelopment;