import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'

import Home from './components/routes/Home';
import SmartContractsAndAudits from './components/routes/SmartContractsAndAudits';
import KYCVerificationPenetrationTestingBugBounty from './components/routes/KYCVerificationPenetrationTestingBugBounty';
import CryptoEnvironments from './components/routes/CryptoEnvironments';
import CustomDevelopment from './components/routes/CustomDevelopment';
import PaymentOptions from './components/routes/PaymentOptions';
import AboutUs from './components/routes/AboutUs';
import ContactUs from './components/routes/ContactUs';
import PrivacyPolicy from './components/routes/PrivacyPolicy';
import FAQs from './components/routes/FAQs';
import Web2DesignAndDevelopment from './components/routes/Web2DesignAndDevelopment';
import Web3DesignAndDevelopment from './components/routes/Web3DesignAndDevelopment';
import IOSAppDevelopment from './components/routes/IOSAppDevelopment';
import IPadOSAppDevelopment from './components/routes/IPadOSAppDevelopment';
import MacOSAppDevelopment from './components/routes/MacOSAppDevelopment';
import AndroidAppDevelopment from './components/routes/AndroidAppDevelopment';
import MicrosoftAzure from './components/routes/MicrosoftAzure';
import AmazonWebServices from './components/routes/AmazonWebServices';
import AIServices from './components/routes/AIServices';

// Article Imports
import HipaaComplianceArticle from './components/articles/HipaaComplianceArticle';

// No Match, 404 Page
import NoMatch from './components/routes/NoMatch';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/smart-contracts-and-audits" element={<SmartContractsAndAudits />} />
        <Route path="/kyc-verification-penetration-testing-bug-bounty" element={<KYCVerificationPenetrationTestingBugBounty />} />
        <Route path="/crypto-environments" element={<CryptoEnvironments />} />
        <Route path="/custom-development" element={<CustomDevelopment />} />
        <Route path="/payment-options" element={<PaymentOptions />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/web-2-design-and-development" element={<Web2DesignAndDevelopment />} />
        <Route path="/web-3-design-and-development" element={<Web3DesignAndDevelopment />} />
        <Route path="/iOS-app-development" element={<IOSAppDevelopment />} />
        <Route path="/iPadOS-app-development" element={<Navigate to="/iOS-app-development" replace />} />
        <Route path="/macOS-app-development" element={<Navigate to="/iOS-app-development" replace />} />
        <Route path="/android-app-development" element={<AndroidAppDevelopment />} />
        <Route path="/microsoft-azure" element={<MicrosoftAzure />} />
        <Route path="/amazon-web-services" element={<AmazonWebServices />} />
        <Route path="/ai-services" element={<AIServices />} />
        <Route path="*" element={<NoMatch/>} />
      </Routes>
    </Router>
  )
}

export default App;