import React from 'react'

import Header from '../global/Header'
import Footer from '../global/Footer'
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png'
import RequestAQuote from './RequestAQuote';
import SubHeading from '../ui/SubHeading';

import microsoftAzurePageHeading from '../../assets/images/microsoft_azure__page_heading.png';
import P from '../ui/P';

function MicrosoftAzure() {
  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="microsoft_azure_body" data-testid="microsoft_azure__page">
          <div className="microsoft_azure__section" style={headerBackgroundImageStyle}>
            <Header/>
            <div className="microsoft_azure__card mb-6 mx-6" id="microsoft_azure" data-testid="microsoft_azure__component">
              <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
                <div className="rounded-t-lg flex items-center justify-center">
                  <SubHeading
                    text="MICROSOFT AZURE"
                  />
                </div>
              </div>
              <div className="bottom_other_services__card bg-[#0C1320] text-white rounded-b-lg shadow-xl w-full pb-6">
                <img
                  src={microsoftAzurePageHeading}
                  className="w-full border-b-2 border-gray-600 xs:border-b-1 sm:border-b-1"
                />
                <div className="p-6">
                  <P className="text-2xl">
                    Microsoft Azure is a cloud computing service created by Microsoft for building, testing, deploying, and managing applications and services through Microsoft-managed data centers. It provides software as a service (SaaS), platform as a service (PaaS) and infrastructure as a service (IaaS) and supports many different programming languages, tools, and frameworks, including both Microsoft-specific and third-party software and systems.
                  </P>
                  <P className="text-2xl">
                    With our leading experts in Microsoft Azure, we can help you build, deploy, and manage applications through our global network of data centers. We can help you build applications that can scale up or down depending on your needs, and we can help you build applications that are secure, reliable, and scalable. Our team of experts can help you with everything from setting up your Microsoft Azure account to managing your applications.
                  </P>
                  <P className="text-2xl">
                    Bringing your business into the future with Microsoft Azure can save you both time and money. Ask us how we can help you build, deploy, and manage applications through Microsoft Azure.
                  </P>
                  <P className="text-2xl">
                    Let's get started with your next project utilizing our Microsoft Azure experts now!
                  </P>
                </div>
                <RequestAQuote />
              </div>
            </div>
            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MicrosoftAzure