import React from 'react';

const Button = ({ url, text, dataTestId, target }) => {

  const targetValue = target ? target : "_self";

  return (
    <>
      <center><div><a href={url} data-testid={dataTestId} target={targetValue}><button className="button_style">{text}</button></a></div></center>
    </>
  );
}

export default Button;