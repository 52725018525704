import React from 'react'
import cryptoEnvironmentsIcons from '../../../../assets/images/crypto_environments/crypto_environments__icons.png'

const HeroRight = () => {
  return ( 
    <>
      <div className="hidden w-full justify-center lg:flex lg:w-full">
        <img
          src={cryptoEnvironmentsIcons}
          alt="Crypto Environments and Blockchains Icons"
          id="crypto_environments_image"
          className="lg:mt-6"
        />
      </div>
      <div className="flex w-full justify-center items-center lg:hidden">
        <img
          src={cryptoEnvironmentsIcons}
          alt="Crypto Environments and Blockchains Icons"
          id="crypto_environments_image__mobile"
          className="p-12"
        />
      </div>
    </>
   );
}
 
export default HeroRight;