import React from 'react'
import Header from '../global/Header'
import Footer from '../global/Footer'
import headerBackgroundImage from '../../assets/images/background_image_mask__small.png';
import aboutUsHeadingText from '../../assets/images/about_us__heading_text.png';

import gdprLogoLarge from '../../assets/images/gdpr_logo__large.png';
import glbaComplianceLogoLarge from '../../assets/images/glba_compliance_logo__large.png';
import isoLogoLarge from '../../assets/images/iso_logo__large.png';
import nistLogoLarge from '../../assets/images/nist_logo__large.png';

import SubHeading from '../ui/SubHeading';
import SmallSubHeading from '../ui/SmallSubHeading';
import Button from '../ui/Button';
import P from '../ui/P';

function AboutUs() {

  const backgroundStyle = {
    backgroundColor: '#131a27',
  };
  
  const headerBackgroundImageStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
  };

  return (
    <>
      <div style={backgroundStyle}>
        <div className="about_us_body" data-testid="about_us__page">
          <div className="about_us_header__section" style={headerBackgroundImageStyle}>

            <Header/>
            
            <div className="about_us__card m-6" data-testid="about_us__card">
              <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
                <div className="rounded-t-lg flex items-center justify-center">

                  <SubHeading 
                    text="ABOUT US"
                    alt="About Us - CypherTech Labs - Technology Company"
                    id="about_us__heading"
                    data-testid="about_us__heading_text"
                    className="px-3"
                  />
                  
                </div>
              </div>
              <div className="bottom_about_us__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
                <div className="p-3">
                    <P className="about_us__general_description text-2xl">
                      CypherTech-Labs is a company that specializes in providing comprehensive security assessments for blockchain-based projects. We have a team of highly experienced security researchers and auditors who are well-versed in smart contract vulnerabilities and best practices.
                    </P>

                    <SmallSubHeading
                      text="Background & Experience"
                      className="text-2xl"
                    />
                    
                    <P className="about_us__background_and_experience__description text-2xl mt-3 mb-6">
                      CypherTech-Labs has been operating in the field of smart contract auditing for several years and has conducted numerous audits for various blockchain projects. We have a deep understanding of the Ethereum ecosystem and are familiar with other blockchain platforms as well.
                    </P>

                    <SmallSubHeading
                      text="Unique Features, Methodologies, & Tools"
                      className="text-2xl"
                    />

                    <P className="about_us__unique_features_methodologies_and_tools__description text-2xl  mt-3 mb-6">
                      CypherTech-Labs employs a combination of manual code reviews and automated analysis tools to thoroughly assess the security of smart contracts. We have developed our own proprietary tools and methodologies to identify potential vulnerabilities and ensure a comprehensive evaluation.
                    </P>

                    <P className="about_us__unique_features_methodologies_and_tools__description_2 text-2xl mt-3 mb-6">
                      One unique feature of CypherTech-Labs is our focus on penetration testing to dynamically test your web3 applications. We also, provide services such as smart contract audits, KYC, NFT audits, custom development and formal verification.
                    </P>

                    <SmallSubHeading
                      text="Audits & Reporting"
                      className="text-2xl"
                    />

                    <P className="about_us__audits_and_reporting__description text-2xl mt-3 mb-6">
                      CypherTech-Labs team is comprised of highly experienced developers in the crypto space. Our audits are comprehensive and can earn a badge for your project.
                    </P>

                    <P className="about_us__audits_and_reporting__description_2 text-2xl mt-3 mb-6">
                      The audit service guarantees to build trust and recognition for your project. It will improve your smart contract security and be the primary building block for all your marketing efforts.
                    </P>

                    <P className="about_us__audits_and_reporting__description_3 text-2xl mt-3 mb-6">
                      CypherTech-Labs has audited numerous contracts, on multiple chains, showcasing our extensive experience and proficiency in scrutinizing projects on the blockchain. With a track record of successful audits, clients can trust our expertise to ensure the security and reliability of their Solana smart contracts.
                    </P>

                    <SmallSubHeading
                      text="What to Expect After Smart Contract Audit?"
                      className="text-2xl"
                    />

                    <SmallSubHeading
                      text="Comprehensive Analysis Reports"
                      className="text-xl my-6"
                    />

                    <P className="about_us__comprehensive_analysis_reports__description text-2xl mt-3 mb-6">
                      Following the audit, stakeholders will receive a comprehensive Solana-specific report detailing the security, functionality, and overall integrity of the smart contract. This report will include an overview of the contract's architecture, a summary of the audit process, and a breakdown of identified issues, along with recommendations for improvements or modifications to enhance its robustness.
                    </P>

                    <SmallSubHeading
                      text="Promoting Project Confidence"
                      className="text-xl my-6"
                    />

                    <P className="about_us__promoting_project_confidence__description text-2xl mt-3 mb-6">
                      After the Audit Report is finalized, we'll create a promotional campaign to share the results on our social media channels and partner websites. This will allow us to inform our community and potential users about the thorough examination of our smart contract's security and functionality. Through clear and concise messaging, we'll emphasize the trustworthiness of our platform and attract new users who value transparency and reliability.
                    </P>

                    <hr className="border-[#09ADE3] border-2 mt-12 mb-12" />

                    <SmallSubHeading
                      text="KYC VERIFICATION"
                      className="text-xl my-6"
                    />

                    <P className="about_us__kyc_verification__description text-2xl mt-3 mb-6">
                      KYC is the process of identity verification of project teams. CypherTech-Labs does extensive checks and verifies the identity of the project team members.
                    </P>

                    <ul className="about_us__kyc_verification__benefit_list text-gray-400 text-2xl mt-0 pt-0">
                      <li><strong className="text-gray-300">✓</strong> Verify your team members</li>
                      <li><strong className="text-gray-300">✓</strong> State-of-the-art validation algorithms</li>
                      <li><strong className="text-gray-300">✓</strong> Data confidentiality</li>
                    </ul>

                    <SmallSubHeading
                      text="LAUNCHPADS"
                      className="text-xl mt-12 mb-6"
                    />

                    <P className="about_us__launchpads__description text-2xl mt-3 mb-6">
                      CypherTech-Labs recommends the following launchpads. They are the most popular launchpads in the crypto space.<br/>
                      We can provide Audit/KYC Badges with our services, valid for all of the following launchpads:
                    </P>

                    <ul className="about_us__launchpads__benefit_list text-gray-400 text-xl mt-0 pt-0">
                      <li>Unicrypt - <a href="https://unicrypt.network" blank="_blank" className="text-[#9b9be9]">unicrypt.network</a></li>
                      <li>Pinksale - <a href="https://www.pinksale.finance/" blank="_blank" className="text-[#9b9be9]">pinksale.finance</a></li>
                      <li>DxSale - <a href="https://dxsale.app/" blank="_blank" className="text-[#9b9be9]">dxsale.app</a></li>
                      <li>Gempad - <a href="https://gempad.app/" blank="_blank" className="text-[#9b9be9]">gempad.app</a></li>
                      <li>Xion Finance - <a href="https://xion.finance/" blank="_blank" className="text-[#9b9be9]">xion.finance</a></li>
                      <li>Dexpanse - <a href="https://dexpanse.io/" blank="_blank" className="text-[#9b9be9]">dexpanse.io</a></li>
                      <li>Matic Launchpad - <a href="https://maticlaunchpad.com/" blank="_blank" className="text-[#9b9be9]">maticlaunchpad.com</a></li>
                      <li>BUSDX - <a href="https://www.busdx.com/" blank="_blank" className="text-[#9b9be9]">busdx.com​</a></li>
                      <li>HedgePay - <a href="https://hedgepay.org/" blank="_blank" className="text-[#9b9be9]">hedgepay.org</a></li>
                    </ul>

                    <SmallSubHeading
                      text="LISTING SITES"
                      className="text-xl mt-12 mb-6"
                    />

                    <P className="about_us__launchpads__description text-2xl mt-3 mb-6">
                      CypherTech-Labs recommends the following listing sites.
                    </P>

                    <ul className="about_us__launchpads text-gray-400 text-xl mt-0 pt-0">
                      <li>Coinscope - <a href="https://t.me/coinscope_co" blank="_blank" className="text-[#9b9be9]">t.me/coinscope_co</a></li>
                      <li>Coinsniper - <a href="https://t.me/coinsnipernet" blank="_blank" className="text-[#9b9be9]">t.me/coinsnipernet</a></li>
                      <li>CNToken - <a href="https://t.me/cntokenofficial" blank="_blank" className="text-[#9b9be9]">t.me/cntokenofficial</a></li>
                      <li>Watcher Guru - <a href="https://t.me/watcherguru" blank="_blank" className="text-[#9b9be9]">t.me/watcherguru</a></li>
                      <li>Coinhunt - <a href="https://t.me/CoinhuntCCOfficial" blank="_blank" className="text-[#9b9be9]">t.me/CoinhuntCCOfficial</a></li>
                      <li>Coinmooner - <a href="https://t.me/CoinMoonerPromo" blank="_blank" className="text-[#9b9be9]">t.me/CoinMoonerPromo</a></li>
                      <li>CoinAlpha - <a href="https://t.me/coinalpha_x100" blank="_blank" className="text-[#9b9be9]">t.me/coinalpha_x100</a></li>
                      <li>GemFinder - <a href="https://t.me/gemfindersupport​" blank="_blank" className="text-[#9b9be9]">t.me/gemfindersupport</a></li>
                      <li>RugFree Coins - <a href="https://t.me/rugfreecoin" blank="_blank" className="text-[#9b9be9]">t.me/rugfreecoin</a></li>
                      <li>Fresh Coins - <a href="https://t.me/FreshCoinsTG" blank="_blank" className="text-[#9b9be9]">t.me/FreshCoinsTG</a></li>
                      <li>CoinVote - <a href="https://t.me/coinvotecc" blank="_blank" className="text-[#9b9be9]">t.me/coinvotecc</a></li>
                      <li>CoinXHigh - <a href="https://t.me/coinXHigh" blank="_blank" className="text-[#9b9be9]">t.me/coinXHigh</a></li>
                      <li>IcoGems - <a href="https://t.me/icogems_team" blank="_blank" className="text-[#9b9be9]">t.me/icogems_team</a></li>
                    </ul>

                    <SmallSubHeading
                      text="LISTING SITES"
                      className="text-xl mt-12 mb-6"
                    />

                    <P className="about_us__listing_sites__description text-2xl mt-3 mb-6">
                      CypherTech-Labs recommends the following listing sites.
                    </P>

                    <ul className="about_us__listing_sites text-gray-400 text-xl mt-0 pt-0">
                      <li>Coinscope - <a href="https://t.me/coinscope_co" blank="_blank" className="text-[#9b9be9]">t.me/coinscope_co</a></li>
                      <li>Coinsniper - <a href="https://t.me/coinsnipernet" blank="_blank" className="text-[#9b9be9]">t.me/coinsnipernet</a></li>
                      <li>CNToken - <a href="https://t.me/cntokenofficial" blank="_blank" className="text-[#9b9be9]">t.me/cntokenofficial</a></li>
                      <li>Watcher Guru - <a href="https://t.me/watcherguru" blank="_blank" className="text-[#9b9be9]">t.me/watcherguru</a></li>
                      <li>Coinhunt - <a href="https://t.me/CoinhuntCCOfficial" blank="_blank" className="text-[#9b9be9]">t.me/CoinhuntCCOfficial</a></li>
                      <li>Coinmooner - <a href="https://t.me/CoinMoonerPromo" blank="_blank" className="text-[#9b9be9]">t.me/CoinMoonerPromo</a></li>
                      <li>CoinAlpha - <a href="https://t.me/coinalpha_x100" blank="_blank" className="text-[#9b9be9]">t.me/coinalpha_x100</a></li>
                      <li>GemFinder - <a href="https://t.me/gemfindersupport​" blank="_blank" className="text-[#9b9be9]">t.me/gemfindersupport</a></li>
                      <li>RugFree Coins - <a href="https://t.me/rugfreecoin" blank="_blank" className="text-[#9b9be9]">t.me/rugfreecoin</a></li>
                      <li>Fresh Coins - <a href="https://t.me/FreshCoinsTG" blank="_blank" className="text-[#9b9be9]">t.me/FreshCoinsTG</a></li>
                      <li>CoinVote - <a href="https://t.me/coinvotecc" blank="_blank" className="text-[#9b9be9]">t.me/coinvotecc</a></li>
                      <li>CoinXHigh - <a href="https://t.me/coinXHigh" blank="_blank" className="text-[#9b9be9]">t.me/coinXHigh</a></li>
                      <li>IcoGems - <a href="https://t.me/icogems_team" blank="_blank" className="text-[#9b9be9]">t.me/icogems_team</a></li>
                    </ul>

                    <hr className="border-[#09ADE3] border-2 mt-12 mb-12" />

                    <SmallSubHeading
                      text="SERVICES WE OFFER"
                      className="text-xl mt-12 mb-6"
                    />

                    <ul className="about_us__services_we_offer__benefit_list text-gray-400 text-xl mt-0 pt-0">
                      <li>Smart Contract Development - Tokens, and NFT's</li>
                      <li>Smart Contract Audits - Coins, Tokens, and NFT's</li>
                      <li>Smart Contract Penetration Testing - Responsible (White Hat) Hacking</li>
                      <li>Bug-Bounty Programs</li>
                      <li>WEB-2 Design and Deployment</li>
                      <li>WEB-3 Design and Deployment</li>
                      <li>iOS App Development</li>
                      <li>iPadOS App Development</li>
                      <li>MacOS App Development</li>
                      <li>Android App Development</li>
                      <li>Microsoft Azure</li>
                      <li>Amazon Web Services</li>
                      <li>Artificial Intelligence Projects</li>
                      <li>Cryptography and Security Services</li>
                    </ul>

                    <P className="about_us__services_we_offer__description text-2xl mt-3 mb-6">
                      Should you have a need for a service or project which is not listed above, just reach out to us, chances are we can help you with your project.
                    </P>

                    <P className="about_us__services_we_offer__description_2 text-2xl mt-3 mb-6">
                      Our team is highly experienced in multiple disciplines, and can handle virtually any project. Most of our team members are located in the United States, however some of our team members are located in Europe.
                    </P>

                    <hr className="border-[#09ADE3] border-2 mt-12 mb-12" />

                    <SmallSubHeading
                      text="Our Company is FULLY compliant with the following National and International Regulations"
                      className="text-xl mt-12 mb-6"
                    />

                    {/* START: ISO Compliance: Desktop Table View */}
                      <table className="hidden md:flex mb-6">
                        <tbody>
                          <tr>
                            <td width="13%" valign="top">
                              <center>
                                <h1 className="text-2xl font-bold text-gray-300 mb-3">ISO COMPLIANCE</h1>
                                <img
                                  src={isoLogoLarge}
                                  alt="ISO Compliance Logo"
                                  data-testid="iso_compliance__logo_large_image"
                                  className="w-full"
                                />
                              </center>
                            </td>
                            <td valign="top">
                              <div className="ml-8">
                                <P className="iso_compliance_description text-2xl mb-6">
                                  <span className="font-bold text-gray-300">ISO 27001 (Information Security and IT)</span> - We have created and maintained an information security management system (ISMS) that will strengthen our defenses against cyber threats. ISO 27001 compliance means that we have clear IT security controls in place to protect operations, hardware, and employees from cyber-attacks.
                                </P>
                                <P className="iso_compliance_description_2 text-2xl mt-3 mb-6">
                                  <span className="font-bold text-gray-300">ISO 22301 (Business Continuity)</span> - provides us an ISO framework to embed a reliable Business Continuity Management System. It's recognized as the international standard for procedures to mitigate potential business disruption. Being ISO 22301 compliant provides reassurance to partners in the supply chain that an organization can cope with disruption.
                                </P>
                                <P className="iso_compliance_description_3 text-2xl mt-3 mb-6">
                                  <span className="font-bold text-gray-300">ISO 37001 (Anti-bribery)</span> - Transparency, trust and good governance are the building blocks of an ethical, compliant organization. ISO compliance with standard 37001 assists us to prevent, identify and tackle bribery via best practice training, risk assessment and due diligence.
                                </P>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">
                              <center>
                                <h1 className="text-2xl font-bold text-gray-300 mb-3">NIST COMPLIANCE</h1>
                                <img
                                  src={nistLogoLarge}
                                  alt="NIST Compliance Logo"
                                  data-testid="nist_compliance__logo_large_image"
                                  className="w-full"
                                />
                              </center>
                            </td>
                            <td valign="top">
                              <div className="ml-8">
                                <P className="nist_compliance_description text-2xl mb-6">
                                  NIST is the National Institute of Standards and Technology at the U.S. Department of Commerce. The NIST Cybersecurity Framework outlines a framework of suggestions, recommendations, and regulations, for businesses doing business with the Government, reducing the cybersecurity risk and protecting networks and data.
                                </P>
                                <P className="nist_compliance_description_2 text-2xl mt-3 mb-6">
                                  NIST SP 800-171 is a NIST Special Publication that provides recommended requirements for protecting the confidentiality of controlled unclassified information (CUI). Defense contractors must implement the recommended requirements contained in NIST SP 800-171 to demonstrate their provision of adequate security to protect the covered defense information included in their defense contracts, as required by DFARS clause 252.204-7012. When contracting with the DoD, General Services Administration (GSA), NASA or other federal or state agencies' supply chain, the implementation of the security requirements included in NIST SP 800-171 is a must.
                                </P>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">
                              <center>
                                <h1 className="text-2xl font-bold text-gray-300 mb-3">GDPR COMPLIANCE</h1>
                                <img
                                  src={gdprLogoLarge}
                                  alt="GDPR Compliance Logo"
                                  data-testid="gdpr_compliance__logo_large_image"
                                  className="w-full"
                                />
                              </center>
                            </td>
                            <td valign="top">
                              <div className="ml-8">
                                <P className="gdpr_compliance_description text-2xl mb-6">
                                  The General Data Protection Regulation (GDPR) is a European Union law that regulates how personal data can be processed and transferred. It was adopted in 2016 and went into effect on May 25, 2018. The GDPR applies to both European organizations and organizations outside the EU that target people in the EU.
                                </P>
                                <P className="gdpr_compliance_description_2 text-2xl mt-3 mb-6">
                                  The GDPR establishes requirements for how companies and organizations collect, store, and manage personal data. It also defines individuals' rights in the digital age, the obligations of those processing data, and methods for ensuring compliance.
                                </P>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">
                              <center>
                                <h1 className="text-2xl font-bold text-gray-300 mb-3">GLBA COMPLIANCE</h1>
                                <img
                                  src={glbaComplianceLogoLarge}
                                  alt="GLBA Compliance Logo"
                                  data-testid="glba_compliance__logo_large_image"
                                  className="w-full"
                                />
                              </center>
                            </td>
                            <td valign="top">
                              <div className="ml-8">
                                <P className="gbla_compliance_description text-2xl mb-6">
                                  Also known as the Financial Services Modernization Act, the Gramm-Leach-Bliley Act (GLBA) was enacted in 1999 to protect private consumer information held by financial institutions.
                                </P>
                                <P className="gbla_compliance_description_2 text-2xl mt-3 mb-6">
                                  The GLBA applies to and requires anyone in the financial services to develop privacy notices and to provide customers with the option of prohibiting the sharing of their confidential information with non-affiliated third parties. On July 1, 2001, the Act was amended, requiring financial organizations to have a comprehensive, written information security program in place.
                                </P>
                                <P className="gbla_compliance_description_3 text-2xl mt-3 mb-6">
                                  We require of ourselves to safeguard and protect customer information, while the customer information is on out possession.
                                </P>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    {/* END: ISO Compliance: Desktop Table View */}

                    {/* START: ISO Compliance: Mobile DIV View */}
                      <div className="block md:hidden mt-24">
                        <div className="w-full block">
                          <center>
                            <h1 className="text-2xl font-bold text-gray-300 mb-3">ISO COMPLIANCE</h1>
                            <img
                              src={isoLogoLarge}
                              alt="ISO Compliance Logo"
                              data-testid="iso_compliance__logo_large_image__mobile"
                            />
                          </center>
                        </div>
                        <div className="w-full block">
                          <P className="iso_compliance_description__mobile text-2xl mt-3 mb-6">
                            <span className="font-bold text-gray-300">ISO 27001 (Information Security and IT)</span> - We have created and maintained an information security management system (ISMS) that will strengthen our defenses against cyber threats. ISO 27001 compliance means that we have clear IT security controls in place to protect operations, hardware, and employees from cyber-attacks.
                          </P>
                        </div>
                        <div className="w-full block">
                          <P className="iso_compliance_description_2__mobile text-2xl mt-3 mb-6">
                            <span className="font-bold text-gray-300">ISO 22301 (Business Continuity)</span> - provides us an ISO framework to embed a reliable Business Continuity Management System. It's recognized as the international standard for procedures to mitigate potential business disruption. Being ISO 22301 compliant provides reassurance to partners in the supply chain that an organization can cope with disruption.
                          </P>
                        </div>
                        <div className="w-full block">
                          <P className="iso_compliance_description_3__mobile text-2xl mt-3 mb-6">
                            <span className="font-bold text-gray-300">ISO 37001 (Anti-bribery)</span> - Transparency, trust and good governance are the building blocks of an ethical, compliant organization. ISO compliance with standard 37001 assists us to prevent, identify and tackle bribery via best practice training, risk assessment and due diligence.
                          </P>
                        </div>
                      </div>
                    {/* END: ISO Compliance: Mobile DIV View */}

                    {/* START: NIST Compliance: Mobile DIV View */}
                      <div className="block md:hidden mt-24">
                        <div className="w-full block">
                          <center>
                            <h1 className="text-2xl font-bold text-gray-300 mb-3">NIST COMPLIANCE</h1>
                            <img
                              src={nistLogoLarge}
                              alt="NIST Compliance Logo"
                              data-testid="nist_compliance__logo_large_image__mobile"
                            />
                          </center>
                        </div>
                        <div className="w-full block">
                          <P className="nist_compliance_description__mobile text-2xl mt-3 mb-6">
                            NIST is the National Institute of Standards and Technology at the U.S. Department of Commerce. The NIST Cybersecurity Framework outlines a framework of suggestions, recommendations, and regulations, for businesses doing business with the Government, reducing the cybersecurity risk and protecting networks and data.
                          </P>
                        </div>
                        <div className="w-full block">
                          <P className="nist_compliance_description_2__mobile text-2xl mt-3 mb-6">
                            NIST SP 800-171 is a NIST Special Publication that provides recommended requirements for protecting the confidentiality of controlled unclassified information (CUI). Defense contractors must implement the recommended requirements contained in NIST SP 800-171 to demonstrate their provision of adequate security to protect the covered defense information included in their defense contracts, as required by DFARS clause 252.204-7012. When contracting with the DoD, General Services Administration (GSA), NASA or other federal or state agencies' supply chain, the implementation of the security requirements included in NIST SP 800-171 is a must.
                          </P>
                        </div>
                      </div>
                    {/* END: NIST Compliance: Mobile DIV View */}

                    {/* START: GDPR Compliance: Mobile DIV View */}
                      <div className="block md:hidden mt-24">
                        <div className="w-full block">
                          <center>
                            <h1 className="text-2xl font-bold text-gray-300 mb-3">GDPR COMPLIANCE</h1>
                            <img
                              src={gdprLogoLarge}
                              alt="GDPR Compliance Logo"
                              data-testid="gdpr_compliance__logo_large_image__mobile"
                            />
                          </center>
                        </div>
                        <div className="w-full block">
                          <P className="gdpr_compliance_description__mobile text-2xl mt-3 mb-6">
                            The General Data Protection Regulation (GDPR) is a European Union law that regulates how personal data can be processed and transferred. It was adopted in 2016 and went into effect on May 25, 2018. The GDPR applies to both European organizations and organizations outside the EU that target people in the EU.
                          </P>
                        </div>
                        <div className="w-full block">
                          <P className="gdpr_compliance_description_2__mobile text-2xl mt-3 mb-6">
                            The GDPR establishes requirements for how companies and organizations collect, store, and manage personal data. It also defines individuals' rights in the digital age, the obligations of those processing data, and methods for ensuring compliance.
                          </P>
                        </div>
                      </div>
                    {/* END: GDPR Compliance: Mobile DIV View */}

                    {/* START: GBLA Compliance: Mobile DIV View */}
                      <div className="block md:hidden mt-24">
                        <div className="w-full block">
                          <center>
                            <h1 className="text-2xl font-bold text-gray-300 mb-3">GBLA COMPLIANCE</h1>
                            <img
                              src={glbaComplianceLogoLarge}
                              alt="GLBA Compliance Logo"
                              data-testid="glba_compliance__logo_large_image"
                            />
                          </center>
                        </div>
                        <div className="w-full block">
                          <P className="gbla_compliance_description__mobile text-2xl mt-3 mb-6">
                            Also known as the Financial Services Modernization Act, the Gramm-Leach-Bliley Act (GLBA) was enacted in 1999 to protect private consumer information held by financial institutions.
                          </P>
                        </div>
                        <div className="w-full block">
                          <P className="gbla_compliance_description_2__mobile text-2xl mt-3 mb-6">
                            The GLBA applies to and requires anyone in the financial services to develop privacy notices and to provide customers with the option of prohibiting the sharing of their confidential information with non-affiliated third parties. On July 1, 2001, the Act was amended, requiring financial organizations to have a comprehensive, written information security program in place.
                          </P>
                        </div>
                        <div className="w-full block">
                          <P className="gbla_compliance_description_3__mobile text-2xl mt-3 mb-6">
                            We require of ourselves to safeguard and protect customer information, while the customer information is on out possession.
                          </P>
                        </div>
                      </div>
                    {/* END: GBLA Compliance: Mobile DIV View */}

                  <div className="w-full mb-2">
                    <center>
                      <SubHeading text="LET'S GET STARTED" />
                    </center>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="w-full mb-4 lg:mb-0">
                      <center>
                        <Button
                          url="/contact-us"
                          text="Contact Us Today!"
                          dataTestId="smart_contract_request__form_button"
                          className="text-2xl"
                        />
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100 footer_section">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs