import React from 'react'
import kycAndVerificationsIcon from '../../../../assets/images/kyc_and_verifications__icon.png'
import SubHeading from '../../../ui/SubHeading';

const KYCAndVerifications = () => {
  return ( 
    <>
      <div className="capability__card flex-col mb-12 md:ml-12 md:m-2 md:w-5/12 2xl:m-6 2xl:w-1/5" data-testid="capability__card">
        <a href="/kyc-verification-penetration-testing-bug-bounty">
          <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
            <div className="h-48 rounded-t-lg flex items-center justify-center">
              
              <img
                src={kycAndVerificationsIcon}
                alt="Capability Card Top Image"
                id="kyc_and_verifications__icon"
              />

            </div>
          </div>
          <div className="bottom_our_capabilities__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full 2xl:pb-12">
            <div className="p-3">
              
              <div className="our_capabilities__seperator border-b-2 mb-12" />
              
              <SubHeading
                text="KYC AND VERIFICATIONS"
                id="kyc_and_verifications__heading"
                alt="KYC and Verifications - Smart Contract Development and Audits"
                className="text-lg sm:text-sm md:text-md text-white mb-3"
              />
              
               <p className="text-gray-400 text-lg sm:text-sm md:text-md lg:text-xl"
              data-testid="kyc_and_verfications__description">
                KYC is the process of identity verification of project teams. We do
                extensive checks and verifies the identity of the project team members.
              </p>

              <ul className="ml-3 text-gray-400 text-lg sm:text-sm md:text-md lg:text-xl" data-testid="kyc_and_verifications__list_items">
                <li className="my-3"><span className="text-green-400">✓</span> Verify your team members</li>
                <li className="my-3"><span className="text-green-400">✓</span> Data confidentiality</li>
                <li className="my-3"><span className="text-green-400">✓</span> State-of-the-art validation algorithms</li>
              </ul>

            </div>
          </div>
        </a>
      </div>
    </>
   );
}
 
export default KYCAndVerifications;

