import React from 'react';

import HeroLeft from './hero_block/HeroLeft'
import HeroRight from './hero_block/HeroRight'

const CustomDevelopmentHeroBlock = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row lg:mb-20" data-testid="hero_block__component">
        <div className="w-full lg:w-1/2">
          <HeroLeft />
        </div>
        <div className="w-full lg:w-1/2">
          <HeroRight />
        </div>
      </div>
    </>
  )
}

export default CustomDevelopmentHeroBlock;