import React from 'react'
import { cn } from '../../lib/utilities';

const Heading = ({ children, testId, className, alt, id }) => {

  return (
    <>
      <p
        className={cn("good_times_font__heading text-3xl mb-1", className, {})}
        alt={alt}
        data-testid={testId}
        id={id}
      >
        {children}
      </p>
    </>
  );
}

export default Heading;