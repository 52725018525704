import React from 'react'
import { useIntersectionObserver } from '../../../lib/useIntersectionObserver';
import SubHeading from '../../ui/SubHeading';
import web3DevelopmentImageTop from '../../../assets/images/custom_development/web_3_design_and_development__top_image.png';
import web3DevelopmentImageBottom from '../../../assets/images/custom_development/web_3_design_and_development__bottom_image.png';
import RequestAQuote from '../RequestAQuote';

const Web3Development = () => {
  
  const [topImageRef, isTopImageVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  return (
    <>
      <div className="base_environments__card m-6" id="bug_bounty" data-testid="web3_development__component">
        <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
          <div className="rounded-t-lg flex items-center justify-center">
            <SubHeading
              text="WEB-3 DESIGN AND DEVELOPMENT"
            />
          </div>
        </div>

        <div className="bottom_base_environments__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full">
          <div className="flex flex-col lg:flex-row lg:mb-24">
            <div className="w-full lg:w-1/2">
              <div className="p-3">
                <SubHeading text="WHAT IS WEB-3 OR WEB 3.0?" />
                <p className="text-gray-400 text-xl mb-8">
                  Web3, also known as Web 3.0, is a concept for a new version of the internet that's decentralized and built on 
                  blockchains. The term was coined in 2014 by Gavin Wood, a co-founder of Ethereum, a blockchain-based software platform.
                  The idea gained interest in 2021 from cryptocurrency enthusiasts, large technology companies, and venture capital
                  firms.
                </p>
                <p className="text-gray-400 text-xl mb-8">
                  Web3 aims to give users more control over their data and online interactions, and to reduce the influence
                  of corporations like Amazon, Google, and Meta that currently dominate Web 2.0. In Web3, users would have greater 
                  ownership of their data and experiences, and fewer middlemen would be involved. Web3 would also create more
                  interconnections between technologies, allowing data to flow between different platforms without intermediaries.
                  This interoperability would make data portable, allowing users to switch between services while keeping
                  their preferences, profiles, and settings.
                </p>

                <p className="text-gray-300 text-2xl mb-2 font-bold">
                  Web3 Utilizes These Concepts and Technologies
                </p>

                <ul className="text-gray-400 text-xl mb-8">
                  <li><span className="text-gray-300 font-bold">Blockchains</span><br/>
                      Distributed ledgers that are controlled by participants</li>
                  <li><span className="text-gray-300 font-bold">Token-based economics</span><br/>
                      Cryptocurrencies like Bitcoin and Ethereum,
                      and non-fungible tokens (NFTs).</li>
                  <li><span className="text-gray-300 font-bold">Dynamic Content</span><br/>
                      Content that reacts to user input</li>
                  <li><span className="text-gray-300 font-bold">Free information sorting</span><br/>
                      Allowing users to retrieve and classify data collectively</li>
                  <li><span className="text-gray-300 font-bold">Application Programming Interfaces (APIs)</span><br/>
                      Well developed APIs allow for the collective community to interface with smart contracts, dApps, and more.</li>
                </ul>

                <SubHeading text="Most common programming languages for Web3?" />
                <div className="text-gray-400 text-xl mb-8">
                  <ul className="link_list text-gray-300">
                    <li><a href="https://soliditylang.org/" target="_blank">Solidity</a></li>
                    <li><a href="https://docs.vyperlang.org/en/stable/" target="_blank">Vyper</a></li>
                    <li><a href="https://go.dev/" target="_blank">Go</a></li>
                    <li><a href="https://docs.huff.sh/get-started/overview/" target="_blank">Huff</a></li>
                    <li><a href="https://www.rust-lang.org/" target="_blank">Rust</a></li>
                    <li><a href="https://sui.io/move" target="_blank">Move</a></li>
                    <li><a href="https://www.cairo-lang.org/" target="_blank">Cairo</a></li>
                    <li><a href="https://www.haskell.org/" target="_blank">Haskell</a></li>
                    <li><a href="https://en.wikipedia.org/wiki/C_Sharp_(programming_language)" target="_blank">C# (C Sharp)</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="inner_web2_development_left__image_container ml-6">
                <div className="hidden lg:flex">
                  <br/><br/><br/>
                </div>
                <div
                  ref={topImageRef}
                  className={`transition-opacity duration-1000 ease-in-out ${
                    isTopImageVisible ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <img
                    src={web3DevelopmentImageTop}
                    className="w-full rounded-lg"
                  />
                </div>

                <div className="mb-20 lg:mb-32" />
                
                {/* <img
                  src={web3DevelopmentImageBottom}
                  className="w-full rounded-lg"
                /> */}
              </div>
            </div>
          </div>
          <RequestAQuote />
        </div>
      </div>
    </>
  )
}

export default Web3Development