import React from 'react'
import { Link } from 'react-router-dom'

import smartContractDevelopmentAndAuditsIcon from '../../../../assets/images/smart_contract_development_and_audits__icon.png';
import SubHeading from '../../../ui/SubHeading';

const SmartContractDevelopmentAndAudits = () => {
  return ( 
    <>
      <div className="capability__card flex-col mb-12 md:ml-12 md:m-2 md:w-5/12 2xl:m-6 2xl:w-1/5" data-testid="capability__card">
        <a href="/smart-contracts-and-audits">
          <div className="bg-[#17243D] text-white rounded-t-lg shadow-xl p-6 w-full">
            <div className="h-48 rounded-t-lg flex items-center justify-center">
              <img
                src={smartContractDevelopmentAndAuditsIcon}
                alt="Capability Card Top Image"
                id="smart_contracts_development_and_audits__icon"
              />
            </div>
          </div>
          <div className="bottom_our_capabilities__card bg-[#0C1320] text-white rounded-b-lg shadow-xl p-6 w-full 2xl:pb-12">
            <div className="p-3">
              <div className="our_capabilities__seperator border-b-2 mb-12" />
              <SubHeading
                text="SMART CONTRACT DEVELOPMENT AND AUDITS"
                id="smart_contract_development_and_audits__heading"
                alt="Capability Card Top Image - Smart Contract Development and Audits"
                className="text-lg sm:text-sm md:text-md text-white mb-3"
              />
              {/* <img
                src={smartContractDevelopmentAndAuditsText}
                alt="Smart Contract Development and Audits Heading"
                id="smartContractDevelopmentAndAuditsText"
                className="mb-12"
              /> */}
              <p className="text-gray-400 text-lg sm:text-sm md:text-md lg:text-xl"
              data-testid="smart_contract_development_and_audits__description">
                Our smart contract development service is a comprehensive solution
                for building and deploying smart contracts on various blockchain platforms.
                Smart contracts are self-executing agreements with the potential to
                revolutionize industries by automating processes and enhancing transparency.
              </p>
              <br/>
            </div>
          </div>
        </a>
      </div>
    </>
   );
}
 
export default SmartContractDevelopmentAndAudits;