import React from 'react'

import { cn } from '../../lib/utilities';

const SubHeading = ({ text, className, alt, id }) => {
  const textToDataTestId = (inputText) => {
    return inputText.toLowerCase().replace(/\s+/g, '_');
  };

  const testId = textToDataTestId(text);

  return (
    <>
      <p
        className={cn("good_times_font__heading text-3xl mb-1", className, {})}
        alt={alt}
        data-testid={`${testId}__subheading`}
        id={id}
      >
        {text}
      </p>
    </>
  );
}

export default SubHeading;