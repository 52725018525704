import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logoFile from '../../../assets/images/logo.png'
import { CiMenuBurger } from "react-icons/ci";

const MobileNav = () => {
  const [ showMobileLinks, setShowMobileLinks ] = useState(false);

  const handleToggleMobileLinks = () => {
    setShowMobileLinks(!showMobileLinks);
  }

  return ( 
    <>
      <nav className="flex w-full md:hidden" data-testid="mobile_navigation__item">
        <ul className="flex flex-row items-center w-full">
          <li className="inline-block py-4">
            <Link to="/">
              <img
                src={logoFile}
                alt="Cypher-Tech Labs - Smart Contract Audits, Token and NFT Developer, Custom Software Applications, and much more!"
                id="mobile_logo_file"
              />
            </Link>
          </li>
          <li>
            <Link to="/">
              <h1 className="text-3xl px-5">
                Cypher-Tech Labs
              </h1>
            </Link>
          </li>
          <li className="ml-auto mr-6 py-4">
            <button 
              className="text-3xl"
              onClick={handleToggleMobileLinks}
              data-testid="mobile_navbar_collapse__button"
            >
              <CiMenuBurger />
            </button>
          </li>
        </ul>
      </nav>
      {showMobileLinks && <VerticalNavLinks handleToggleMobileLinks={handleToggleMobileLinks} />}
    </>
  );
}

const VerticalNavLinks = ({ handleToggleMobileLinks }) => {
  const links = [
    { name: 'Home', path: '/', id: 'home__route'},
    { name: 'Blog', path: 'https://blog.cyphertech-labs.io/', id: 'blog__route', external: true},
    { name: 'SmartContracts & Audits', path: '/smart-contracts-and-audits', id: 'smart_contracts_and_audits__route'},
    { name: 'KYC/Penetration Testing', path: '/kyc-verification-penetration-testing-bug-bounty', id: 'kyc_verification_penetration_testing_and_bug_bounty__route'},
    { name: 'Bug Bounty', path: '/kyc-verification-penetration-testing-bug-bounty#bug_bounty', id: 'kyc_verification_penetration_testing_and_bug_bounty__route#bug_bounty'},
    { name: 'Crypto Environments', path: '/crypto-environments', id: 'crypto_environments__route'},
    { name: 'Custom Development', path: '/custom-development', id: 'custom_development__route'},
    { name: 'Payment Options', path: '/payment-options', id: 'payment_options__route'},
    { name: 'Contact Us', path: '/contact-us', id: 'contact_us__route'},
    { name: 'About Us', path: '/about-us', id: 'about_us__route'},
    { name: 'FAQs', path: '/faqs', id: 'faqs__route'},
    { name: 'Privacy Policy', path: '/privacy-policy', id: 'privacy_policy__route'},
  ]

  return (
    <>
      <ul className="flex flex-col w-full md:hidden" id="mobile_navbar_ul">
        {links.map((link, index) => (
          <li key={index} className="ml-4 py-2 sm:text-base" data-testid={link.id}>
            <Link to={link.path} onClick={handleToggleMobileLinks}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </>
  );
}
 
export default MobileNav;