import React from 'react'

import stepFourReporting from '../../../../assets/images/HDAW_STEP_4.png';

const Reporting = () => {
  return ( 
    <>
      <div className="flex flex-col items-center px-auto py-8" data-testid="how_do_audits_work__card">
        <div className="mb-12">
          <center>
            <p className="text-3xl">Step 4</p>
          </center>
        </div>
        <div className="icon-container transform transition-transform duration-300 hover:scale-110">
          <img src={stepFourReporting} alt="Step 4 - Reporting - Icon" className="w-24 h-24" />
        </div>
        <p className="text-4xl mt-6">Reporting</p>
        <hr/>
      </div>       
    </>
  );
}
 
export default Reporting;