import React from 'react'
import ourSpecialtiesDivider from '../../../../assets/images/our_specialty_divider__first.png';
import SubHeading from '../../../ui/SubHeading';

const InitialSpecialties = () => {
  return ( 
    <>
      <div className="capability__card w-full lg:w-1/5 flex-col m-6 mb-24" data-testid="specialty__card">
        <center>
          <SubHeading
            text="OUR SPECIALTIES"
            alt="Our Specialties at Cypher-Tech Labs"
            id="our_specialties__text"
            className="text-3xl sm:text-2xl md:text-2xl text-white mt-4"
          />
        </center>
        <img
          src={ourSpecialtiesDivider}
          alt="seperating aesthic line"
          className="mt-12 mb-4"
          id="ourSpecialtiesDivider"
        />
        <p className="mb-3 text-md text-gray-300" data-testid="our_specialties__descriptions">
          Our developers can work with any blockchain and environment, however
          over time we have specialized in the Ethereum, Base, Solana and Doge environments.
        </p>
        <p className="mb-3 text-md text-gray-300" data-testid="our_specialties__descriptions">
          We use Solidity, Python, JS, HardHat, OpenZeppelin, and other tools to design, create, develop, test, analyze, and mitigate projects.
        </p>
        <p className="mb-3 text-md text-gray-300" data-testid="our_specialties__descriptions">
          We also have custom and proprietary tools in our tool bag which will considerably speedup the work.
        </p>
      </div>
    </>
   );
}
 
export default InitialSpecialties;